import React from 'react';
import { useAppSelector, useAppDispatch } from '../hooks';
import { dim } from '../actions/Dim';
import { Dialog } from '../components/DialogOld';
import { hideDialog } from '../actions/HideDialog';
const DimmerDialogButton = props => (React.createElement("button", { className: "lw-button", onClick: () => props.onDim(props.num) }, props.num + 1));
export const DimmerDialog = (props) => {
    const dispatch = useAppDispatch();
    const devices = useAppSelector(state => state.definitions.devices);
    const device = devices.get(props.id);
    if (device == undefined) {
        return null;
    }
    const target = 'xdim_' + props.id;
    const title = device.title + ' - Dimnivå';
    function onDim(val) {
        dispatch(hideDialog());
        dispatch(dim(props.id, val));
    }
    return (React.createElement(Dialog, { id: target, title: title },
        React.createElement("div", { className: "dimmer-buttons dimmer-buttons-2-rows" },
            React.createElement("div", { className: "lw-button-group" },
                React.createElement(DimmerDialogButton, { num: 0, onDim: onDim }),
                React.createElement(DimmerDialogButton, { num: 1, onDim: onDim }),
                React.createElement(DimmerDialogButton, { num: 2, onDim: onDim }),
                React.createElement(DimmerDialogButton, { num: 3, onDim: onDim }),
                React.createElement(DimmerDialogButton, { num: 4, onDim: onDim }),
                React.createElement(DimmerDialogButton, { num: 5, onDim: onDim }),
                React.createElement(DimmerDialogButton, { num: 6, onDim: onDim }),
                React.createElement(DimmerDialogButton, { num: 7, onDim: onDim })),
            React.createElement("div", { className: "lw-button-group" },
                React.createElement(DimmerDialogButton, { num: 8, onDim: onDim }),
                React.createElement(DimmerDialogButton, { num: 9, onDim: onDim }),
                React.createElement(DimmerDialogButton, { num: 10, onDim: onDim }),
                React.createElement(DimmerDialogButton, { num: 11, onDim: onDim }),
                React.createElement(DimmerDialogButton, { num: 12, onDim: onDim }),
                React.createElement(DimmerDialogButton, { num: 13, onDim: onDim }),
                React.createElement(DimmerDialogButton, { num: 14, onDim: onDim }),
                React.createElement(DimmerDialogButton, { num: 15, onDim: onDim }))),
        React.createElement("div", { className: "dimmer-buttons dimmer-buttons-4-rows" },
            React.createElement("div", { className: "lw-button-group" },
                React.createElement(DimmerDialogButton, { num: 0, onDim: onDim }),
                React.createElement(DimmerDialogButton, { num: 1, onDim: onDim }),
                React.createElement(DimmerDialogButton, { num: 2, onDim: onDim }),
                React.createElement(DimmerDialogButton, { num: 3, onDim: onDim })),
            React.createElement("div", { className: "lw-button-group" },
                React.createElement(DimmerDialogButton, { num: 4, onDim: onDim }),
                React.createElement(DimmerDialogButton, { num: 5, onDim: onDim }),
                React.createElement(DimmerDialogButton, { num: 6, onDim: onDim }),
                React.createElement(DimmerDialogButton, { num: 7, onDim: onDim })),
            React.createElement("div", { className: "lw-button-group" },
                React.createElement(DimmerDialogButton, { num: 8, onDim: onDim }),
                React.createElement(DimmerDialogButton, { num: 9, onDim: onDim }),
                React.createElement(DimmerDialogButton, { num: 10, onDim: onDim }),
                React.createElement(DimmerDialogButton, { num: 11, onDim: onDim })),
            React.createElement("div", { className: "lw-button-group" },
                React.createElement(DimmerDialogButton, { num: 12, onDim: onDim }),
                React.createElement(DimmerDialogButton, { num: 13, onDim: onDim }),
                React.createElement(DimmerDialogButton, { num: 14, onDim: onDim }),
                React.createElement(DimmerDialogButton, { num: 15, onDim: onDim })))));
};
