import { setupEditTransmitterDialog } from '../actions/SetupEditTransmitterDialog';
import { showDialog } from '../actions/ShowDialog';
export function newTransmitter() {
    return dispatch => {
        dispatch(setupEditTransmitterDialog({
            id: null,
            title: '',
            kind: 'onoff',
            code: '',
            unit: '',
            comment: ''
        }));
        dispatch(showDialog('transmitter-edit'));
    };
}
