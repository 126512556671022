import { batch } from 'react-redux';
import { outletsCleared, outletUpdated } from '../features/outlets/outletsSlice';
import { schedulesCleared, scheduleUpdated } from '../features/schedules/schedulesSlice';
import { macrosCleared, macroUpdated } from '../features/macros/macrosSlice';
import { transmittersCleared, transmitterUpdated } from '../features/transmitters/transmittersSlice';
import { devicesCleared, deviceUpdated } from '../features/devices/devicesSlice';
import { panelsCleared, panelUpdated } from '../features/panels/panelsSlice';
import { panelGroupsCleared, panelGroupUpdated } from '../features/panel_groups/panelGroupsSlice';
import { panelDevicesCleared, panelDeviceUpdated } from '../features/panel_devices/panelDevicesSlice';
import { zigbeeNodeDescriptorsCleared, zigbeeNodeDescriptorUpdated } from '../features/zigbee_node_descriptors/zigbeeNodeDescriptorsSlice';
import { zigbeeNodesCleared, zigbeeNodeUpdated } from '../features/zigbee_nodes/zigbeeNodesSlice';
import { updateDeviceStates } from '../actions/UpdateDeviceStates';
import { parseNodeDescriptor } from '../zigbee/node_descriptor';
import { parse, parseOpt } from '../util/parse';
import { parseSchedule } from '../types/Schedule';
import { parsePanel } from '../types/Panel';
import { parseOutlet } from '../types/Outlet';
import { parseTransmitter } from '../types/transmitter';
import { parseDevice } from '../types/device';
import { parsePanelDevice } from '../types/PanelDevice';
import { parsePanelGroup } from '../types/PanelGroup';
import { parseMacro } from '../types/Macro';
import { parseZigbeeNode } from '../types/ZigbeeNode';
export function setDefinitions(data) {
    return dispatch => {
        batch(() => {
            dispatch(outletsCleared());
            for (let o of data.outlets) {
                const outlet = parse(parseOutlet, o);
                if (outlet) {
                    dispatch(outletUpdated(outlet));
                }
            }
            dispatch(schedulesCleared());
            for (let s of data.schedules) {
                const schedule = parse(parseSchedule, s);
                if (schedule) {
                    dispatch(scheduleUpdated(schedule));
                }
            }
            dispatch(macrosCleared());
            for (let s of data.macros) {
                const item = parse(parseMacro, s);
                if (item) {
                    dispatch(macroUpdated(item));
                }
            }
            dispatch(transmittersCleared());
            for (let s of data.transmitters) {
                const item = parse(parseTransmitter, s);
                if (item) {
                    dispatch(transmitterUpdated(item));
                }
            }
            dispatch(devicesCleared());
            for (let s of data.devices) {
                const item = parse(parseDevice, s);
                if (item) {
                    dispatch(deviceUpdated(item));
                }
            }
            dispatch(panelsCleared());
            for (let s of data.panels) {
                const item = parse(parsePanel, s);
                if (item) {
                    dispatch(panelUpdated(item));
                }
            }
            dispatch(panelGroupsCleared());
            for (let s of data.panel_groups) {
                const item = parse(parsePanelGroup, s);
                if (item) {
                    dispatch(panelGroupUpdated(item));
                }
            }
            dispatch(panelDevicesCleared());
            for (let s of data.panel_devices) {
                const item = parse(parsePanelDevice, s);
                if (item) {
                    dispatch(panelDeviceUpdated(item));
                }
            }
            dispatch(zigbeeNodesCleared());
            for (let s of data.zigbee_nodes) {
                const zigbeeNode = parse(parseZigbeeNode, s);
                if (zigbeeNode) {
                    dispatch(zigbeeNodeUpdated(zigbeeNode));
                }
            }
            if (Object.prototype.hasOwnProperty.call(data, 'zigbee_node_descriptors')) {
                dispatch(zigbeeNodeDescriptorsCleared());
                for (let s of data.zigbee_node_descriptors) {
                    const nd = parseOpt(parseNodeDescriptor, s);
                    if (nd !== undefined) {
                        dispatch(zigbeeNodeDescriptorUpdated(nd));
                    }
                }
            }
        });
        if (Object.prototype.hasOwnProperty.call(data, 'device_states')) {
            dispatch(updateDeviceStates(data.device_states));
        }
    };
}
