import React from 'react';
import { useAppSelector, useAppDispatch } from '../hooks';
import { Dialog } from '../components/DialogOld';
import { saveOutlet } from '../actions/SaveOutlet';
import { createOutlet } from '../actions/CreateOutlet';
import { deleteOutlet } from '../actions/DeleteOutlet';
import { FormRow } from '../components/FormRow';
import { FormGroup } from '../components/FormGroup';
import { hideDialog } from '../actions/HideDialog';
import { setEditOutletDialog } from '../actions/SetEditOutletDialog';
import { RadioButtons } from '../components/RadioButtons';
import { unique_value } from '../util/unique';
const DeleteButton = props => (React.createElement("button", { type: "button", className: "delete lw danger", onClick: props.onClick }, "Ta bort"));
const CancelButton = props => (React.createElement("button", { type: "button", className: "lw", onClick: props.onClick }, "Avbryt"));
const SaveButton = props => (React.createElement("button", { type: "button", className: "save lw primary", onClick: props.onClick }, "Spara \u00E4ndringar"));
const CreateButton = props => (React.createElement("button", { type: "button", className: "save lw primary", onClick: props.onClick }, "Skapa uttag"));
const CodeRow = (props) => {
    var _a, _b;
    return (React.createElement("div", { style: { display: 'grid', gridTemplateColumns: '6fr 5fr 1fr', paddingTop: '1px' } },
        React.createElement("div", { style: { paddingRight: '15px', paddingBottom: '3px' } },
            React.createElement("input", { type: "number", min: "0", name: 'code_' + props.index, value: (_a = props.item.code) !== null && _a !== void 0 ? _a : '', onChange: event => props.updateCode(event.target.value) })),
        React.createElement("div", { style: { paddingRight: '15px', paddingBottom: '3px' } },
            React.createElement("input", { type: "number", min: "0", name: 'unit_' + props.index, value: (_b = props.item.unit) !== null && _b !== void 0 ? _b : '', onChange: event => props.updateUnit(event.target.value) })),
        React.createElement("div", null,
            React.createElement("button", { type: "button", className: "close", "aria-label": "Close", style: { paddingTop: '5px' }, onClick: () => props.deleteItem() },
                React.createElement("span", { "aria-hidden": "true" }, "\u00D7")))));
};
const CodeRows = () => {
    const dispatch = useAppDispatch();
    const dialog = useAppSelector(state => state.edit_outlet_dialog);
    function deleteItem(index) {
        let codes = [...dialog.codes];
        codes.splice(index, 1);
        dispatch(setEditOutletDialog(Object.assign(Object.assign({}, dialog), { codes })));
    }
    function addItem() {
        const codes = [...dialog.codes, { key: unique_value(), code: undefined, unit: undefined }];
        dispatch(setEditOutletDialog(Object.assign(Object.assign({}, dialog), { codes })));
    }
    function updateCode(index) {
        return v => {
            let codes = [...dialog.codes];
            codes.splice(index, 1, Object.assign(Object.assign({}, dialog.codes[index]), { code: parseInt(v) }));
            dispatch(setEditOutletDialog(Object.assign(Object.assign({}, dialog), { codes })));
        };
    }
    function updateUnit(index) {
        return v => {
            let codes = [...dialog.codes];
            codes.splice(index, 1, Object.assign(Object.assign({}, dialog.codes[index]), { unit: parseInt(v) }));
            dispatch(setEditOutletDialog(Object.assign(Object.assign({}, dialog), { codes })));
        };
    }
    const rows = dialog.codes.map((codeItem, index) => (React.createElement(CodeRow, { key: codeItem.key, index: index, item: codeItem, deleteItem: () => deleteItem(index), updateCode: updateCode(index), updateUnit: updateUnit(index) })));
    return (React.createElement("div", null,
        React.createElement("div", { style: { display: 'grid', gridTemplateColumns: '1fr 1fr', paddingTop: '5px' } },
            React.createElement("label", { className: "grouptitle", style: { whiteSpace: 'nowrap' } }, "Kod"),
            React.createElement("label", { className: "grouptitle", style: { whiteSpace: 'nowrap' } }, "Enhet")),
        rows,
        React.createElement("div", null,
            React.createElement("button", { className: "lw success add-code", type: "button", "aria-label": "Add", style: { paddingTop: '5px' }, onClick: () => addItem() },
                React.createElement("span", { "aria-hidden": "true" }, "Add")))));
};
export const DialogOutletEdit = () => {
    const dispatch = useAppDispatch();
    const dialog = useAppSelector(state => state.edit_outlet_dialog);
    let footerleft, footerright;
    if (dialog.id) {
        footerleft = [
            (React.createElement(DeleteButton, { key: "delete", onClick: () => dispatch(deleteOutlet(dialog.id)) }))
        ];
        footerright = [
            (React.createElement(CancelButton, { key: "cancel", onClick: () => dispatch(hideDialog()) })),
            (React.createElement(SaveButton, { key: "save", onClick: () => dispatch(saveOutlet(dialog)) }))
        ];
    }
    else {
        footerright = [
            (React.createElement(CancelButton, { key: "cancel", onClick: () => dispatch(hideDialog()) })),
            (React.createElement(CreateButton, { key: "create", onClick: () => dispatch(createOutlet(dialog)) }))
        ];
    }
    const radiobuttons = [
        { kind: 'onoff', title: 'På/Av' },
        { kind: 'dim', title: 'Dimmer' },
        { kind: 'rollershade', title: 'Rullgardin' }
    ];
    return (React.createElement(Dialog, { id: "outlet-edit", title: "Redigera uttag", footerleft: footerleft, footerright: footerright },
        React.createElement(FormRow, null,
            React.createElement(FormGroup, { label: "Namn" },
                React.createElement("input", { type: "text", name: "title", value: dialog.title, onChange: event => dispatch(setEditOutletDialog(Object.assign(Object.assign({}, dialog), { title: event.target.value }))) }))),
        React.createElement(FormRow, null,
            React.createElement(RadioButtons, { buttons: radiobuttons, name: 'kind', kind: dialog.kind, updateKind: v => dispatch(setEditOutletDialog(Object.assign(Object.assign({}, dialog), { kind: v }))) })),
        React.createElement(FormRow, null,
            React.createElement(CodeRows, null)),
        React.createElement(FormRow, null,
            React.createElement(FormGroup, { label: "Kommentar" },
                React.createElement("input", { type: "text", name: "comment", value: dialog.comment, onChange: event => dispatch(setEditOutletDialog(Object.assign(Object.assign({}, dialog), { comment: event.target.value }))) })))));
};
