import Ajax from '../util/ajax';
import { updateTransmitterStates } from '../actions/UpdateTransmitterStates';
import { deviceStateSetZigbeeAttribute } from '../actions/DeviceStateSetZigbeeAttribute';
export function zigbeeColorTemperature(id, value) {
    return dispatch => {
        dispatch(deviceStateSetZigbeeAttribute(id, 0x0300, 7, parseInt(value))); // 7=ColorTemperatureMireds
        dispatch(deviceStateSetZigbeeAttribute(id, 0x0300, 8, 2)); // 8=ColorMode 2=ColorTemperatureMireds
        const success = data => {
            if (data.states) {
                dispatch(updateTransmitterStates(data.states));
            }
        };
        const ajax = new Ajax(dispatch);
        ajax.throttle_post('/zigbee/' + id + '/temperature', { level: value }, success);
    };
}
