import Ajax from '../util/ajax';
import { updateTransmitterStates } from '../actions/UpdateTransmitterStates';
import { deviceStateSetZigbeeAttribute } from '../actions/DeviceStateSetZigbeeAttribute';
export function zigbeeLevelControl(id, value) {
    return dispatch => {
        dispatch(deviceStateSetZigbeeAttribute(id, 8, 0, parseInt(value)));
        const success = data => {
            if (data.states) {
                dispatch(updateTransmitterStates(data.states));
            }
        };
        const ajax = new Ajax(dispatch);
        ajax.throttle_post('/zigbee/' + id + '/dim', { level: value }, success);
    };
}
