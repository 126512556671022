import { parseInteger, parseString, parseLiteral, parseObject } from '../util/parse';
export const parseTransmitterKind = parseLiteral((s) => {
    switch (s) {
        case 'onoff': return 'onoff';
        case 'dim': return 'dim';
        case 'rollershade': return 'rollershade';
        default: return undefined;
    }
});
export const parseTransmitter = parseObject('Transmitter', part => {
    return {
        id: part.req('id', parseInteger),
        title: part.req('title', parseString),
        comment: part.req('comment', parseString),
        kind: part.req('kind', parseTransmitterKind),
        code: part.req('code', parseInteger),
        unit: part.req('unit', parseInteger),
    };
});
export function transmitterKindLabel(transmitter) {
    switch (transmitter.kind) {
        case 'onoff': return 'På/Av';
        case 'dim': return 'Dimmer';
        case 'rollershade': return 'Rullgardin';
        default: return '';
    }
}
