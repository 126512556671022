import { createSlice } from '@reduxjs/toolkit';
import { createEntityAdapter } from '../../util/OrderedEntities';
const adapter = createEntityAdapter({
    selectId: (x) => x.id,
    sortComparer: (a, b) => a.title.localeCompare(b.title),
});
export const panelsSlice = createSlice({
    name: 'panels',
    initialState: adapter.getInitialState(),
    reducers: {
        panelsCleared: adapter.removeAll,
        panelUpdated: adapter.addOne,
        panelDeleted: adapter.removeOne,
    }
});
export const { panelsCleared, panelUpdated, panelDeleted } = panelsSlice.actions;
export const panelAdded = panelUpdated;
export default panelsSlice.reducer;
