import { createSlice } from '@reduxjs/toolkit';
export const outletsSlice = createSlice({
    name: 'outlets',
    initialState: new Map(),
    reducers: {
        outletsCleared: (state) => {
            state.clear();
        },
        outletUpdated: (state, action) => {
            state.set(action.payload.id, action.payload);
        },
        outletDeleted: (state, action) => {
            state.delete(action.payload);
        },
    }
});
export const { outletsCleared, outletUpdated, outletDeleted } = outletsSlice.actions;
export const outletAdded = outletUpdated;
export default outletsSlice.reducer;
