import { setupEditTransmitterDialog } from '../actions/SetupEditTransmitterDialog';
import { showDialog } from '../actions/ShowDialog';
export function editTransmitter(id) {
    return (dispatch, getState) => {
        const { definitions } = getState();
        const transmitter = definitions.transmitters.get(id);
        if (transmitter != undefined) {
            dispatch(setupEditTransmitterDialog({
                id: transmitter.id,
                kind: transmitter.kind,
                title: transmitter.title,
                comment: transmitter.comment,
                code: transmitter.code.toString(),
                unit: transmitter.unit.toString(),
            }));
            dispatch(showDialog('transmitter-edit'));
        }
    };
}
