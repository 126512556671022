import React from 'react';
import { useAppSelector, useAppDispatch } from '../hooks';
import { Dialog } from '../components/DialogOld';
import { FormGroup } from '../components/FormGroup';
import { FormRow } from '../components/FormRow';
import { Selector } from '../components/Selector';
import { TransmitterSelector } from '../components/TransmitterSelector';
import { createPanelTransmitter } from '../actions/CreatePanelTransmitter';
import { savePanelTransmitter } from '../actions/SavePanelTransmitter';
import { deletePanelTransmitter } from '../actions/DeletePanelTransmitter';
import { hideDialog } from '../actions/HideDialog';
import { updateEditPanelDeviceDialog } from '../actions/UpdateEditPanelDeviceDialog';
const DeleteButton = props => (React.createElement("button", { type: "button", className: "delete lw danger", onClick: props.onClick }, "Ta bort"));
const CancelButton = props => (React.createElement("button", { type: "button", className: "lw", onClick: props.onClick }, "Avbryt"));
const SaveButton = props => (React.createElement("button", { type: "button", className: "save lw primary", onClick: props.onClick }, "Spara \u00E4ndringar"));
const CreateButton = props => (React.createElement("button", { type: "button", className: "save lw primary", onClick: props.onClick }, "Skapa uttag"));
export const DialogPanelTransmitterEdit = (props) => {
    const dispatch = useAppDispatch();
    const edit_panel_transmitter_dialog = useAppSelector(state => state.edit_panel_transmitter_dialog);
    const def_panel_group = useAppSelector(state => state.definitions.panel_groups);
    const ordRaw = parseInt(edit_panel_transmitter_dialog.ord);
    const ord = isNaN(ordRaw) ? null : ordRaw;
    let panel_groups = [];
    for (const pg of def_panel_group.values()) {
        panel_groups.push({
            value: pg.id,
            label: pg.title
        });
    }
    edit_panel_transmitter_dialog.panel_device_id;
    let footerleft, footerright;
    if (edit_panel_transmitter_dialog.panel_device_id) {
        footerleft = [
            (React.createElement(DeleteButton, { key: "delete", onClick: () => dispatch(deletePanelTransmitter(props.panel_id)) }))
        ];
        footerright = [
            (React.createElement(CancelButton, { key: "cancel", onClick: () => dispatch(hideDialog()) })),
            (React.createElement(SaveButton, { key: "save", onClick: () => dispatch(savePanelTransmitter(props.panel_id)) }))
        ];
    }
    else {
        footerright = [
            (React.createElement(CancelButton, { key: "cancel", onClick: () => dispatch(hideDialog()) })),
            (React.createElement(CreateButton, { key: "create", onClick: () => dispatch(createPanelTransmitter(props.panel_id)) }))
        ];
    }
    return (React.createElement(Dialog, { id: "edit-panel-transmitter", title: "Redigera panelens s\u00E4ndare", footerleft: footerleft, footerright: footerright },
        React.createElement(FormRow, null,
            React.createElement(FormGroup, { label: "S\u00E4ndare" },
                React.createElement(TransmitterSelector, { name: "transmitter", value: edit_panel_transmitter_dialog.device_id, onChange: event => dispatch(updateEditPanelDeviceDialog({ device_id: parseInt(event.target.value) })) }))),
        React.createElement(FormRow, null,
            React.createElement(FormGroup, { label: "Grupp" },
                React.createElement(Selector, { name: "panel_group_id", value: edit_panel_transmitter_dialog.panel_group_id, options: panel_groups, onChange: event => dispatch(updateEditPanelDeviceDialog({ panel_group_id: parseInt(event.target.value) })) }))),
        React.createElement(FormRow, null,
            React.createElement(FormGroup, { label: "Ordning" },
                React.createElement("input", { type: "text", name: "ord", value: ord !== null && ord !== void 0 ? ord : '', onChange: event => dispatch(updateEditPanelDeviceDialog({ ord: parseInt(event.target.value) })) }))),
        React.createElement("input", { name: "panel_transmitter_id", type: "hidden", value: edit_panel_transmitter_dialog.panel_device_id == null ? '' : edit_panel_transmitter_dialog.panel_device_id })));
};
