import React from 'react';
import { useAppSelector } from '../hooks';
export const OperationSelector = (props) => {
    const devices = useAppSelector(state => state.definitions.devices);
    const device = devices.get(props.transmitter_id);
    if (device == undefined) {
        return null;
    }
    let options = [];
    switch (device.transmitter_kind) {
        case 'onoff':
            options.push(React.createElement("option", { value: "on", key: "on" }, "P\u00E5"));
            options.push(React.createElement("option", { value: "off", key: "off" }, "Av"));
            break;
        case 'dim':
            for (let i = 1; i <= 16; i++) {
                options.push(React.createElement("option", { value: 'dim ' + i, key: i },
                    "Dim ",
                    i));
            }
            break;
    }
    return (React.createElement("select", { name: props.name, value: props.value, onChange: props.onChange }, options));
};
