export const DEVICE_STATE_SET_ZIGBEE_ATTRIBUTE = 'DEVICE_STATE_SET_ZIGBEE_ATTRIBUTE';
export function deviceStateSetZigbeeAttribute(device_id, cluster_id, attribute_id, value) {
    return {
        type: DEVICE_STATE_SET_ZIGBEE_ATTRIBUTE,
        device_id,
        cluster_id,
        attribute_id,
        value
    };
}
