import React from 'react';
import { useAppSelector, useAppDispatch } from '../hooks';
import { DeviceButton2 } from '../components/DeviceButton';
import { zigbeeOn } from '../actions/ZigbeeOn';
import { zigbeeOff } from '../actions/ZigbeeOff';
function getValue(deviceStatesZigbee, device_id, cluster, attribute) {
    const d = deviceStatesZigbee.get(device_id);
    if (d != undefined) {
        const c = d.get(cluster);
        if (c != undefined) {
            const a = c.get(attribute);
            if (a != undefined) {
                const value = a.get('value');
                return value;
            }
        }
    }
    return undefined;
}
export const DeviceButtonsZigbee = props => {
    const devices = useAppSelector(state => state.definitions.devices);
    const deviceStatesZigbee = useAppSelector(state => state.deviceStatesZigbee);
    const dispatch = useAppDispatch();
    const device = devices.get(props.device_id);
    if (device == undefined) {
        return null;
    }
    const onoff = getValue(deviceStatesZigbee, props.device_id, 6, 0);
    let s1, s2;
    if (onoff == true) {
        s1 = undefined;
        s2 = 'on';
    }
    else if (onoff == false) {
        s1 = 'off';
        s2 = undefined;
    }
    else {
        s1 = undefined;
        s2 = undefined;
    }
    return (React.createElement("div", { className: "transmitter-buttons lw-button-group" },
        React.createElement(DeviceButton2, { state: s1, onClick: () => dispatch(zigbeeOff(props.device_id)) }, "Av"),
        React.createElement(DeviceButton2, { state: s2, onClick: () => dispatch(zigbeeOn(props.device_id)) }, "P\u00E5")));
};
