import React from 'react';
import { Group } from '../components/Group';
const ZigbeeNodeNeighborTable = props => {
    let rows = [];
    for (const i in props.items) {
        const n = props.items[i];
        const nwk = n.nwk.toString(16).padStart(4, '0');
        rows.push(React.createElement("tr", { key: n.ieee },
            React.createElement("td", null, n.ieee),
            React.createElement("td", null, nwk),
            React.createElement("td", null, n.deviceType),
            React.createElement("td", null, n.permitJoining),
            React.createElement("td", null, n.relationship),
            React.createElement("td", null, n.rxWhenIdle),
            React.createElement("td", null, n.depth),
            React.createElement("td", null, n.lqi)));
    }
    return (React.createElement("table", null,
        React.createElement("thead", null,
            React.createElement("tr", null,
                React.createElement("th", null, "IEEE"),
                React.createElement("th", null, "NWK"),
                React.createElement("th", null, "Type"),
                React.createElement("th", null, "PermitJoin"),
                React.createElement("th", null, "Relation"),
                React.createElement("th", null, "RxWhenIdle"),
                React.createElement("th", null, "Depth"),
                React.createElement("th", null, "LQI"))),
        React.createElement("tbody", null, rows)));
};
export const ZigbeeNodeNeighbors = (props) => {
    var _a, _b, _c;
    const ts = (_a = props.neighbors) === null || _a === void 0 ? void 0 : _a.fetched_ts;
    const last_fetched = ts == undefined ? '?' : (ts.toLocaleString());
    const title = 'Neighbors (' + last_fetched + ')';
    return (React.createElement(Group, { title: title },
        React.createElement(ZigbeeNodeNeighborTable, { items: (_c = (_b = props.neighbors) === null || _b === void 0 ? void 0 : _b.items) !== null && _c !== void 0 ? _c : [] }),
        React.createElement("form", { onSubmit: props.onFetchNodeNeighbors, style: { padding: '10px 10px' } },
            React.createElement("button", { type: "submit" }, "Fetch"))));
};
