import React from 'react';
import { sprintf } from 'sprintf-js';
function prettyNextRun(s) {
    if (s.next_run) {
        const d = new Date(s.next_run);
        const diff = d.getTime() - Date.now(); // ms
        if (diff / 1000 / 3600 < 18) {
            return sprintf('%02d:%02d', d.getHours(), d.getMinutes());
        }
        else {
            return sprintf('%04d-%02d-%02d %02d:%02d', d.getFullYear(), d.getMonth() + 1, d.getDate(), d.getHours(), d.getMinutes());
        }
    }
    else {
        return '';
    }
}
export const ScheduleNextRun = props => {
    return (React.createElement("div", { className: 'nextrun' }, prettyNextRun(props.schedule)));
};
