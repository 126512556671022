import React from 'react';
import { useAppSelector } from '../hooks';
import { Selector } from '../components/Selector';
export const TransmitterSelector = (props) => {
    const devices = useAppSelector(state => state.definitions.devices);
    let options = [];
    for (const t of devices.values()) {
        if (t.transmitter_kind) {
            options.push({
                value: t.id,
                label: t.title
            });
        }
    }
    options.sort((a, b) => a.label.localeCompare(b.label));
    return React.createElement(Selector, Object.assign({ options: options }, props));
};
