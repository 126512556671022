import { setupEditPanelGroupDialog } from '../actions/SetupEditPanelGroupDialog';
import { showDialog } from '../actions/ShowDialog';
export function editPanelGroup(id) {
    return (dispatch, getState) => {
        const { definitions } = getState();
        const group = definitions.panel_groups.get(id);
        if (group) {
            dispatch(setupEditPanelGroupDialog({
                id: group.id,
                panel_id: group.panel_id,
                title: group.title,
                ord: group.ord,
            }));
            dispatch(showDialog('group-edit'));
        }
    };
}
