import React from 'react';
import { useAppSelector, useAppDispatch } from '../hooks';
import { editSchedule } from '../actions/EditSchedule';
import { DeviceZigbee } from '../components/DeviceZigbee';
import { DeviceButtons } from '../components/DeviceButtons';
import { ScheduleTime } from '../features/schedules/ScheduleTime';
import { ScheduleOperation } from '../features/schedules/ScheduleOperation';
import { ScheduleNextRun } from '../features/schedules/ScheduleNextRun';
import { DialogScheduleEdit } from '../components/DialogScheduleEdit';
import { newSchedule } from '../actions/NewSchedule';
export const Device = props => {
    const dispatch = useAppDispatch();
    const devices = useAppSelector(state => state.definitions.devices);
    const schemas = useAppSelector(state => state.definitions.schedules);
    const device = devices.get(props.id);
    if (device == undefined) {
        return (React.createElement("div", null, "Unknown device"));
    }
    let device2;
    if (device.protocol.name == 'zigbee') {
        device2 = (React.createElement(DeviceZigbee, { device_id: props.id }));
    }
    else {
        device2 = (React.createElement(DeviceButtons, { device_id: props.id }));
    }
    let schemaRows = [];
    for (const sid of schemas.order.values()) {
        const s = schemas.entities.get(sid);
        if (s && s.device_id == device.id) {
            schemaRows.push(React.createElement("div", { key: s.id, className: "config-schedule-row" },
                React.createElement("div", { style: { whiteSpace: 'nowrap' } },
                    React.createElement(ScheduleTime, Object.assign({}, s.time))),
                React.createElement(ScheduleNextRun, { schedule: s }),
                React.createElement(ScheduleOperation, { schedule: s }),
                React.createElement("div", null,
                    React.createElement("button", { className: "edit lw small", onClick: () => dispatch(editSchedule(s.id)) }, "\u00A0Redigera\u00A0"))));
        }
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(DialogScheduleEdit, { device_id: device.id }),
        React.createElement("h1", null, device.title),
        device2,
        React.createElement("div", null,
            React.createElement("button", { style: { float: 'right' }, className: "addschedule lw success", onClick: () => dispatch(newSchedule(device.id)) }, "Ny schemal\u00E4ggning"),
            React.createElement("h3", { style: { marginTop: '3em' } }, "Schemal\u00E4ggning")),
        React.createElement("div", { className: "device-schedule-config", style: { marginLeft: '15px' } },
            React.createElement("div", { className: "config-rows device-schedules" },
                React.createElement("div", { className: "config-schedule-row header" },
                    React.createElement("div", { style: { whiteSpace: 'nowrap' } }, "Tidpunkt"),
                    React.createElement("div", null, "N\u00E4sta"),
                    React.createElement("div", { style: { whiteSpace: 'nowrap' } }, "Operation"),
                    React.createElement("div", null)),
                schemaRows))));
};
