export const ADD_SENSOR = 'ADD_SENSOR';
export function addSensor(id, name, temp, humid, time) {
    return {
        type: ADD_SENSOR,
        id,
        name,
        temp,
        humid,
        time
    };
}
