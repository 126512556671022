import React from 'react';
import { useAppDispatch } from '../hooks';
import { useParams } from 'react-router-dom';
import { DeviceButtons } from '../components/DeviceButtons';
import { DeviceButton2 } from '../components/DeviceButton';
import { transmitter } from '../actions/Transmitter';
export const ConfigTransmitterSetup = () => {
    const dispatch = useAppDispatch();
    const { id } = useParams();
    const device_id = Number(id);
    return (React.createElement("div", null,
        React.createElement("h1", null, "Installation av rullgardin"),
        React.createElement("div", { style: { 'padding': '15px' } },
            React.createElement(DeviceButtons, { device_id: device_id }),
            React.createElement("br", null),
            React.createElement("br", null),
            React.createElement(DeviceButton2, { onClick: () => dispatch(transmitter(device_id, 'confirm')) }, "Confirm"),
            "\u00A0",
            React.createElement(DeviceButton2, { onClick: () => dispatch(transmitter(device_id, 'limit')) }, "Limit"),
            "\u00A0",
            React.createElement(DeviceButton2, { onClick: () => dispatch(transmitter(device_id, 'reverse')) }, "Reverse")),
        React.createElement("h2", null, "Sammankoppling"),
        React.createElement("p", null, "Tryck knapp p\u00E5 motor i 3 sekunder. Motorn l\u00E5ter Di---Di---Di---. Tryck p\u00E5 confirm."),
        React.createElement("h2", null, "Nollst\u00E4llning av motor"),
        React.createElement("p", null, "Tryck knapp p\u00E5 motor i 8 sekunder. Motorn l\u00E5ter Di---Di---Di--- Di-Di-Di-."),
        React.createElement("h2", null, "Inst\u00E4llning av \u00E4ndl\u00E4gen"),
        React.createElement("p", null, "Tryck \"limit\". Motorn l\u00E5ter Di-Di-Di. Tryck \"ner\" och gardinen \u00E5ker ner\u00E5t. Stoppa gardinen. Finjustera med upp/ner-knappar. Tryck \"confirm\" f\u00F6r att spara och motorn l\u00E5ter Di-Di-Di. Tryck \"upp\" och gardinen \u00E5ker upp\u00E5t. Stoppa gardinen. Finjustera med upp/ner-knappar. Tryck \"confirm\" f\u00F6r att spara och motorn l\u00E5ter Di-Di-Di.")));
};
