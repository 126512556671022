import React from 'react';
export const Selector = (props) => {
    const { name, value, onChange } = props;
    const options = [];
    let idx = 1;
    for (const o of props.options) {
        options.push(React.createElement("option", { key: o.value + '_' + idx++, value: o.value }, o.label));
    }
    return (React.createElement("select", { name: name, value: value, onChange: onChange }, options));
};
