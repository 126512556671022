import React from 'react';
import { useParams } from 'react-router-dom';
import { useAppSelector } from '../hooks';
export const ConfigZigbeeDeviceJson = () => {
    const { id } = useParams();
    const node_id = Number(id);
    const zigbee_nodes = useAppSelector(state => state.definitions.zigbee_nodes);
    const zigbee_node = zigbee_nodes.get(node_id);
    var page;
    if (zigbee_node)
        page = (React.createElement("pre", null, JSON.stringify(zigbee_node, null, 4)));
    else
        page = '';
    return (React.createElement("div", { className: "page-container" },
        React.createElement("h1", null, "Zigbee device json"),
        page));
};
