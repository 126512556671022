import { SET_ZIGBEE_DEVICE_INFO } from '../actions/SetZigbeeDeviceInfo';
export function zigbee_device_info(state = new Map(), action) {
    switch (action.type) {
        case SET_ZIGBEE_DEVICE_INFO: {
            let m = new Map(state);
            m.set(action.id, action.info);
            return m;
        }
        default:
            return state;
    }
}
