import React from 'react';
import { useAppSelector, useAppDispatch } from '../hooks';
import { editPanelDevice } from '../actions/EditPanelDevice';
function kindLabel(kind) {
    switch (kind) {
        case 'onoff': return 'På/Av';
        case 'dim': return 'Dimmer';
        case 'rollershade': return 'Rullgardin';
        default: return '';
    }
}
export const ConfigPanelDeviceRow = (props) => {
    const dispatch = useAppDispatch();
    const panel_devices = useAppSelector(state => state.definitions.panel_devices);
    const devices = useAppSelector(state => state.definitions.devices);
    const panel_device = panel_devices.get(props.id);
    if (!panel_device) {
        return (React.createElement("div", null, "error"));
    }
    const device = devices.get(panel_device.device_id);
    if (!device) {
        return (React.createElement("div", null, "error"));
    }
    const active = true;
    const label_classes = 'inactive label label-default' + (active ? ' hidden' : '');
    return (React.createElement("div", { className: "config-panel-transmitter-row" },
        React.createElement("div", null,
            device.title,
            React.createElement("span", { className: label_classes }, "Inaktiv")),
        React.createElement("div", null, kindLabel(device.transmitter_kind)),
        React.createElement("div", null, panel_device.ord),
        React.createElement("div", null,
            React.createElement("button", { className: "edit lw small", onClick: () => dispatch(editPanelDevice(props.id)) }, "\u00A0Redigera\u00A0"))));
};
