import { SET_ZIGBEE_BINDING_TABLE } from '../actions/SetZigbeeBindingTable';
export function zigbee_binding_table(state = new Map(), action) {
    switch (action.type) {
        case SET_ZIGBEE_BINDING_TABLE: {
            let m = new Map(state);
            m.set(action.id, action.binding_table);
            return m;
        }
        default:
            return state;
    }
}
