import { createStore, combineReducers, applyMiddleware } from 'redux';
import { createLogger } from 'redux-logger';
import { thunk } from 'redux-thunk';
import { createSelector } from 'reselect';
import { reducers } from '../reducers/index';
let middlewares = [
    thunk
];
const enable_debug_logging = false;
if (enable_debug_logging && process.env.NODE_ENV !== 'production') {
    const logger = createLogger();
    middlewares.push(logger);
}
function reducer() {
    return combineReducers(Object.assign({}, reducers));
}
export const store = createStore(reducer(), {}, applyMiddleware(...middlewares));
export const createAppSelector = createSelector.withTypes();
