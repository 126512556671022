import React from 'react';
// hard code to 154 ~ 6500K and 454 ~ 2200K
export const ZigbeeColorTemperature = props => {
    const temp = 455 - props.temperature + 154; // invert value so the darkest is to the left
    function onChange(event) {
        event.target.value = 455 - event.target.value + 154;
        props.onChange(event);
    }
    return (React.createElement("div", { className: "zigbeecolortemperature" },
        React.createElement("input", { type: "range", min: "154", max: "455", value: temp, id: "myRange", style: { width: '100%' }, onChange: onChange })));
};
