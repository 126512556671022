import React from 'react';
import { useParams } from 'react-router-dom';
import { useAppSelector, useAppDispatch } from '../hooks';
import { ConfigPanelGroup } from '../components/ConfigPanelGroup';
import { ConfigPanelDeviceRow } from '../components/ConfigPanelDeviceRow';
import { DialogPanelGroupEdit } from '../components/DialogPanelGroupEdit';
import { DialogPanelTransmitterEdit } from '../components/DialogPanelTransmitterEdit';
import { newPanelTransmitter } from '../actions/NewPanelTransmitter';
import { newPanelGroup } from '../actions/NewPanelGroup';
export const ConfigPanel = () => {
    const { id } = useParams();
    const panel_id = Number(id);
    const dispatch = useAppDispatch();
    const newGroup = () => dispatch(newPanelGroup(panel_id));
    const newTransmitter = () => dispatch(newPanelTransmitter());
    const panel_group_order = useAppSelector(state => state.definitions.panel_group_order);
    const panel_groups = useAppSelector(state => state.definitions.panel_groups);
    const panel_device_order = useAppSelector(state => state.definitions.panel_device_order);
    const panel_devices = useAppSelector(state => state.definitions.panel_devices);
    const devices = useAppSelector(state => state.definitions.devices);
    const group_order = panel_group_order.get(panel_id);
    var groups = [];
    if (group_order !== undefined) {
        for (const panel_group_id of group_order) {
            let panel_group = panel_groups.get(panel_group_id);
            if (panel_group == undefined)
                continue;
            const device_order = panel_device_order.get(panel_group.id);
            if (device_order == undefined) {
                console.log('Missing panel_device_order for panel_group ' + panel_group.id);
                continue;
            }
            let g = {
                id: panel_group.id,
                name: panel_group.title,
                ord: panel_group.ord,
                devices: [],
                panel_devices: device_order
            };
            for (const id of device_order) {
                const panel_device = panel_devices.get(id);
                if (panel_device == undefined)
                    continue;
                const device = devices.get(panel_device.device_id);
                if (device == undefined)
                    continue;
                g.devices.push(device);
            }
            groups.push(g);
        }
    }
    var rows = [];
    for (const group of groups) {
        rows.push(React.createElement(ConfigPanelGroup, { key: 'g' + group.id, id: group.id }));
        for (const panel_device_id of group.panel_devices) {
            rows.push(React.createElement(ConfigPanelDeviceRow, { key: 'pt' + panel_device_id, id: panel_device_id }));
        }
    }
    return (React.createElement("div", { className: "page-container" },
        React.createElement(DialogPanelGroupEdit, { panel_id: panel_id }),
        React.createElement(DialogPanelTransmitterEdit, { panel_id: panel_id }),
        React.createElement("div", { className: "operations" },
            React.createElement("div", null,
                React.createElement("button", { className: "lw success", onClick: newGroup }, "\u00A0L\u00E4gg till grupp...\u00A0"),
                ' ',
                React.createElement("button", { className: "lw success", onClick: newTransmitter }, "\u00A0L\u00E4gg till kontakt...\u00A0"))),
        React.createElement("div", { className: "config-rows config-panel-device" }, rows)));
};
