import { parseString, parseObject } from '../util/parse';
import { parseSun } from '../types/Sun';
import { parseTime } from '../types/Time';
export const parseScheduleTime = parseObject('ScheduleTime', part => {
    const kind = part.req('kind', parseString);
    switch (kind) {
        case 'cron':
            return {
                kind: 'cron',
                cron: part.req('cron', parseString),
            };
        case 'time':
            return {
                kind: 'time',
                time: part.req('time', parseTime),
            };
        case 'sun':
            return {
                kind: 'sun',
                sun: part.req('sun', parseSun),
            };
        default:
            return part.error('invalid kind value ' + kind);
    }
});
