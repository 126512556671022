import Ajax from '../util/ajax';
import { panelUpdated } from '../features/panels/panelsSlice';
import { parse } from '../util/parse';
import { parsePanel } from '../types/Panel';
export function savePanel(panel, callback) {
    return dispatch => {
        const ajax = new Ajax(dispatch);
        const url = '/panel/' + panel.id;
        ajax.post(url, panel, data => {
            const panel = parse(parsePanel, data);
            if (panel != undefined) {
                dispatch(panelUpdated(panel));
                callback();
            }
        });
    };
}
