import React from 'react';
export function ZigbeeNodeEndpointGroups(props) {
    var _a;
    const ts = (_a = props.groups) === null || _a === void 0 ? void 0 : _a.fetched_ts;
    const last_fetched = ts == undefined ? '?' : (ts.toLocaleString());
    const title = 'Groups (' + last_fetched + ')';
    var rows = [];
    if (props.groups != undefined) {
        for (const g of props.groups.items) {
            rows.push(React.createElement("div", { key: g.id }, g.id));
        }
    }
    return (React.createElement(React.Fragment, null,
        React.createElement("h3", null,
            title,
            "\u00A0\u00A0",
            React.createElement("button", { type: "button", onClick: () => props.onFetchEndpointGroups() }, "\uD83D\uDDD8")),
        rows));
}
