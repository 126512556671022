import React from 'react';
import { useAppSelector, useAppDispatch } from '../hooks';
import { DeviceButton2 } from '../components/DeviceButton';
import { transmitter } from '../actions/Transmitter';
export const DeviceButtonsNexaOnOff = props => {
    const devices = useAppSelector(state => state.definitions.devices);
    const device_states = useAppSelector(state => state.device_states);
    const dispatch = useAppDispatch();
    const device = devices.get(props.device_id);
    if (device == undefined) {
        return null;
    }
    const s = device_states.get(props.device_id);
    const state = s != undefined ? s.get('state') : undefined;
    let s1, s2;
    if (state == 'on') {
        s1 = undefined;
        s2 = 'on';
    }
    else if (state == 'off') {
        s1 = 'off';
        s2 = undefined;
    }
    else {
        s1 = undefined;
        s2 = undefined;
    }
    return (React.createElement("div", { className: "transmitter-buttons lw-button-group" },
        React.createElement(DeviceButton2, { state: s1, onClick: () => dispatch(transmitter(props.device_id, 'off')) }, "Av"),
        React.createElement(DeviceButton2, { state: s2, onClick: () => dispatch(transmitter(props.device_id, 'on')) }, "P\u00E5")));
};
