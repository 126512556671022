import Ajax from '../util/ajax';
export const THROTTLE_QUEUE_ADD = 'THROTTLE_QUEUE_ADD';
export const THROTTLE_QUEUE_UPDATE = 'THROTTLE_QUEUE_UPDATE';
export const THROTTLE_SENT = 'THROTTLE_SENT';
const THROTTLE_TIMEOUT = 200; // milliseconds
export function throttle(endpoint, data, success, failure) {
    return (dispatch, getState) => {
        const ajax = new Ajax(dispatch);
        function send() {
            ajax.post(endpoint, data, success, failure);
            dispatch({ type: THROTTLE_SENT, endpoint });
        }
        function timeout_send() {
            const { throttle } = getState();
            const t = throttle.get(endpoint);
            if (t == undefined)
                return;
            if (t.post == null)
                return;
            window.clearInterval(t.post.intervalId);
            ajax.post(endpoint, t.post.data, t.post.success, t.post.failure);
            dispatch({ type: THROTTLE_SENT, endpoint });
        }
        const { throttle } = getState();
        if (!throttle.has(endpoint)) {
            send();
        }
        else {
            const t = throttle.get(endpoint);
            if (t != undefined) {
                if (t.post == null) {
                    // nothing is queued
                    const timeSinceLast = Date.now() - t.timestamp;
                    if (timeSinceLast > THROTTLE_TIMEOUT) { // more than 100ms ago, send direct
                        send();
                    }
                    else {
                        const intervalId = window.setInterval(() => timeout_send(), THROTTLE_TIMEOUT - timeSinceLast);
                        dispatch({ type: THROTTLE_QUEUE_ADD,
                            endpoint,
                            data,
                            success,
                            failure,
                            intervalId
                        });
                    }
                }
                else {
                    // we are already waiting to send something, change it to our new value
                    dispatch({ type: THROTTLE_QUEUE_UPDATE,
                        endpoint,
                        data,
                        success,
                        failure,
                        intervalId: t.post.intervalId
                    });
                }
            }
        }
    };
}
