import { createSlice } from '@reduxjs/toolkit';
export const transmittersSlice = createSlice({
    name: 'transmitters',
    initialState: new Map(),
    reducers: {
        transmittersCleared: s => { s.clear(); },
        transmitterUpdated: (s, action) => { s.set(action.payload.id, action.payload); },
        transmitterDeleted: (s, action) => { s.delete(action.payload); },
    }
});
export const { transmittersCleared, transmitterUpdated, transmitterDeleted } = transmittersSlice.actions;
export const transmitterAdded = transmitterUpdated;
export default transmittersSlice.reducer;
