import React from 'react';
import { useAppDispatch } from '../hooks';
import { login } from '../actions/Login';
export const Login = (_props) => {
    const dispatch = useAppDispatch();
    const username = React.useRef(null);
    const password = React.useRef(null);
    const submit = e => {
        e.preventDefault();
        if (username != null && password != null) {
            if (username.current != null && password.current != null) {
                dispatch(login(username.current.value, password.current.value));
            }
        }
    };
    return (React.createElement("form", { onSubmit: submit },
        React.createElement("label", null,
            "Anv\u00E4ndarnamn",
            React.createElement("br", null),
            React.createElement("input", { ref: username, type: "text", name: "username", autoFocus: true, autoCorrect: "off", autoCapitalize: "off", required: true })),
        React.createElement("br", null),
        React.createElement("label", null,
            "L\u00F6senord",
            React.createElement("br", null),
            React.createElement("input", { ref: password, type: "password", name: "password", required: true })),
        React.createElement("br", null),
        React.createElement("br", null),
        React.createElement("input", { type: "submit", value: "Logga in" })));
};
