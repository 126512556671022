import React from 'react';
import { useAppSelector, useAppDispatch } from '../hooks';
import { Link } from 'react-router-dom';
import { transmitterKindLabel } from '../types/transmitter';
import { editTransmitter } from '../actions/EditTransmitter';
const rollershade_setup = id => {
    const link = `/config/transmitter/${id}/setup`;
    return (React.createElement("div", { style: { 'float': 'right' } },
        React.createElement(Link, { to: link }, "Installation")));
};
export const ConfigTransmitterRow = (props) => {
    const dispatch = useAppDispatch();
    const transmitters = useAppSelector(state => state.definitions.transmitters);
    const transmitter = transmitters.get(props.id);
    if (!transmitter) {
        return null;
    }
    const active = true;
    const has_setup = transmitter.kind == 'rollershade';
    const label_classes = 'inactive label ' + (active ? ' hidden' : '');
    const setup = has_setup ? rollershade_setup(transmitter.id) : '';
    return (React.createElement("div", { className: "config-transmitter-row" },
        React.createElement("div", null,
            transmitter.title,
            React.createElement("span", { className: label_classes }, "Inaktiv"),
            setup),
        React.createElement("div", null, transmitterKindLabel(transmitter)),
        React.createElement("div", { className: "pull-right" },
            React.createElement("button", { className: "edit lw small", onClick: () => dispatch(editTransmitter(transmitter.id)) }, "\u00A0Redigera\u00A0"))));
};
