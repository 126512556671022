import { SETUP_EDIT_TRANSMITTER_DIALOG } from '../actions/SetupEditTransmitterDialog';
import { UPDATE_EDIT_TRANSMITTER_DIALOG } from '../actions/UpdateEditTransmitterDialog';
export function edit_transmitter_dialog(state = {}, action) {
    switch (action.type) {
        case SETUP_EDIT_TRANSMITTER_DIALOG:
            return Object.assign({}, state, action.props);
        case UPDATE_EDIT_TRANSMITTER_DIALOG:
            return Object.assign({}, state, action.props);
        default:
            return state;
    }
}
