import { get_any_transmitter_id, get_transmitters } from '../util/definitions';
export const SETUP_EDIT_MACRO_DIALOG = 'SETUP_EDIT_MACRO_DIALOG';
export function setupEditMacroDialog(macroprops) {
    return (dispatch, getState) => {
        const { definitions } = getState();
        // find a default transmitter to use for new items
        let default_transmitter_id = get_any_transmitter_id(definitions);
        let default_transmitter_operation;
        const device = definitions.devices.get(default_transmitter_id);
        if (device == undefined) {
            return;
        }
        switch (device.transmitter_kind) {
            case 'onoff':
                default_transmitter_operation = 'off';
                break;
            case 'dim':
                default_transmitter_operation = 'dim 15';
                break;
        }
        const props = {
            macro: Object.assign({}, macroprops),
            transmitter_definitions: get_transmitters(definitions),
            default_transmitter_id,
            default_transmitter_operation
        };
        dispatch({
            type: SETUP_EDIT_MACRO_DIALOG,
            props
        });
    };
}
