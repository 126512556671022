import { SETUP_EDIT_PANEL_DEVICE_DIALOG } from '../actions/SetupEditPanelDeviceDialog';
import { UPDATE_EDIT_PANEL_DEVICE_DIALOG } from '../actions/UpdateEditPanelDeviceDialog';
const initialState = {
    panel_device_id: null,
    device_id: NaN,
    panel_group_id: NaN,
    ord: ''
};
export function edit_panel_transmitter_dialog(state = initialState, action) {
    switch (action.type) {
        case SETUP_EDIT_PANEL_DEVICE_DIALOG:
            return Object.assign({}, state, action.props);
        case UPDATE_EDIT_PANEL_DEVICE_DIALOG:
            return Object.assign({}, state, action.props);
        default:
            return state;
    }
}
