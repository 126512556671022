import { parseString, ParseError } from '../util/parse';
export const parseAttributeType = (ctx, v) => {
    const s = parseString(ctx, v);
    switch (s) {
        // ***********************
        // Backward compability
        case '-':
            return 'NoData';
        case 'bit':
            return 'Data8';
        case '16bit':
            return 'Data16';
        case '24bit':
            return 'Data24';
        case '32bit':
            return 'Data32';
        case '40bit':
            return 'Data40';
        case '48bit':
            return 'Data48';
        case '56bit':
            return 'Data56';
        case '64bit':
            return 'Data64';
        // ***********************
        case 'NoData':
        case 'Data8':
        case 'Data16':
        case 'Data24':
        case 'Data32':
        case 'Data40':
        case 'Data48':
        case 'Data56':
        case 'Data64':
        case 'Bool':
        case 'Bitmap8':
        case 'Bitmap16':
        case 'Bitmap24':
        case 'Bitmap32':
        case 'Bitmap40':
        case 'Bitmap48':
        case 'Bitmap56':
        case 'Bitmap64':
        case 'UInt8':
        case 'UInt16':
        case 'UInt24':
        case 'UInt32':
        case 'UInt40':
        case 'UInt48':
        case 'UInt56':
        case 'UInt64':
        case 'Int8':
        case 'Int16':
        case 'Int24':
        case 'Int32':
        case 'Int40':
        case 'Int48':
        case 'Int56':
        case 'Int64':
        case 'Enum8':
        case 'Enum16':
        case 'FloatSemi':
        case 'FloatSingle':
        case 'FloatDouble':
        case 'OctetStr':
        case 'CharStr':
        case 'LongOctetStr':
        case 'LongCharStr':
        case 'Array':
        case 'Struct':
        case 'Set':
        case 'Bag':
        case 'TimeOfDay':
        case 'Date':
        case 'UTCTime':
        case 'ClusterID':
        case 'AttributeID':
        case 'BACnetOID':
        case 'IEEEAddr':
        case 'Security128':
        case 'Unknown':
            return s;
        default: throw new ParseError(ctx, 'expected string with atttribute type, got ' + s);
    }
};
