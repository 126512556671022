import React from 'react';
import { useAppSelector, useAppDispatch } from '../hooks';
import { Dialog } from '../components/DialogOld';
import { saveMacro } from '../actions/SaveMacro';
import { createMacro } from '../actions/CreateMacro';
import { deleteMacro } from '../actions/DeleteMacro';
import { editMacroDialogUpdate } from '../actions/EditMacroDialogUpdate';
import { editMacroDialogAddItem } from '../actions/EditMacroDialogAddItem';
import { editMacroDialogDeleteItem } from '../actions/EditMacroDialogDeleteItem';
import { editMacroDialogItemChange } from '../actions/EditMacroDialogItemChange';
import { editMacroDialogItemTransmitterChange } from '../actions/EditMacroDialogItemTransmitterChange';
import { FormGroup } from '../components/FormGroup';
import { FormRow } from '../components/FormRow';
import { TransmitterSelector } from '../components/TransmitterSelector';
import { OperationSelector } from '../components/OperationSelector';
import { hideDialog } from '../actions/HideDialog';
const DeleteButton = props => (React.createElement("button", { type: "button", className: "delete lw danger", onClick: props.onClick }, "Ta bort"));
const CancelButton = props => (React.createElement("button", { type: "button", className: "lw", onClick: props.onClick }, "Avbryt"));
const SaveButton = props => (React.createElement("button", { type: "button", className: "save lw primary", onClick: props.onClick }, "Spara \u00E4ndringar"));
const CreateButton = props => (React.createElement("button", { type: "button", className: "save lw primary", onClick: props.onClick }, "Skapa scen"));
export function DialogMacroEdit(props) {
    const dispatch = useAppDispatch();
    const macro = useAppSelector(state => state.edit_macro_dialog.macro);
    let footerleft, footerright;
    let items = macro.items ? macro.items : [];
    if (props.id) {
        footerleft = [
            (React.createElement(DeleteButton, { key: "delete", onClick: () => dispatch(deleteMacro()) }))
        ];
        footerright = [
            (React.createElement(CancelButton, { key: "cancel", onClick: () => dispatch(hideDialog()) })),
            (React.createElement(SaveButton, { key: "save", onClick: () => dispatch(saveMacro()) }))
        ];
    }
    else {
        footerright = [
            (React.createElement(CancelButton, { key: "cancel", onClick: () => dispatch(hideDialog()) })),
            (React.createElement(CreateButton, { key: "create", onClick: () => dispatch(createMacro()) }))
        ];
    }
    const updateField = (event) => {
        if (event.target.type == 'checkbox') {
            dispatch(editMacroDialogUpdate({
                [event.target.name]: event.target.checked
            }));
        }
        else {
            dispatch(editMacroDialogUpdate({
                [event.target.name]: event.target.value
            }));
        }
    };
    const updateItemField = index => (event) => {
        if (event.target.type == 'select-one') {
            dispatch(editMacroDialogItemChange(index, { [event.target.name]: event.target.value }));
        }
    };
    const itemsnodes = items.map((item, index) => (React.createElement("div", { style: { display: 'grid', gridTemplateColumns: '7fr 4fr 1fr', paddingBottom: '7px' }, key: index },
        React.createElement("div", { style: { paddingRight: '15px' } },
            React.createElement(TransmitterSelector, { name: "transmitter_id", value: item.transmitter_id, onChange: event => {
                    dispatch(editMacroDialogItemTransmitterChange(index, Number(event.target.value)));
                } })),
        React.createElement("div", null,
            React.createElement(OperationSelector, { name: "operation", transmitter_id: item.transmitter_id, value: item.operation, onChange: updateItemField(index) })),
        React.createElement("div", { style: { justifySelf: 'right' } },
            React.createElement("button", { type: "button", className: "close", "aria-label": "Close", style: { paddingTop: '5px' }, onClick: () => dispatch(editMacroDialogDeleteItem(index)) },
                React.createElement("span", { "aria-hidden": "true" }, "\u00D7"))))));
    return (React.createElement(Dialog, { id: "macro-edit", title: "Redigera scen", footerleft: footerleft, footerright: footerright },
        React.createElement("input", { name: "macro_id", type: "hidden", value: props.id }),
        React.createElement(FormGroup, { label: "Namn" },
            React.createElement("input", { type: "text", name: "title", value: macro.title, onChange: updateField })),
        React.createElement(FormRow, null,
            React.createElement("label", null,
                React.createElement("input", { type: "checkbox", name: "active", checked: macro.active, onChange: updateField }),
                "\u00A0Aktiv")),
        itemsnodes,
        React.createElement(FormRow, null,
            React.createElement("button", { className: "lw success add-command", onClick: () => dispatch(editMacroDialogAddItem()) }, "L\u00E4gg till kommando..."))));
}
