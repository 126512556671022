import { createSlice } from '@reduxjs/toolkit';
export const zigbeeNodeDescriptorsSlice = createSlice({
    name: 'zigbee_node_descriptors',
    initialState: new Map(),
    reducers: {
        zigbeeNodeDescriptorsCleared: s => { s.clear(); },
        zigbeeNodeDescriptorUpdated: (s, action) => { s.set(action.payload.id, action.payload); },
        zigbeeNodeDescriptorDeleted: (s, action) => { s.delete(action.payload); },
    }
});
export const { zigbeeNodeDescriptorsCleared, zigbeeNodeDescriptorUpdated, zigbeeNodeDescriptorDeleted } = zigbeeNodeDescriptorsSlice.actions;
export default zigbeeNodeDescriptorsSlice.reducer;
