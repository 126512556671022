import React from 'react';
import { useAppSelector, useAppDispatch } from '../hooks';
import { Link } from 'react-router-dom';
import { getManufacturerName } from '../features/zigbee_node_descriptors/util';
import { fetchZigbeeNode } from '../actions/FetchZigbeeNode';
import { permitJoining } from '../actions/PermitJoining';
function get_node_title(devices, node_id, endpoint) {
    function endpointPredicate(d) {
        return d.protocol.name == 'zigbee'
            && d.protocol.endpoint.zigbee_node_id == node_id
            && d.protocol.endpoint.endpoint == endpoint;
    }
    const ep_devices = Array.from(devices.values()).filter(endpointPredicate);
    if (ep_devices.length == 1) {
        let device = ep_devices[0];
        return device.title;
    }
    else {
        return null;
    }
}
export const ConfigZigbee = () => {
    const [value, setValue] = React.useState('');
    const devices = useAppSelector(state => state.definitions.devices);
    const zigbee_nodes = useAppSelector(state => state.definitions.zigbee_nodes);
    const zigbee_node_descriptors = useAppSelector(state => state.definitions.zigbee_node_descriptors);
    const dispatch = useAppDispatch();
    const onChange = event => {
        setValue(event.target.value);
    };
    const onSubmit = event => {
        event.preventDefault();
        dispatch(fetchZigbeeNode(value));
    };
    const onPermitJoining = event => {
        event.preventDefault();
        dispatch(permitJoining());
    };
    var rows = [];
    var nodes = Array.from(zigbee_nodes.values());
    if (nodes) {
        nodes.sort((a, b) => { var _a, _b, _c, _d, _e, _f; if (((_a = a.nwk) !== null && _a !== void 0 ? _a : 0) == ((_b = b.nwk) !== null && _b !== void 0 ? _b : 0)) {
            return ((_c = a.ieee) !== null && _c !== void 0 ? _c : 0) - ((_d = b.ieee) !== null && _d !== void 0 ? _d : 0);
        }
        else {
            return ((_e = a.nwk) !== null && _e !== void 0 ? _e : 0) - ((_f = b.nwk) !== null && _f !== void 0 ? _f : 0);
        } });
        for (const node of nodes) {
            const nwk = node.nwk == null ? '?' : ('0x' + node.nwk.toString(16).padStart(4, '0'));
            const ieee = node.ieee_hex != null ? ('0x' + node.ieee_hex) : (node.ieee == null ? null : ('0x' + node.ieee.toString(16).padStart(16, '0')));
            const node_descriptor = zigbee_node_descriptors.get(node.id);
            const manufacturer = getManufacturerName(node_descriptor);
            const zigbee_device_link = React.createElement(Link, { to: '/config/zigbee/' + node.id },
                React.createElement("span", { style: { color: 'green' } },
                    " ",
                    nwk));
            let device_title = null;
            for (const [, endpoint] of node.endpoints) {
                if (device_title == null) {
                    device_title = get_node_title(devices, node.id, endpoint.endpoint);
                }
            }
            rows.push(React.createElement("tr", { key: node.id },
                React.createElement("td", null, zigbee_device_link),
                React.createElement("td", null, ieee),
                React.createElement("td", null, manufacturer),
                React.createElement("td", null, device_title)));
        }
    }
    return (React.createElement("div", { className: "page-container" },
        React.createElement("h1", null, "Zigbee"),
        React.createElement("table", null,
            React.createElement("thead", null,
                React.createElement("tr", null,
                    React.createElement("th", null, "NWK"),
                    React.createElement("th", null, "IEEE"),
                    React.createElement("th", null, "Manufacturer"),
                    React.createElement("th", null, "Name"))),
            React.createElement("tbody", null, rows)),
        React.createElement("form", { onSubmit: onSubmit, style: { padding: '10px 10px', borderTop: '3px solid #ddd' } },
            "Get device info for NWK id: ",
            React.createElement("input", { type: "text", value: value, onChange: onChange }),
            " ",
            React.createElement("button", { type: "submit" }, "H\u00E4mta")),
        React.createElement("form", { onSubmit: onPermitJoining, style: { padding: '10px 10px', borderTop: '3px solid #ddd' } },
            React.createElement("button", { type: "submit" }, "Permit joining for 2 minutes"))));
};
