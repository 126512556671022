import React from 'react';
export const DeviceButton2 = props => {
    let button_class_names = [
        'lw-button'
    ];
    switch (props.state) {
        case 'on':
            button_class_names.push('lw-button-on');
            break;
        case 'off':
            button_class_names.push('lw-button-off');
            break;
        case undefined:
            break;
    }
    const button_class = button_class_names.join(' ');
    return (React.createElement("button", { className: button_class, onClick: () => props.onClick() }, props.children));
};
