import Ajax from '../util/ajax';
import { panelDeviceAdded } from '../features/panel_devices/panelDevicesSlice';
import { hideDialog } from '../actions/HideDialog';
import { parse } from '../util/parse';
import { parsePanelTransmitter } from '../types/PanelTransmitter';
export function createPanelTransmitter(panel_id) {
    return (dispatch, getState) => {
        const { edit_panel_transmitter_dialog } = getState();
        const ajax = new Ajax(dispatch);
        const url = '/panel/' + panel_id + '/transmitter/create';
        let obj = {
            transmitter_id: edit_panel_transmitter_dialog.device_id,
            panel_group_id: edit_panel_transmitter_dialog.panel_group_id,
            ord: edit_panel_transmitter_dialog.ord
        };
        ajax.post(url, obj, data => {
            const panelTransmitter = parse(parsePanelTransmitter, data);
            const panelDevice = {
                id: panelTransmitter.id,
                panel_group_id: panelTransmitter.panel_group_id,
                device_id: panelTransmitter.transmitter_id,
                ord: panelTransmitter.ord
            };
            dispatch(panelDeviceAdded(panelDevice));
            dispatch(hideDialog());
        });
    };
}
