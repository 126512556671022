import React from 'react';
import { useAppSelector, useAppDispatch } from '../hooks';
import { clusterNameOrHex } from '../zigbee/cluster';
import { attributeNameOrHex } from '../zigbee/attribute';
import { fetchZigbeeNodeCluster } from '../actions/FetchZigbeeNodeCluster';
function renderAttributeValue(a) {
    if (typeof a.value == 'undefined') {
        return '?';
    }
    else if (a.type == 'Bool') {
        return a.value ? '\u2705' : '\u274C';
    }
    else {
        return a.value.toString();
    }
}
const Cluster = (props) => {
    var rows = [];
    for (const a of props.attributes.values()) {
        const name = attributeNameOrHex(props.cluster, a.attribute_id);
        const value = renderAttributeValue(a);
        rows.push(React.createElement("tr", { key: a.attribute_id },
            React.createElement("td", null, name),
            React.createElement("td", null, a.type),
            React.createElement("td", null, value)));
    }
    return (React.createElement("div", { key: props.cluster },
        React.createElement("h3", null,
            "Cluster ",
            clusterNameOrHex(props.cluster),
            "\u00A0\u00A0",
            React.createElement("button", { type: "button", onClick: () => props.onFetchCluster(props.cluster) }, "\uD83D\uDDD8")),
        React.createElement("table", null,
            React.createElement("tbody", null, rows))));
};
export const ConfigZigbeeEndpointAttributes = (props) => {
    const dispatch = useAppDispatch();
    const zigbee_device_info = useAppSelector(state => state.zigbee_device_info);
    const deviceInfo = zigbee_device_info.get(props.node);
    let attributes = deviceInfo ? deviceInfo.attributes.get(props.endpoint) : undefined;
    if (attributes == undefined) {
        attributes = new Map();
    }
    let clusters = new Set();
    let endpoint = deviceInfo ? deviceInfo.endpoints.get(props.endpoint) : undefined;
    if (endpoint != undefined) {
        for (const c of endpoint.input_clusters) {
            clusters.add(c);
        }
        for (const c of endpoint.output_clusters) {
            clusters.add(c);
        }
    }
    let items = [];
    let sorted_clusters = Array.from(clusters);
    sorted_clusters.sort();
    for (const c of sorted_clusters) {
        let attr = attributes.get(c);
        if (attr == undefined) {
            attr = new Map();
        }
        items.push(React.createElement(Cluster, { key: c, cluster: c, attributes: attr, onFetchCluster: () => dispatch(fetchZigbeeNodeCluster(props.node, props.endpoint, c)) }));
    }
    return (React.createElement("div", null, items));
};
