import { SETUP_EDIT_MACRO_DIALOG } from '../actions/SetupEditMacroDialog';
import { EDIT_MACRO_DIALOG_UPDATE } from '../actions/EditMacroDialogUpdate';
import { EDIT_MACRO_DIALOG_ADD_ITEM } from '../actions/EditMacroDialogAddItem';
import { EDIT_MACRO_DIALOG_DELETE_ITEM } from '../actions/EditMacroDialogDeleteItem';
import { EDIT_MACRO_DIALOG_ITEM_TRANSMITTER_CHANGE } from '../actions/EditMacroDialogItemTransmitterChange';
import { EDIT_MACRO_DIALOG_ITEM_CHANGE } from '../actions/EditMacroDialogItemChange';
const initialEditMacroDialog = {
    macro: { title: '', active: true, items: [] },
    transmitter_definitions: new Map(),
    default_transmitter_id: null,
    default_transmitter_operation: null,
};
export function edit_macro_dialog(state = initialEditMacroDialog, action) {
    switch (action.type) {
        case SETUP_EDIT_MACRO_DIALOG:
            return Object.assign({}, state, action.props);
        case EDIT_MACRO_DIALOG_UPDATE: {
            let o = Object.assign({}, state);
            o.macro = Object.assign({}, o.macro, action.props);
            return o;
        }
        case EDIT_MACRO_DIALOG_ADD_ITEM: {
            if (state.default_transmitter_id != null && state.default_transmitter_operation != null) {
                let o = Object.assign({}, state);
                o.macro = Object.assign({}, o.macro);
                o.macro.items = [...o.macro.items,
                    {
                        operation: state.default_transmitter_operation,
                        transmitter_id: state.default_transmitter_id,
                        device_id: state.default_transmitter_id
                    }
                ];
                return o;
            }
            else {
                return state;
            }
        }
        case EDIT_MACRO_DIALOG_DELETE_ITEM: {
            let o = Object.assign({}, state);
            o.macro = Object.assign({}, o.macro);
            o.macro.items = [...o.macro.items.slice(0, action.index),
                ...o.macro.items.slice(action.index + 1)];
            return o;
        }
        case EDIT_MACRO_DIALOG_ITEM_TRANSMITTER_CHANGE: {
            let o = Object.assign({}, state);
            o.macro = Object.assign({}, o.macro);
            o.macro.items = o.macro.items.map((item, index) => {
                if (index == action.index) {
                    if (state.transmitter_definitions == undefined) {
                        return item;
                    }
                    const transmitter = state.transmitter_definitions.get(action.transmitter_id);
                    if (transmitter == null) {
                        return item;
                    }
                    let operation;
                    switch (transmitter.transmitter_kind) {
                        case 'onoff':
                            operation = 'off';
                            break;
                        case 'dim':
                            operation = 'dim 15';
                            break;
                    }
                    return Object.assign({}, item, { transmitter_id: action.transmitter_id,
                        operation });
                }
                else {
                    return item;
                }
            });
            return o;
        }
        case EDIT_MACRO_DIALOG_ITEM_CHANGE: {
            let o = Object.assign({}, state);
            o.macro = Object.assign({}, o.macro);
            o.macro.items = o.macro.items.map((item, index) => {
                if (index == action.index) {
                    return Object.assign({}, item, action.itemprops);
                }
                else {
                    return item;
                }
            });
            return o;
        }
        default:
            return state;
    }
}
