import Ajax from '../util/ajax';
import { panelDeleted } from '../features/panels/panelsSlice';
export function deletePanel(panelId, callback) {
    return dispatch => {
        const ajax = new Ajax(dispatch);
        const url = '/panel/' + panelId;
        ajax.del(url, undefined, () => {
            dispatch(panelDeleted(panelId));
            callback();
        });
    };
}
