import { UPDATE_DEVICE_STATES } from '../actions/UpdateDeviceStates';
export function device_states(state = new Map(), action) {
    switch (action.type) {
        case UPDATE_DEVICE_STATES: {
            let o = new Map();
            let changed = false;
            for (const [id, actionstate] of Object.entries(action.states)) {
                let newItem;
                if (Object.prototype.hasOwnProperty.call(actionstate, 'state')) {
                    newItem = new Map(Object.entries(actionstate));
                }
                else if (Object.prototype.hasOwnProperty.call(actionstate, 'temp')) {
                    newItem = new Map(Object.entries(actionstate));
                }
                else {
                    // it's a zigbee object
                    continue;
                }
                // reuse old values in the map as much as we can so we don't trigger any renders
                // unless something has changed.
                const oldItem = state.get(parseInt(id));
                if (oldItem == undefined || JSON.stringify(oldItem) != JSON.stringify(newItem)) {
                    changed = true;
                    o.set(parseInt(id), newItem);
                }
                else {
                    o.set(parseInt(id), oldItem);
                }
            }
            return changed ? o : state;
        }
        default:
            return state;
    }
}
