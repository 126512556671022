import { parseInteger, parseString, parseObject, parseArray } from '../util/parse';
import { parseZigbeeGUI } from '../types/ZigbeeGUI';
import { parseGroups } from '../types/Zigbee/Groups';
export const parseEndpoint = parseObject('Endpoint', part => {
    return {
        clusters: part.req('clusters', parseArray(parseInteger)),
        device: part.req('device', parseInteger),
        device_name: part.req('device_name', parseString),
        device_version: part.req('device_version', parseInteger),
        eid: part.req('eid', parseInteger),
        endpoint: part.req('endpoint', parseInteger),
        gui: part.req('gui', parseZigbeeGUI),
        input_clusters: part.req('input_clusters', parseArray(parseInteger)),
        output_clusters: part.req('output_clusters', parseArray(parseInteger)),
        groups: part.opt('groups', parseGroups),
        profile: part.req('profile', parseInteger),
        profile_name: part.req('profile_name', parseString),
        zigbee_node_id: part.req('zigbee_node_id', parseInteger),
    };
});
export const parseEndpointMap = (ctx, v) => {
    const es = parseArray(parseEndpoint)(ctx, v);
    var m = new Map();
    for (const e of es) {
        m.set(e.endpoint, e);
    }
    return m;
};
