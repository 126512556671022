import React from 'react';
import { useAppSelector, useAppDispatch } from '../hooks';
import { Dialog } from '../components/DialogOld';
import { updateEditTransmitterDialog } from '../actions/UpdateEditTransmitterDialog';
import { saveTransmitter } from '../actions/SaveTransmitter';
import { createTransmitter } from '../actions/CreateTransmitter';
import { deleteTransmitter } from '../actions/DeleteTransmitter';
import { FormGroup } from '../components/FormGroup';
import { FormRow } from '../components/FormRow';
import { RadioButtons } from '../components/RadioButtons';
import { hideDialog } from '../actions/HideDialog';
const DeleteButton = props => (React.createElement("button", { type: "button", className: "delete lw danger", onClick: props.onClick }, "Ta bort"));
const CancelButton = props => (React.createElement("button", { type: "button", className: "lw", onClick: props.onClick }, "Avbryt"));
const SaveButton = props => (React.createElement("button", { type: "button", className: "save lw primary", onClick: props.onClick }, "Spara \u00E4ndringar"));
const CreateButton = props => (React.createElement("button", { type: "button", className: "save lw primary", onClick: props.onClick }, "Skapa s\u00E4ndare"));
export const DialogTransmitterEdit = (_props) => {
    const dispatch = useAppDispatch();
    const dialog = useAppSelector(state => state.edit_transmitter_dialog);
    if (dialog.id == null) {
        dialog.id = '';
    }
    if (dialog.code == null) {
        dialog.code = '';
    }
    if (dialog.unit == null) {
        dialog.unit = '';
    }
    let footerleft, footerright;
    const radiobuttons = [
        { kind: 'onoff', title: 'På/Av' },
        { kind: 'dim', title: 'Dimmer' },
        { kind: 'rollershade', title: 'Rullgardin' }
    ];
    if (dialog.id) {
        footerleft = (React.createElement(DeleteButton, { key: "delete", onClick: () => dispatch(deleteTransmitter()) }));
        footerright = [
            (React.createElement(CancelButton, { key: "cancel", onClick: () => dispatch(hideDialog()) })),
            (React.createElement(SaveButton, { key: "save", onClick: () => dispatch(saveTransmitter()) }))
        ];
    }
    else {
        footerright = [
            (React.createElement(CancelButton, { key: "cancel", onClick: () => dispatch(hideDialog()) })),
            (React.createElement(CreateButton, { key: "create", onClick: () => dispatch(createTransmitter()) }))
        ];
    }
    return (React.createElement(Dialog, { id: "transmitter-edit", title: "Redigera s\u00E4ndare", footerleft: footerleft, footerright: footerright },
        React.createElement(FormRow, null,
            React.createElement(FormGroup, { label: "Namn" },
                React.createElement("input", { type: "text", name: "title", value: dialog.title, onChange: event => dispatch(updateEditTransmitterDialog({ title: event.target.value })) }))),
        React.createElement(FormRow, null,
            React.createElement(RadioButtons, { buttons: radiobuttons, name: 'kind', kind: dialog.kind, updateKind: v => dispatch(updateEditTransmitterDialog({ kind: v })) })),
        React.createElement(FormRow, null,
            React.createElement("div", { style: { display: 'grid', gridTemplateColumns: '1fr 1fr' } },
                React.createElement("div", { style: { marginRight: '15px' } },
                    React.createElement(FormGroup, { label: "Kod" },
                        React.createElement("input", { type: "text", name: "code", value: dialog.code, onChange: event => dispatch(updateEditTransmitterDialog({ code: event.target.value })) }))),
                React.createElement("div", { style: { marginLeft: '15px' } },
                    React.createElement(FormGroup, { label: "Knapp" },
                        React.createElement("input", { type: "text", name: "unit", value: dialog.unit, onChange: event => dispatch(updateEditTransmitterDialog({ unit: event.target.value })) }))))),
        React.createElement(FormRow, null,
            React.createElement(FormGroup, { label: "Kommentar" },
                React.createElement("input", { type: "text", name: "comment", value: dialog.comment, onChange: event => dispatch(updateEditTransmitterDialog({ comment: event.target.value })) }))),
        React.createElement("input", { name: "transmitter_id", type: "hidden", value: dialog.id })));
};
