import Ajax from '../util/ajax';
import { panelDeviceDeleted } from '../features/panel_devices/panelDevicesSlice';
import { hideDialog } from '../actions/HideDialog';
export function deletePanelTransmitter(panel_id) {
    return (dispatch, getState) => {
        const { edit_panel_transmitter_dialog } = getState();
        const panel_device_id = edit_panel_transmitter_dialog.panel_device_id;
        if (panel_device_id) {
            const ajax = new Ajax(dispatch);
            const url = '/panel/' + panel_id + '/transmitter/' + panel_device_id;
            ajax.del(url, undefined, () => {
                dispatch(panelDeviceDeleted(panel_device_id));
                dispatch(hideDialog());
            });
        }
    };
}
