import Ajax from '../util/ajax';
import { setZigbeeDeviceInfo } from '../actions/SetZigbeeDeviceInfo';
import { parse } from '../util/parse';
import { parseZigbeeDeviceInfo } from '../types/ZigbeeDeviceInfo';
export function fetchZigbeeDeviceInfo(zigbee_node_id) {
    return dispatch => {
        const ajax = new Ajax(dispatch);
        const url = '/zigbee/' + zigbee_node_id + '/all';
        ajax.get(url, undefined, data => {
            const device_info = parse(parseZigbeeDeviceInfo, data);
            dispatch(setZigbeeDeviceInfo(zigbee_node_id, device_info));
        });
    };
}
