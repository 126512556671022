import React from 'react';
import { MacroButton } from '../components/MacroButton';
import { useAppSelector } from '../hooks';
export const Macros = () => {
    const macros = useAppSelector(state => state.definitions.macros.entities);
    const macros_order = useAppSelector(state => state.definitions.macros.order);
    var macrosElems = [];
    for (let id of macros_order) {
        const macro = macros.get(id);
        if (macro && macro.active) {
            macrosElems.push(React.createElement(MacroButton, { key: macro.id, id: macro.id }));
        }
    }
    return (React.createElement("div", { className: "page-container" },
        React.createElement("div", { className: "macros" }, macrosElems)));
};
