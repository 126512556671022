import { SETUP_EDIT_PANEL_GROUP_DIALOG } from '../actions/SetupEditPanelGroupDialog';
import { UPDATE_EDIT_PANEL_GROUP_DIALOG } from '../actions/UpdateEditPanelGroupDialog';
export const initialState = {
    id: null,
    panel_id: 0,
    title: '',
    ord: 0,
};
export function edit_panel_group_dialog(state = initialState, action) {
    switch (action.type) {
        case SETUP_EDIT_PANEL_GROUP_DIALOG:
            return Object.assign({}, state, action.props);
        case UPDATE_EDIT_PANEL_GROUP_DIALOG:
            return Object.assign({}, state, action.props);
        default:
            return state;
    }
}
