import React from 'react';
import { useAppDispatch } from '../hooks';
import { Dialog } from '../components/Dialog';
import { FormGroup } from '../components/FormGroup';
import { savePanel } from '../actions/SavePanel';
import { createPanel } from '../actions/CreatePanel';
import { deletePanel } from '../actions/DeletePanel';
const DeleteButton = props => (React.createElement("button", { type: "button", className: "delete lw danger", onClick: props.onClick }, "Ta bort"));
const CancelButton = props => (React.createElement("button", { type: "button", className: "lw", onClick: props.onClick }, "Avbryt"));
const SaveButton = props => (React.createElement("button", { type: "button", className: "save lw primary", onClick: props.onClick }, "Spara \u00E4ndringar"));
const CreateButton = props => (React.createElement("button", { type: "button", className: "save lw primary", onClick: props.onClick }, "Skapa panel"));
export const DialogPanelEdit = (props) => {
    const dispatch = useAppDispatch();
    let dialogTitle, footerleft, footerright;
    if (props.panelId != null) {
        const panel_id = props.panelId;
        dialogTitle = 'Redigera panel';
        footerleft = [
            (React.createElement(DeleteButton, { key: "delete", onClick: () => dispatch(deletePanel(panel_id, props.onClose)) }))
        ];
        let panel = Object.assign({ id: panel_id }, props.panelData);
        footerright = [
            (React.createElement(CancelButton, { key: "cancel", onClick: props.onClose })),
            (React.createElement(SaveButton, { key: "save", onClick: () => dispatch(savePanel(panel, props.onClose)) }))
        ];
    }
    else {
        dialogTitle = 'Skapa panel';
        footerright = [
            (React.createElement(CancelButton, { key: "cancel", onClick: props.onClose })),
            (React.createElement(CreateButton, { key: "create", onClick: () => dispatch(createPanel(props.panelData, props.onClose)) }))
        ];
    }
    return (React.createElement(Dialog, { title: dialogTitle, footerleft: footerleft, footerright: footerright, isOpen: props.isOpen, close: props.onClose },
        React.createElement(FormGroup, { label: "Namn" },
            React.createElement("input", { type: "text", name: "title", value: props.panelData.title, onChange: e => props.setPanelData(Object.assign(Object.assign({}, props.panelData), { title: e.target.value })) }))));
};
