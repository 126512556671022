import { createAction, createSlice } from '@reduxjs/toolkit';
import { panelDeviceOrderChanged } from './panelDeviceOrderSlice';
const panelDeviceUpdatedInternal = createAction('panel_devices/panelDevicepdated');
export function panelDeviceUpdated(pd) {
    return dispatch => {
        dispatch(panelDeviceUpdatedInternal(pd));
        dispatch(panelDeviceOrderChanged(pd.panel_group_id)); // tell the order part of the state to recompute since an ord value might have changed
    };
}
export const panelDevicesSlice = createSlice({
    name: 'panel_devices',
    initialState: new Map(),
    reducers: {
        panelDevicesCleared: s => { s.clear(); },
        panelDeviceDeleted: (s, action) => { s.delete(action.payload); },
    },
    extraReducers: builder => {
        builder
            .addCase(panelDeviceUpdatedInternal, (s, action) => { s.set(action.payload.id, action.payload); });
    },
});
export const { panelDevicesCleared, panelDeviceDeleted } = panelDevicesSlice.actions;
export const panelDeviceAdded = panelDeviceUpdated;
export default panelDevicesSlice.reducer;
