import Ajax from '../util/ajax';
import { macroDeleted } from '../features/macros/macrosSlice';
import { hideDialog } from '../actions/HideDialog';
export function deleteMacro() {
    return (dispatch, getState) => {
        const { edit_macro_dialog } = getState();
        const ajax = new Ajax(dispatch);
        const url = '/macro/' + edit_macro_dialog.macro.id;
        ajax.del(url, undefined, () => {
            dispatch(macroDeleted(edit_macro_dialog.macro.id));
            dispatch(hideDialog());
        });
    };
}
