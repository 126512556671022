import React from 'react';
import { useAppSelector, useAppDispatch } from '../hooks';
import { DeviceButtonsZigbee } from '../components/DeviceButtonsZigbee';
import { ZigbeeLevelControl } from '../components/ZigbeeLevelControl';
import { ZigbeeColorTemperature } from '../components/ZigbeeColorTemperature';
import { zigbeeLevelControl } from '../actions/ZigbeeLevelControl';
import { zigbeeColorTemperature } from '../actions/ZigbeeColorTemperature';
function getValue(deviceStatesZigbee, device_id, cluster, attribute) {
    const d = deviceStatesZigbee.get(device_id);
    if (d != undefined) {
        const c = d.get(cluster);
        if (c != undefined) {
            const a = c.get(attribute);
            if (a != undefined) {
                const value = a.get('value');
                return value;
            }
        }
    }
    return undefined;
}
export const DeviceZigbee = props => {
    const dispatch = useAppDispatch();
    const devices = useAppSelector(state => state.definitions.devices);
    const zigbee_nodes = useAppSelector(state => state.definitions.zigbee_nodes);
    const deviceStatesZigbee = useAppSelector(state => state.deviceStatesZigbee);
    let has_level = false;
    let has_color_temperature = false;
    const device = devices.get(props.device_id);
    if (device != undefined && device.protocol.name == 'zigbee') {
        const zigbee_id = device.protocol.endpoint.zigbee_node_id;
        const eid = device.protocol.endpoint.eid;
        const zigbee_node = zigbee_nodes.get(zigbee_id);
        const zigbee_endpoint = zigbee_node != undefined ? zigbee_node.endpoints.get(eid) : undefined;
        if (zigbee_endpoint != undefined) {
            has_level = 'level' in zigbee_endpoint.gui && zigbee_endpoint.gui.level != undefined;
            has_color_temperature = 'temperature' in zigbee_endpoint.gui && zigbee_endpoint.gui.temperature != undefined;
        }
    }
    let level;
    let color_temp;
    const onChangeColorTemperature = (event) => {
        const val = event.target.value;
        dispatch(zigbeeColorTemperature(props.device_id, val)); // invert value so the darkest is to the left
    };
    const onChangeLevelControl = (event) => {
        const val = event.target.value;
        dispatch(zigbeeLevelControl(props.device_id, val));
    };
    if (has_color_temperature) {
        const temperatureStr = getValue(deviceStatesZigbee, props.device_id, 0x0300, 7);
        const temperature = temperatureStr == undefined ? 454 : parseInt(temperatureStr);
        color_temp = React.createElement(React.Fragment, null,
            "F\u00E4rgtemperatur: ",
            React.createElement(ZigbeeColorTemperature, { onChange: onChangeColorTemperature, temperature: temperature }));
    }
    else {
        color_temp = '';
    }
    if (has_level) {
        const levelStr = getValue(deviceStatesZigbee, props.device_id, 8, 0);
        const levelValue = levelStr == undefined ? 127 : parseInt(levelStr);
        level = React.createElement(React.Fragment, null,
            "Ljusstyrka: ",
            React.createElement(ZigbeeLevelControl, { onChange: onChangeLevelControl, level: levelValue }));
    }
    else {
        level = '';
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(DeviceButtonsZigbee, { device_id: props.device_id }),
        React.createElement("div", { style: { paddingTop: '10px' } },
            level,
            color_temp)));
};
