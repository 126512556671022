import Ajax from '../util/ajax';
export const RUN_MACRO = 'RUN_MACRO';
export function runMacro(id) {
    console.log('RunMacro ' + id);
    return dispatch => {
        const success = () => {
        };
        const a = new Ajax(dispatch);
        a.post('/macro/' + id + '/run', undefined, success);
        dispatch({
            type: RUN_MACRO,
            id
        });
    };
}
