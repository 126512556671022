import React from 'react';
import { useAppSelector, useAppDispatch } from '../hooks';
import { DeviceButton2 } from '../components/DeviceButton';
import { transmitter } from '../actions/Transmitter';
import * as LWIcon from '../components/LWIcon';
export const DeviceButtonsHasta = props => {
    const devices = useAppSelector(state => state.definitions.devices);
    const device_states = useAppSelector(state => state.device_states);
    const dispatch = useAppDispatch();
    const device = devices.get(props.device_id);
    if (device == undefined) {
        return null;
    }
    const s = device_states.get(props.device_id);
    const state = s != undefined ? s.get('state') : undefined;
    let s1, s2, s3;
    if (state == 'up') {
        s1 = 'off';
        s2 = undefined;
        s3 = undefined;
    }
    else if (state == 'stop' || state == undefined) {
        s1 = undefined;
        s2 = 'off';
        s3 = undefined;
    }
    else if (state == 'down') {
        s1 = undefined;
        s2 = undefined;
        s3 = 'on';
    }
    else {
        s1 = undefined;
        s2 = undefined;
        s3 = undefined;
    }
    // up: <span style={{'font-weight': 'bold'}}>{"\u2B06"}</span>
    // down: <span style={{'font-weight': 'bold'}}>{"\u2B07"}</span>
    return (React.createElement("div", { className: "transmitter-buttons lw-button-group" },
        React.createElement(DeviceButton2, { state: s1, onClick: () => dispatch(transmitter(props.device_id, 'up')) },
            React.createElement(LWIcon.ArrowUp, null)),
        React.createElement(DeviceButton2, { state: s2, onClick: () => dispatch(transmitter(props.device_id, 'stop')) },
            React.createElement(LWIcon.Stop, null)),
        React.createElement(DeviceButton2, { state: s3, onClick: () => dispatch(transmitter(props.device_id, 'down')) },
            React.createElement(LWIcon.ArrowDown, null))));
};
