import { showDialog } from '../actions/ShowDialog';
import { get_any_transmitter_id } from '../util/definitions';
import { initialState } from '../reducers/edit_schedule_dialog';
import { setEditScheduleDialog } from '../actions/SetEditScheduleDialog';
export function newSchedule(device_id) {
    return (dispatch, getState) => {
        const { definitions } = getState();
        let dialog = Object.assign({}, initialState);
        if (device_id == undefined)
            dialog.transmitter_id = get_any_transmitter_id(definitions);
        else
            dialog.transmitter_id = device_id;
        dispatch(setEditScheduleDialog(dialog));
        dispatch(showDialog('schedule-edit'));
    };
}
