import React from 'react';
import { prettyWeekdays } from '../../types/Weekdays';
const format_time_part = n => {
    if (n >= 10) {
        return n.toString();
    }
    else {
        return '0' + n.toString();
    }
};
const format_time = (h, m) => {
    return h.toString() + ':' + format_time_part(m);
};
const one_sequence = (str) => {
    let start = null;
    let end = null;
    for (let i = 0; i < str.length; i++) {
        if (start == null) {
            if (str[i] == '1') {
                start = i;
            }
        }
        else if (end == null) {
            if (str[i] != '1') {
                end = i - 1;
            }
        }
        else if (start != null && end != null) {
            if ((end + 1) != i && str[i] == '1') {
                return [null, null];
            }
        }
    }
    if (start != null && end == null) {
        end = str.length - 1;
    }
    return [start, end];
};
const format_weekdays = (w) => {
    const day_strings = ['Mån', 'Tis', 'Ons', 'Tors', 'Fre', 'Lör', 'Sön'];
    let days = [];
    if (w.monday) {
        days.push('Mån');
    }
    if (w.tuesday) {
        days.push('Tis');
    }
    if (w.wednesday) {
        days.push('Ons');
    }
    if (w.thursday) {
        days.push('Tors');
    }
    if (w.friday) {
        days.push('Fre');
    }
    if (w.saturday) {
        days.push('Lör');
    }
    if (w.sunday) {
        days.push('Sön');
    }
    switch (days.length) {
        case 0:
            return null;
        case 7:
            return '';
        case 1:
        case 2:
            return days.join(', ');
        default:
            {
                const str = prettyWeekdays(w);
                let [start, end] = one_sequence(str);
                if (start !== null && end !== null) {
                    return day_strings[start] + '-' + day_strings[end];
                }
                else {
                    let res = '';
                    res += str[0] == '1' ? 'M' : '-';
                    res += str[1] == '1' ? 'T' : '-';
                    res += str[2] == '1' ? 'O' : '-';
                    res += str[3] == '1' ? 'T' : '-';
                    res += str[4] == '1' ? 'F' : '-';
                    res += str[5] == '1' ? 'L' : '-';
                    res += str[6] == '1' ? 'S' : '-';
                    return res;
                }
            }
    }
};
export const ScheduleTime = (props) => {
    if (props.kind == 'cron') {
        return (React.createElement("div", null, props.cron));
    }
    else if (props.kind == 'time') {
        let time = format_time(props.time.h, props.time.m);
        const wd = format_weekdays(props.time.w);
        switch (wd) {
            case null:
                time = '';
                break;
            case '':
                break;
            default:
                time = time + ' ' + wd;
                break;
        }
        return (React.createElement("div", null, time));
    }
    else if (props.kind == 'sun') {
        let time = '';
        switch (props.sun.k) {
            case 't':
                time = 'Gryning';
                break;
            case 'u':
                time = 'Dagsljus';
                break;
            case 'e':
                time = 'Skymning';
                break;
            case 'n':
                time = 'Mörker';
                break;
        }
        if (props.sun.a < 0) {
            time += props.sun.a;
        }
        else if (props.sun.a > 0) {
            time += '+' + props.sun.a;
        }
        const wd = format_weekdays(props.sun.w);
        switch (wd) {
            case null:
                time = '';
                break;
            case '':
                break;
            default:
                time = time + ' ' + wd;
                break;
        }
        switch (props.sun.r) {
            case 'r0':
                break;
            case 'r5':
                time = time + ' ± 5';
                break;
            case 'r15':
                time = time + ' ± 15';
                break;
        }
        return (React.createElement("div", null, time));
    }
    else {
        return (React.createElement("div", null, "?"));
    }
};
