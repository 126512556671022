import { parseInteger, parseString, parseObject } from '../util/parse';
import { parseBindingTable } from '../types/Zigbee/BindingTable';
import { parseNeighbors } from '../types/Zigbee/Neighbors';
import { parseEndpointMap } from '../types/endpoint';
export const parseZigbeeNode = parseObject('ZigbeeNode', part => {
    return {
        id: part.req('id', parseInteger),
        ieee: part.opt('ieee', parseInteger),
        ieee_hex: part.opt('ieee_hex', parseString),
        last_seen: part.req('last_seen', parseString),
        manufacturer_code: part.opt('manufacturer_code', parseInteger),
        manufacturer_name: part.opt('manufacturer_name', parseString),
        nwk: part.opt('nwk', parseInteger),
        binding_table: part.req('binding_table', parseBindingTable),
        neighbors: part.req('neighbors', parseNeighbors),
        endpoints: part.req('endpoints', parseEndpointMap),
    };
});
