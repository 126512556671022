import { UPDATE_NODE_NEIGHBORS } from '../actions/UpdateNodeNeighbors';
export function zigbee_node_neighbors(state = new Map(), action) {
    switch (action.type) {
        case UPDATE_NODE_NEIGHBORS: {
            let m = new Map(state);
            m.set(Number(action.zigbee_node_id), action.neighbors);
            return m;
        }
        default:
            return state;
    }
}
