import Ajax from '../util/ajax';
import { transmitterDeleted } from '../features/transmitters/transmittersSlice';
import { deviceDeleted } from '../features/devices/devicesSlice';
import { hideDialog } from '../actions/HideDialog';
export function deleteTransmitter() {
    return (dispatch, getState) => {
        const { edit_transmitter_dialog } = getState();
        const ajax = new Ajax(dispatch);
        const url = '/transmitter/' + edit_transmitter_dialog.id;
        ajax.del(url, undefined, () => {
            dispatch(transmitterDeleted(edit_transmitter_dialog.id));
            dispatch(deviceDeleted(edit_transmitter_dialog.id));
            dispatch(hideDialog());
        });
    };
}
