import { parseInteger, parseString, parseArray, parseLiteral, parseObject } from '../util/parse';
import { parseEndpoint } from '../types/endpoint';
import { parseTransmitterKind } from '../types/transmitter';
import { parseNexaCode } from '../types/NexaCode';
export const parseDeviceProtocolKind = parseLiteral((s) => {
    switch (s) {
        case 'nexa receiver': return 'nexa receiver';
        case 'nexa transmitter': return 'nexa transmitter';
        case 'hasta receiver': return 'hasta receiver';
        case 'hasta transmitter': return 'hasta transmitter';
        case 'zigbee': return 'zigbee';
        case 'mandolyn': return 'mandolyn';
        default: return undefined;
    }
});
export function toNexaTransmitterKind(t) {
    switch (t) {
        case 'dim': return 'dimmer';
        case 'onoff': return 'onoff';
        case 'rollershade': throw new Error('TransmitterKind "rollershade" can not be converted to NexaTransmitterKind');
    }
}
export function fromNexaTransmitterKind(t) {
    switch (t) {
        case 'dimmer': return 'dim';
        case 'onoff': return 'onoff';
    }
}
export const parseNexaTransmitterKind = parseLiteral((s) => {
    switch (s) {
        case 'onoff': return 'onoff';
        case 'dimmer': return 'dimmer';
        default: return undefined;
    }
});
export function toNexaReceiverKind(t) {
    switch (t) {
        case 'dim': return 'dimmer';
        case 'onoff': return 'onoff';
        case 'rollershade': throw new Error('TransmitterKind "rollershade" can not be converted to NexaReceiverKind');
    }
}
export function fromNexaReceiverKind(t) {
    switch (t) {
        case 'dimmer': return 'dim';
        case 'onoff': return 'onoff';
    }
}
export const parseNexaReceiverKind = parseLiteral((s) => {
    switch (s) {
        case 'onoff': return 'onoff';
        case 'dimmer': return 'dimmer';
        default: return undefined;
    }
});
export const parseDeviceProtocol = parseObject('DeviceProtocol', part => {
    const kind = part.req('name', parseDeviceProtocolKind);
    switch (kind) {
        case 'nexa receiver':
            return {
                name: 'nexa receiver',
                kind: part.req('kind', parseNexaReceiverKind),
                codes: part.req('codes', parseArray(parseNexaCode)),
            };
        case 'nexa transmitter':
            return {
                name: 'nexa transmitter',
                kind: part.req('kind', parseNexaTransmitterKind),
                code: part.req('code', parseInteger),
                unit: part.req('unit', parseInteger),
            };
        case 'hasta receiver':
            return {
                name: 'hasta receiver',
                codes: part.req('codes', parseArray(parseNexaCode)),
            };
        case 'hasta transmitter':
            return {
                name: 'hasta transmitter',
                code: part.req('code', parseInteger),
                unit: part.req('unit', parseInteger),
            };
        case 'mandolyn':
            return {
                name: 'mandolyn',
                code: part.req('code', parseString),
            };
        case 'zigbee':
            return {
                name: 'zigbee',
                endpoint: part.req('endpoint', parseEndpoint)
            };
    }
});
export const parseDevice = parseObject('Device', part => {
    const transmitter_kind = part.opt('transmitter_kind', parseString);
    const transmitter_kind_exists = transmitter_kind != undefined && transmitter_kind != '';
    return {
        id: part.req('id', parseInteger),
        title: part.req('title', parseString),
        comment: part.req('comment', parseString),
        protocol: part.req('protocol', parseDeviceProtocol),
        transmitter_kind: transmitter_kind_exists ? part.req('transmitter_kind', parseTransmitterKind) : undefined,
    };
});
