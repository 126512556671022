import React from 'react';
import { useAppSelector, useAppDispatch } from '../hooks';
import { ConfigOutlet } from '../components/ConfigOutlet';
import { DialogOutletEdit } from '../components/DialogOutletEdit';
import { newOutlet } from '../actions/NewOutlet';
export const ConfigOutlets = () => {
    const defOutlets = useAppSelector(state => state.definitions.outlets);
    let outlets = Array.from(defOutlets.values());
    outlets.sort((a, b) => a.title.localeCompare(b.title));
    const dispatch = useAppDispatch();
    var rows = [];
    for (const outlet of outlets) {
        rows.push(React.createElement(ConfigOutlet, Object.assign({ key: outlet.id }, outlet)));
    }
    return (React.createElement("div", { className: "page-container" },
        React.createElement(DialogOutletEdit, null),
        React.createElement("div", { className: "operations" },
            React.createElement("button", { className: "lw success", onClick: () => dispatch(newOutlet()) }, "\u00A0L\u00E4gg till kontakt...\u00A0")),
        React.createElement("div", { className: "config-rows config-outlets" }, rows)));
};
