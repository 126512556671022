import { combineSlices } from '@reduxjs/toolkit';
import { outletsSlice } from '../outlets/outletsSlice';
import { schedulesSlice } from '../schedules/schedulesSlice';
import { macrosSlice } from '../macros/macrosSlice';
import { transmittersSlice } from '../transmitters/transmittersSlice';
import { devicesSlice } from '../devices/devicesSlice';
import { panelsSlice } from '../panels/panelsSlice';
import { panelGroupsSlice } from '../panel_groups/panelGroupsSlice';
import { panelDevicesSlice } from '../panel_devices/panelDevicesSlice';
import { panelGroupOrderSlice } from '../panel_groups/panelGroupOrderSlice';
import { panelDeviceOrderSlice } from '../panel_devices/panelDeviceOrderSlice';
import { zigbeeNodesSlice } from '../zigbee_nodes/zigbeeNodesSlice';
import { zigbeeNodeDescriptorsSlice } from '../zigbee_node_descriptors/zigbeeNodeDescriptorsSlice';
export default combineSlices(outletsSlice, schedulesSlice, macrosSlice, transmittersSlice, devicesSlice, panelsSlice, panelGroupsSlice, panelDevicesSlice, panelGroupOrderSlice, panelDeviceOrderSlice, zigbeeNodesSlice, zigbeeNodeDescriptorsSlice);
