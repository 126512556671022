import React from 'react';
import { useAppSelector, useAppDispatch } from '../hooks';
import { editSchedule } from '../actions/EditSchedule';
import { ScheduleTime } from '../features/schedules/ScheduleTime';
import { ScheduleOperation } from '../features/schedules/ScheduleOperation';
import { ScheduleNextRun } from '../features/schedules/ScheduleNextRun';
export const ConfigScheduleRow = (props) => {
    const dispatch = useAppDispatch();
    const schedules = useAppSelector(state => state.definitions.schedules.entities);
    const devices = useAppSelector(state => state.definitions.devices);
    const schedule = schedules.get(props.id);
    if (schedule == undefined) {
        return null;
    }
    const device = devices.get(schedule.device_id);
    if (device == undefined) {
        return null;
    }
    return (React.createElement("div", { key: props.id, className: "config-schedule-row" },
        React.createElement("div", { style: { whiteSpace: 'nowrap' } },
            React.createElement(ScheduleTime, Object.assign({}, schedule.time))),
        React.createElement(ScheduleNextRun, { schedule: schedule }),
        React.createElement(ScheduleOperation, { schedule: schedule }),
        React.createElement("div", null, device.title),
        React.createElement("div", null,
            React.createElement("button", { className: "edit lw small", onClick: () => dispatch(editSchedule(props.id)) }, "\u00A0Redigera\u00A0"))));
};
