import { createSlice } from '@reduxjs/toolkit';
export const zigbeeNodesSlice = createSlice({
    name: 'zigbee_nodes',
    initialState: new Map(),
    reducers: {
        zigbeeNodesCleared: s => { s.clear(); },
        zigbeeNodeUpdated: (s, action) => { s.set(action.payload.id, action.payload); },
        zigbeeNodeDeleted: (s, action) => { s.delete(action.payload); },
    }
});
export const { zigbeeNodesCleared, zigbeeNodeUpdated, zigbeeNodeDeleted } = zigbeeNodesSlice.actions;
export default zigbeeNodesSlice.reducer;
