import React from 'react';
import { useAppSelector, useAppDispatch } from '../hooks';
import { Dialog } from '../components/DialogOld';
import { createPanelGroup } from '../actions/CreatePanelGroup';
import { savePanelGroup } from '../actions/SavePanelGroup';
import { deletePanelGroup } from '../actions/DeletePanelGroup';
import { updateEditPanelGroupDialog } from '../actions/UpdateEditPanelGroupDialog';
import { FormGroup } from '../components/FormGroup';
import { FormRow } from '../components/FormRow';
import { hideDialog } from '../actions/HideDialog';
const DeleteButton = props => (React.createElement("button", { key: "delete", type: "button", className: "delete lw danger", onClick: props.onClick }, "Ta bort"));
const CancelButton = props => (React.createElement("button", { key: "cancel", type: "button", className: "lw", onClick: props.onClick }, "Avbryt"));
const SaveButton = props => (React.createElement("button", { key: "save", type: "button", className: "save lw primary", onClick: props.onClick }, "Spara \u00E4ndringar"));
const CreateButton = props => (React.createElement("button", { key: "create", type: "button", className: "save lw primary", onClick: props.onClick }, "Skapa grupp"));
export const DialogPanelGroupEdit = (props) => {
    const dispatch = useAppDispatch();
    const dialog = useAppSelector(state => state.edit_panel_group_dialog);
    let footerleft, footerright;
    const id = dialog.id == null ? '' : dialog.id;
    if (id) {
        footerleft = [
            (React.createElement(DeleteButton, { key: "delete", onClick: () => dispatch(deletePanelGroup()) }))
        ];
        footerright = [
            (React.createElement(CancelButton, { key: "cancel", onClick: () => dispatch(hideDialog()) })),
            (React.createElement(SaveButton, { key: "save", onClick: () => dispatch(savePanelGroup()) }))
        ];
    }
    else {
        footerright = [
            (React.createElement(CancelButton, { key: "cancel", onClick: () => dispatch(hideDialog()) })),
            (React.createElement(CreateButton, { key: "create", onClick: () => dispatch(createPanelGroup()) }))
        ];
    }
    return (React.createElement(Dialog, { id: "group-edit", title: "Redigera grupp", footerleft: footerleft, footerright: footerright },
        React.createElement(FormRow, null,
            React.createElement("input", { name: "group_id", type: "hidden", value: id }),
            React.createElement("input", { name: "panel_id", type: "hidden", value: props.panel_id }),
            React.createElement(FormGroup, { label: "Namn" },
                React.createElement("input", { type: "text", name: "title", value: dialog.title, onChange: event => dispatch(updateEditPanelGroupDialog({ title: event.target.value })) }))),
        React.createElement(FormRow, null,
            React.createElement(FormGroup, { label: "Ordning" },
                React.createElement("input", { type: "text", name: "ord", value: isNaN(dialog.ord) ? '' : dialog.ord, onChange: event => dispatch(updateEditPanelGroupDialog({ ord: parseInt(event.target.value) })) })))));
};
