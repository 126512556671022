import { setupEditPanelDeviceDialog } from '../actions/SetupEditPanelDeviceDialog';
import { showDialog } from '../actions/ShowDialog';
import { get_any_transmitter_id } from '../util/definitions';
export function newPanelTransmitter() {
    return (dispatch, getState) => {
        const { definitions } = getState();
        let panel_group_id;
        for (const obj of definitions.panel_groups.values()) {
            panel_group_id = obj.id;
            break;
        }
        let device_id = get_any_transmitter_id(definitions);
        dispatch(setupEditPanelDeviceDialog({
            panel_device_id: null,
            device_id,
            panel_group_id,
            ord: '1'
        }));
        dispatch(showDialog('edit-panel-transmitter'));
    };
}
