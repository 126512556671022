import { SET_EDIT_SCHEDULE_DIALOG } from '../actions/SetEditScheduleDialog';
export const initialState = {
    id: null,
    kind: 'time',
    cron: '',
    sun: {
        k: 't',
        a: 0,
        w: { monday: true, tuesday: true, wednesday: true, thursday: true, friday: true, saturday: true, sunday: true },
        r: 'r0',
    },
    time: {
        h: 0,
        m: 0,
        w: { monday: true, tuesday: true, wednesday: true, thursday: true, friday: true, saturday: true, sunday: true },
    },
    active: true,
    transmitter_id: NaN,
    operation: 'on',
    dim_level: 15,
};
export function edit_schedule_dialog(state = initialState, action) {
    switch (action.type) {
        case SET_EDIT_SCHEDULE_DIALOG:
            return Object.assign({}, state, action.dialog);
        default:
            return state;
    }
}
