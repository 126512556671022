import { parseInteger, parseString, parseObject, parseObjectOrNull, parseArray, parseDate, parseOr } from '../../util/parse';
import { parseAddress } from '../../zigbee/address';
const parseSource = parseObject('source', part => {
    const cluster = part.req('cluster', parseInteger);
    const endpoint = part.req('endpoint', parseInteger);
    const ieee = part.req('ieee', parseString);
    return { cluster, endpoint, ieee };
});
export const parseBindingTableItem = parseObject('BindingTableItem', part => {
    if (part.has('destination')) {
        return {
            destination: part.req('destination', parseAddress),
            source: part.req('source', parseSource),
        };
    }
    else {
        const cluster = part.req('src_cluster', parseInteger);
        const endpoint = part.req('src_ep', parseInteger);
        const ieee = part.req('src_ieee', parseString);
        const source = {
            cluster,
            endpoint,
            ieee
        };
        if (part.has('dest_group')) {
            const group = part.req('dest_group', parseInteger);
            return {
                destination: { group },
                source
            };
        }
        else if (part.has('dest_ep') && part.has('dest_ieee')) {
            const ieee = part.req('dest_ieee', parseString);
            const endpoint = part.req('dest_ep', parseInteger);
            return {
                destination: { ieee, endpoint },
                source
            };
        }
        else {
            return part.error('Invalid binding table item');
        }
    }
});
export const parseBindingTableOld = (ctx, v) => {
    const items = parseArray(parseBindingTableItem)(ctx, v);
    return { fetched_ts: new Date(0),
        items };
};
export const parseBindingTableNew = parseObjectOrNull('BindingTable', part => {
    const fetched_ts = part.req('fetched_ts', parseDate);
    const items = part.req('items', parseArray(parseBindingTableItem));
    return { fetched_ts, items };
});
export const parseBindingTable = parseOr(parseBindingTableNew, parseBindingTableOld);
