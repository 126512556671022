import React from 'react';
export function ArrowUp() {
    return (React.createElement("svg", { "aria-hidden": "true", className: "lw-icon", fill: "currentColor", width: "11", height: "13", role: "img", version: "1.1", viewBox: "0 0 11 13" },
        React.createElement("path", { d: "M 4,7 H 0 L 5.5,0 11,7 H 7 v 6 H 4 Z" })));
}
export function ArrowDown() {
    return (React.createElement("svg", { "aria-hidden": "true", className: "lw-icon", fill: "currentColor", width: "11", height: "13", role: "img", version: "1.1", viewBox: "0 0 11 13" },
        React.createElement("path", { d: "M 4,6 H 0 L 5.5,13 11,6 H 7 V 0 H 4 Z" })));
}
export function Stop() {
    return (React.createElement("svg", { "aria-hidden": "true", className: "lw-icon", fill: "currentColor", width: "10", height: "13", role: "img", version: "1.1", viewBox: "0 0 10 13" },
        React.createElement("path", { d: "M 1,2 H 11 V 12 H 1 Z" })));
}
export function Wrench() {
    return (React.createElement("svg", { "aria-hidden": "true", className: "lw-icon", fill: "currentColor", width: "15", height: "18", role: "img", version: "1.1", viewBox: "0 0 11 13" },
        React.createElement("path", { d: "M 7.8,0.1 A 3.6,3.4 0 0 0 4.4,3.5 3.6,3.4 0 0 0 4.8,5.1 L 0,11 2.2,13 7,6.8 A 3.6,3.4 0 0 0 8,6.9 3.6,3.4 0 0 0 11.5,4.2 L 7.6,5.4 6.5,2 10.2,0.8 A 3.6,3.4 0 0 0 8,0.1 Z" })));
}
export function Dimmer() {
    return (React.createElement("svg", { "aria-hidden": "true", className: "lw-icon", fill: "currentColor", width: "25", height: "28", role: "img", version: "1.1", viewBox: "0 0 3 3" },
        React.createElement("g", null,
            React.createElement("rect", { x: "0.2", y: "2.6", width: ".25", height: "0.2", ry: "0" }),
            React.createElement("rect", { x: "0.6", y: "2.3", width: ".25", height: "0.5", ry: "0" }),
            React.createElement("rect", { x: "1.0", y: "2.0", width: ".25", height: "0.8", ry: "0" }),
            React.createElement("rect", { x: "1.4", y: "1.7", width: ".25", height: "1.1", ry: "0" }),
            React.createElement("rect", { x: "1.8", y: "1.4", width: ".25", height: "1.4", ry: "0" }),
            React.createElement("rect", { x: "2.2", y: "1.1", width: ".25", height: "1.7", ry: "0" }),
            React.createElement("rect", { x: "2.6", y: "0.8", width: ".25", height: "2.0", ry: "0" }))));
}
