import { SET_EDIT_OUTLET_DIALOG } from '../actions/SetEditOutletDialog';
export const initialState = {
    id: null,
    title: '',
    kind: 'onoff',
    codes: [],
    comment: ''
};
export function edit_outlet_dialog(state = initialState, action) {
    switch (action.type) {
        case SET_EDIT_OUTLET_DIALOG:
            return Object.assign({}, state, action.dialog);
        default:
            return state;
    }
}
