export const clusterName = (c) => {
    switch (c) {
        case 0x0000: return 'Basic';
        case 0x0001: return 'PowerConfiguration';
        case 0x0002: return 'DeviceTemperatureConfiguration';
        case 0x0003: return 'Identify';
        case 0x0004: return 'Groups';
        case 0x0005: return 'Scenes';
        case 0x0006: return 'OnOff';
        case 0x0007: return 'OnOffSwitchConfiguration';
        case 0x0008: return 'LevelControl';
        case 0x0009: return 'Alarms';
        case 0x000a: return 'Time';
        case 0x000b: return 'RssiLocation';
        case 0x000c: return 'AnalogInput';
        case 0x000d: return 'AnalogOutput';
        case 0x000e: return 'AnalogValue';
        case 0x000f: return 'BinaryInput';
        case 0x0010: return 'BinaryOutput';
        case 0x0011: return 'BinaryValue';
        case 0x0012: return 'MultiStateInput';
        case 0x0013: return 'MultiStateOutput';
        case 0x0014: return 'MultiStateValue';
        case 0x0019: return 'OTAU';
        case 0x001a: return 'PowerProfile';
        case 0x0020: return 'PollControl';
        case 0x0021: return 'GreenPower';
        case 0x0100: return 'ShadeConfiguration';
        case 0x0101: return 'DoorLock';
        case 0x0102: return 'WindowCovering';
        case 0x0202: return 'FanControl';
        case 0x0300: return 'ColorControl';
        case 0x0400: return 'IlluminanceMeasurement';
        case 0x0401: return 'IlluminanceLevelSensing';
        case 0x0402: return 'TemperatureMeasurement';
        case 0x0403: return 'PressureMeasurement';
        case 0x0404: return 'FlowMeasurement';
        case 0x0405: return 'RelativeHumidityMeasurement';
        case 0x0406: return 'OccupancySensing';
        case 0x040c: return 'CarbonMonoxide';
        case 0x042a: return 'PM25';
        case 0x0500: return 'IAS:Zone'; // Intruder Alarm Systems: Security zone devices
        case 0x0501: return 'IAS:ACE'; // Intruder Alarm Systems: Ancillary Control Equipment
        case 0x0502: return 'IAS:WD'; // Intruder Alarm Systems: Warning Devices
        case 0x0700: return 'Price';
        case 0x0701: return 'Demand Response and Load Control';
        case 0x0702: return 'Metering';
        case 0x0703: return 'Messaging';
        case 0x0704: return 'Tunneling';
        case 0x0800: return 'Key Establishment';
        case 0x0b01: return 'MeterIdentification';
        case 0x0b04: return 'ElectricalMeasurement';
        case 0x0b05: return 'Diagnostics';
        case 0x1000: return 'TouchLink';
        default: return null;
    }
};
export const clusterNameOrHex = (c) => {
    const s = clusterName(c);
    if (s == null)
        return '0x' + c.toString(16).padStart(4, '0');
    else
        return s;
};
export const clusterNameOrHexNoPrefix = (c) => {
    const s = clusterName(c);
    if (s == null)
        return c.toString(16).padStart(4, '0');
    else
        return s;
};
