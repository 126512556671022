export const attributeName = (c, a) => {
    switch (a) { // global attributes
        case 0xfffd: return 'ClusterRevision';
        case 0xfffe: return 'AttributeReportingStatus';
    }
    switch (c) {
        case 0x0000: // Basic
            switch (a) {
                case 0x0000: return 'ZCLVersion';
                case 0x0001: return 'ApplicationVersion';
                case 0x0002: return 'StackVersion';
                case 0x0003: return 'HWVersion';
                case 0x0004: return 'ManufacturerName';
                case 0x0005: return 'ModelIdentifier';
                case 0x0006: return 'DateCode';
                case 0x0007: return 'PowerSource';
                case 0x0008: return 'GenericDevice-Class';
                case 0x0009: return 'GenericDevice-Type';
                case 0x000a: return 'ProductCode';
                case 0x000b: return 'ProductURL';
                case 0x000c: return 'ManufacturerVersionDetails';
                case 0x000d: return 'SerialNumber';
                case 0x000e: return 'ProductLabel';
                case 0x0010: return 'LocationDescription';
                case 0x0011: return 'PhysicalEnvironment';
                case 0x0012: return 'DeviceEnabled';
                case 0x0013: return 'AlarmMask';
                case 0x0014: return 'DisableLocalConfig';
                case 0x4000: return 'SWBuildID';
            }
            break;
        case 0x0001: // PowerConfiguration
            switch (a) {
                case 0x0000: return 'MainsVoltage';
                case 0x0001: return 'MainsFrequency';
                case 0x0010: return 'MainsAlarmMask';
                case 0x0011: return 'MainsVoltageMinThreshold';
                case 0x0012: return 'MainsVoltageMaxThreshold';
                case 0x0013: return 'MainsVoltageDwellTripPoint';
                case 0x0020: return 'BatteryVoltage';
                case 0x0021: return 'BatteryPercentageRemaining';
                case 0x0030: return 'BatteryManufacturer';
                case 0x0031: return 'BatterySize';
                case 0x0032: return 'BatteryAHrRating';
                case 0x0033: return 'BatteryQuantity';
                case 0x0034: return 'BatteryRatedVoltage';
                case 0x0035: return 'BatteryAlarmMask';
                case 0x0036: return 'BatteryVoltageMinThreshold';
                case 0x0037: return 'BatteryVoltageThreshold1';
                case 0x0038: return 'BatteryVoltageThreshold2';
                case 0x0039: return 'BatteryVoltageThreshold3';
                case 0x003a: return 'BatteryPercentageMinThreshold';
                case 0x003b: return 'BatteryPercentageThreshold1';
                case 0x003c: return 'BatteryPercentageThreshold2';
                case 0x003d: return 'BatteryPercentageThreshold3';
                case 0x003e: return 'BatteryAlarmState';
                case 0x0040: return 'Battery2Voltage';
                case 0x0041: return 'Battery2PercentageRemaining';
                case 0x0050: return 'Battery2Manufacturer';
                case 0x0051: return 'Battery2Size';
                case 0x0052: return 'Battery2AHrRating';
                case 0x0053: return 'Battery2Quantity';
                case 0x0054: return 'Battery2RatedVoltage';
                case 0x0055: return 'Battery2AlarmMask';
                case 0x0056: return 'Battery2VoltageMinThreshold';
                case 0x0057: return 'Battery2VoltageThreshold1';
                case 0x0058: return 'Battery2VoltageThreshold2';
                case 0x0059: return 'Battery2VoltageThreshold3';
                case 0x005a: return 'Battery2PercentageMinThreshold';
                case 0x005b: return 'Battery2PercentageThreshold1';
                case 0x005c: return 'Battery2PercentageThreshold2';
                case 0x005d: return 'Battery2PercentageThreshold3';
                case 0x005e: return 'Battery2AlarmState';
                case 0x0060: return 'Battery3Voltage';
                case 0x0061: return 'Battery3PercentageRemaining';
                case 0x0070: return 'Battery3Manufacturer';
                case 0x0071: return 'Battery3Size';
                case 0x0072: return 'Battery3AHrRating';
                case 0x0073: return 'Battery3Quantity';
                case 0x0074: return 'Battery3RatedVoltage';
                case 0x0075: return 'Battery3AlarmMask';
                case 0x0076: return 'Battery3VoltageMinThreshold';
                case 0x0077: return 'Battery3VoltageThreshold1';
                case 0x0078: return 'Battery3VoltageThreshold2';
                case 0x0079: return 'Battery3VoltageThreshold3';
                case 0x007a: return 'Battery3PercentageMinThreshold';
                case 0x007b: return 'Battery3PercentageThreshold1';
                case 0x007c: return 'Battery3PercentageThreshold2';
                case 0x007d: return 'Battery3PercentageThreshold3';
                case 0x007e: return 'Battery3AlarmState';
            }
            break;
        case 0x0002: // DeviceTemperatureConfiguration
            switch (a) {
                case 0x0000: return 'CurrentTemperature';
                case 0x0001: return 'MinTempExperienced';
                case 0x0002: return 'MaxTempExperienced';
                case 0x0003: return 'OverTempTotalDwell';
                case 0x0010: return 'DeviceTempAlarmMask';
                case 0x0011: return 'LowTempThreshold';
                case 0x0012: return 'HighTempThreshold';
                case 0x0013: return 'LowTempDwellTripPoint';
                case 0x0014: return 'HighTempDwellTripPoint';
            }
            break;
        case 0x0003: // Identify
            switch (a) {
                case 0x0000: return 'IdentifyTime';
            }
            break;
        case 0x0004: // Groups
            switch (a) {
                case 0x0000: return 'NameSupport';
            }
            break;
        case 0x0005: // Scenes
            switch (a) {
                case 0x0000: return 'SceneCount';
                case 0x0001: return 'CurrentScene';
                case 0x0002: return 'CurrentGroup';
                case 0x0003: return 'SceneValid';
                case 0x0004: return 'NameSupport';
                case 0x0005: return 'LastConfiguredBy';
            }
            break;
        case 0x0006: // OnOff
            switch (a) {
                case 0x0000: return 'OnOff';
                case 0x4000: return 'GlobalSceneControl';
                case 0x4001: return 'OnTime';
                case 0x4002: return 'OffWaitTime';
                case 0x4003: return 'StartUpOnOff';
            }
            break;
        case 0x0008: // LevelControl
            switch (a) {
                case 0x0000: return 'CurrentLevel';
                case 0x0001: return 'RemainingTime';
                case 0x0002: return 'MinLevel';
                case 0x0003: return 'MaxLevel';
                case 0x0004: return 'CurrentFrequency';
                case 0x0005: return 'MinFrequency';
                case 0x0006: return 'MaxFrequency';
                case 0x0010: return 'OnOffTransitionTime';
                case 0x0011: return 'OnLevel';
                case 0x0012: return 'OnTransitionTime';
                case 0x0013: return 'OffTransitionTime';
                case 0x0014: return 'DefaultMoveRate';
                case 0x000f: return 'Options';
                case 0x4000: return 'StartUpCurrentLevel';
            }
            break;
        case 0x0019: // OTAU
            switch (a) {
                case 0x0000: return 'UpgradeServerID';
                case 0x0001: return 'FileOffset';
                case 0x0002: return 'CurrentFileVersion';
                case 0x0003: return 'CurrentZigBeeStackVersion';
                case 0x0004: return 'DownloadedFileVersion';
                case 0x0005: return 'DownloadedZigBeeStackVersion';
                case 0x0006: return 'ImageUpgradeStatus';
                case 0x0007: return 'Manufacturer ID';
                case 0x0008: return 'Image Type ID';
                case 0x0009: return 'MinimumBlockPeriod';
                case 0x000a: return 'Image Stamp';
                case 0x000b: return 'UpgradeActivationPolicy';
                case 0x000c: return 'UpgradeTimeout Policy';
            }
            break;
        case 0x0702: // Metering
            if (a >= 0x0700 && a <= 0x07ff) {
                const tier = a & 0x00f0 >> 8;
                const block = a & 0x000f;
                if (tier == 0) {
                    return 'CurrentNoTierBlock' + block + 'SummationDelivered';
                }
                else {
                    return 'CurrentTier' + tier + 'Block' + block + 'SummationDelivered';
                }
            }
            switch (a) {
                case 0x0000: return 'CurrentSummationDelivered';
                case 0x0001: return 'CurrentSummationReceived';
                case 0x0002: return 'CurrentMaxDemandDelivered';
                case 0x0003: return 'CurrentMaxDemandReceived';
                case 0x0004: return 'DFTSummation';
                case 0x0005: return 'DailyFreezeTime';
                case 0x0006: return 'PowerFactor';
                case 0x0007: return 'ReadingSnapShotTime';
                case 0x0008: return 'CurrentMaxDemandDeliveredTime';
                case 0x0009: return 'CurrentMaxDemandReceivedTime';
                case 0x000a: return 'DefaultUpdatePeriod';
                case 0x000b: return 'FastPollUpdatePeriod';
                case 0x000c: return 'CurrentBlockPeriodConsumptionDelivered';
                case 0x000d: return 'DailyConsumptionTarget';
                case 0x000e: return 'CurrentBlock';
                case 0x000f: return 'ProfileIntervalPeriod';
                case 0x0010: return 'IntervalReadReportingPeriod';
                case 0x0011: return 'PresetReadingTime';
                case 0x0012: return 'VolumePerReport';
                case 0x0013: return 'FlowRestriction';
                case 0x0014: return 'Supply Status';
                case 0x0015: return 'CurrentInletEnergyCarrierSummation';
                case 0x0016: return 'CurrentOutletEnergyCarrierSummation';
                case 0x0017: return 'InletTemperature';
                case 0x0018: return 'OutletTemperature';
                case 0x0019: return 'ControlTemperature';
                case 0x001a: return 'CurrentInletEnergyCarrierDemand';
                case 0x001b: return 'CurrentOutletEnergyCarrierDemand';
                case 0x001c: return 'PreviousBlockPeriodConsumptionDelivered';
                case 0x0100: return 'CurrentTier1SummationDelivered';
                case 0x0101: return 'CurrentTier1SummationReceived';
                case 0x0102: return 'CurrentTier2SummationDelivered';
                case 0x0103: return 'CurrentTier2SummationReceived';
                case 0x0104: return 'CurrentTier3SummationDelivered';
                case 0x0105: return 'CurrentTier3SummationReceived';
                case 0x0106: return 'CurrentTier4SummationDelivered';
                case 0x0107: return 'CurrentTier4SummationReceived';
                case 0x0108: return 'CurrentTier5SummationDelivered';
                case 0x0109: return 'CurrentTier5SummationReceived';
                case 0x010a: return 'CurrentTier6SummationDelivered';
                case 0x010b: return 'CurrentTier6SummationReceived';
                case 0x010c: return 'CurrentTier7SummationDelivered';
                case 0x010d: return 'CurrentTier7SummationReceived';
                case 0x010e: return 'CurrentTier8SummationDelivered';
                case 0x010f: return 'CurrentTier8SummationReceived';
                case 0x0110: return 'CurrentTier9SummationDelivered';
                case 0x0111: return 'CurrentTier9SummationReceived';
                case 0x0112: return 'CurrentTier10SummationDelivered';
                case 0x0113: return 'CurrentTier10SummationReceived';
                case 0x0114: return 'CurrentTier11SummationDelivered';
                case 0x0115: return 'CurrentTier11SummationReceived';
                case 0x0116: return 'CurrentTier12SummationDelivered';
                case 0x0117: return 'CurrentTier12SummationReceived';
                case 0x0118: return 'CurrentTier13SummationDelivered';
                case 0x0119: return 'CurrentTier13SummationReceived';
                case 0x011a: return 'CurrentTier14SummationDelivered';
                case 0x011b: return 'CurrentTier14SummationReceived';
                case 0x011c: return 'CurrentTier15SummationDelivered';
                case 0x011d: return 'CurrentTier15SummationReceived';
                case 0x0200: return 'Status';
                case 0x0201: return 'RemainingBatteryLife';
                case 0x0202: return 'HoursInOperation';
                case 0x0203: return 'HoursInFault';
                case 0x0300: return 'UnitofMeasure';
                case 0x0301: return 'Multiplier';
                case 0x0302: return 'Divisor';
                case 0x0303: return 'SummationFormatting';
                case 0x0304: return 'DemandFormatting';
                case 0x0305: return 'HistoricalConsumptionFormatting';
                case 0x0306: return 'MeteringDeviceType';
                case 0x0307: return 'SiteID';
                case 0x0308: return 'MeterSerialNumber';
                case 0x0309: return 'EnergyCarrierUnitOfMeasure';
                case 0x030a: return 'EnergyCarrierSummationFormatting';
                case 0x030b: return 'EnergyCarrierDemandFormatting';
                case 0x030c: return 'TemperatureUnitOfMeasure';
                case 0x030d: return 'TemperatureFormatting';
                case 0x0400: return 'InstantaneousDemand';
                case 0x0401: return 'CurrentDayConsumptionDelivered';
                case 0x0402: return 'CurrentDayConsumptionReceived';
                case 0x0403: return 'PreviousDayConsumptionDelivered';
                case 0x0404: return 'PreviousDayConsumptionReceived';
                case 0x0405: return 'CurrentPartialProfileIntervalStartTimeDelivered';
                case 0x0406: return 'CurrentPartialProfileIntervalStartTimeReceived';
                case 0x0407: return 'CurrentPartialProfileIntervalValueDelivered';
                case 0x0408: return 'CurrentPartialProfileIntervalValueReceived';
                case 0x0409: return 'CurrentDayMaxPressure';
                case 0x040a: return 'CurrentDayMinPressure';
                case 0x040b: return 'PreviousDayMaxPressure';
                case 0x040c: return 'PreviousDayMinPressure';
                case 0x040d: return 'CurrentDayMaxDemand';
                case 0x040e: return 'PreviousDayMaxDemand';
                case 0x040f: return 'CurrentMonthMaxDemand';
                case 0x0410: return 'CurrentYearMaxDemand';
                case 0x0411: return 'CurrentDayMaxEnergyCarrierDemand';
                case 0x0412: return 'PreviousDayMaxEnergyCarrierDemand';
                case 0x0413: return 'CurrentMonthMaxEnergyCarrierDemand';
                case 0x0414: return 'CurrentMonthMinEnergyCarrierDemand';
                case 0x0415: return 'CurrentYearMaxEnergyCarrierDemand';
                case 0x0416: return 'CurrentYearMinEnergyCarrierDemand';
                case 0x0500: return 'MaxNumberOfPeriodsDelivered';
                case 0x0600: return 'CurrentDemandDelivered';
                case 0x0601: return 'DemandLimit';
                case 0x0602: return 'DemandIntegrationPeriod';
                case 0x0603: return 'NumberOfDemandSubintervals';
                // 0x7000 see above
                case 0x0800: return 'GenericAlarmMask';
                case 0x0801: return 'ElectricityAlarmMask';
                case 0x0802: return 'Generic Flow/PressureAlarmMask';
                case 0x0803: return 'WaterSpecificAlarmMask';
                case 0x0804: return 'HeatandCoolingSpecificAlarmMask';
                case 0x0805: return 'GasSpecificAlarmMask';
            }
            break;
        case 0x0b04: // ElectricalMeasurement
            switch (a) {
                case 0x0000: return 'MeasurementType';
                case 0x0100: return 'DCVoltage';
                case 0x0101: return 'DCVoltageMin';
                case 0x0102: return 'DCVoltageMax';
                case 0x0103: return 'DCCurrent';
                case 0x0104: return 'DCCurrentMin';
                case 0x0105: return 'DCCurrentMax';
                case 0x0106: return 'DCPower';
                case 0x0107: return 'DCPowerMin';
                case 0x0108: return 'DCPowerMax';
                case 0x0200: return 'DCVoltageMultiplier';
                case 0x0201: return 'DCVoltageDivisor';
                case 0x0202: return 'DCCurrentMultiplier';
                case 0x0203: return 'DCCurrentDivisor';
                case 0x0204: return 'DCPowerMultiplier';
                case 0x0205: return 'DCPowerDivisor';
                case 0x0300: return 'ACFrequency';
                case 0x0301: return 'ACFrequencyMin';
                case 0x0302: return 'ACFrequencyMax';
                case 0x0303: return 'NeutralCurrent';
                case 0x0304: return 'TotalActivePower';
                case 0x0305: return 'TotalReactivePower';
                case 0x0306: return 'TotalApparentPower';
                case 0x0307: return 'Measured1stHarmonicCurrent';
                case 0x0308: return 'Measured3rdHarmonicCurrent';
                case 0x0309: return 'Measured5thHarmonicCurrent';
                case 0x030a: return 'Measured7thHarmonicCurrent';
                case 0x030b: return 'Measured9thHarmonicCurrent';
                case 0x030c: return 'Measured11thHarmonicCurrent';
                case 0x030d: return 'MeasuredPhase1stHarmonicCurrent';
                case 0x030e: return 'MeasuredPhase3rdHarmonicCurrent';
                case 0x030f: return 'MeasuredPhase5thHarmonicCurrent';
                case 0x0310: return 'MeasuredPhase7thHarmonicCurrent';
                case 0x0311: return 'MeasuredPhase9thHarmonicCurrent';
                case 0x0312: return 'MeasuredPhase11thHarmonicCurrent';
                case 0x0400: return 'ACFrequencyMultiplier';
                case 0x0401: return 'ACFrequencyDivisor';
                case 0x0402: return 'PowerMultiplier';
                case 0x0403: return 'PowerDivisor';
                case 0x0404: return 'HarmonicCurrentMultiplier';
                case 0x0405: return 'PhaseHarmonicCurrentMultiplier';
                case 0x0501: return 'LineCurrent';
                case 0x0502: return 'ActiveCurrent';
                case 0x0503: return 'ReactiveCurrent';
                case 0x0505: return 'RMSVoltage';
                case 0x0506: return 'RMSVoltageMin';
                case 0x0507: return 'RMSVoltageMax';
                case 0x0508: return 'RMSCurrent';
                case 0x0509: return 'RMSCurrentMin';
                case 0x050a: return 'RMSCurrentMax';
                case 0x050b: return 'ActivePower';
                case 0x050c: return 'ActivePowerMin';
                case 0x050d: return 'ActivePowerMax';
                case 0x050e: return 'ReactivePower';
                case 0x050f: return 'ApparentPower';
                case 0x0510: return 'PowerFactor';
                case 0x0511: return 'AverageRMSVoltageMeasurementPeriod';
                case 0x0512: return 'AverageRMSOverVoltageCounter';
                case 0x0513: return 'AverageRMSUnderVoltageCounter';
                case 0x0514: return 'RMSExtremeOverVoltagePeriod';
                case 0x0515: return 'RMSExtremeUnderVoltagePeriod';
                case 0x0516: return 'RMSVoltageSagPeriod';
                case 0x0517: return 'RMSVoltageSwellPeriod';
                case 0x0600: return 'ACVoltageMultiplier';
                case 0x0601: return 'ACVoltageDivisor';
                case 0x0602: return 'ACCurrentMultiplier';
                case 0x0603: return 'ACCurrentDivisor';
                case 0x0604: return 'ACPowerMultiplier';
                case 0x0605: return 'ACPowerDivisor';
                case 0x0700: return 'DCOverloadAlarmsMask';
                case 0x0701: return 'DCVoltageOverload';
                case 0x0702: return 'DCCurrentOverload';
                case 0x0800: return 'ACAlarmsMask';
                case 0x0801: return 'ACVoltageOverload';
                case 0x0802: return 'ACCurrentOverload';
                case 0x0803: return 'ACActivePowerOverload';
                case 0x0804: return 'ACReactivePowerOverload';
                case 0x0805: return 'AverageRMSOverVoltage';
                case 0x0806: return 'AverageRMSUnderVoltage';
                case 0x0807: return 'RMSExtremeOverVoltage';
                case 0x0808: return 'RMSExtremeUnderVoltage';
                case 0x0809: return 'RMSVoltageSag';
                case 0x080a: return 'RMSVoltageSwell';
                case 0x0901: return 'LineCurrentPhB';
                case 0x0902: return 'ActiveCurrentPhB';
                case 0x0903: return 'ReactiveCurrentPhB';
                case 0x0905: return 'RMSVoltagePhB';
                case 0x0906: return 'RMSVoltageMinPhB';
                case 0x0907: return 'RMSVoltageMaxPhB';
                case 0x0908: return 'RMSCurrentPhB';
                case 0x0909: return 'RMSCurrentMinPhB';
                case 0x090a: return 'RMSCurrentMaxPhB';
                case 0x090b: return 'ActivePowerPhB';
                case 0x090c: return 'ActivePowerMinPhB';
                case 0x090d: return 'ActivePowerMaxPhB';
                case 0x090e: return 'ReactivePowerPhB';
                case 0x090f: return 'ApparentPowerPhB';
                case 0x0910: return 'PowerFactorPhB';
                case 0x0911: return 'AverageRMSVoltageMeasurementPeriodPhB';
                case 0x0912: return 'AverageRMSOverVoltageCounterPhB';
                case 0x0913: return 'AverageRMSUnderVoltageCounterPhB';
                case 0x0914: return 'RMSExtremeOverVoltagePeriodPhB';
                case 0x0915: return 'RMSExtremeUnderVoltagePeriodPhB';
                case 0x0916: return 'RMSVoltageSagPeriodPhB';
                case 0x0917: return 'RMSVoltageSwellPeriodPhB';
                case 0x0a01: return 'LineCurrentPhC';
                case 0x0a02: return 'ActiveCurrentPhC';
                case 0x0a03: return 'ReactiveCurrentPhC';
                case 0x0a05: return 'RMSVoltagePhC';
                case 0x0a06: return 'RMSVoltageMinPhC';
                case 0x0a07: return 'RMSVoltageMaxPhC';
                case 0x0a08: return 'RMSCurrentPhC';
                case 0x0a09: return 'RMSCurrentMinPhC';
                case 0x0a0a: return 'RMSCurrentMaxPhC';
                case 0x0a0b: return 'ActivePowerPhC';
                case 0x0a0c: return 'ActivePowerMinPhC';
                case 0x0a0d: return 'ActivePowerMaxPhC';
                case 0x0a0e: return 'ReactivePowerPhC';
                case 0x0a0f: return 'ApparentPowerPhC';
                case 0x0a10: return 'PowerFactorPhC';
                case 0x0a11: return 'AverageRMSVoltageMeasurementPeriodPhC';
                case 0x0a12: return 'AverageRMSOverVoltageCounterPhC';
                case 0x0a13: return 'AverageRMSUnderVoltageCounterPhC';
                case 0x0a14: return 'RMSExtremeOverVoltagePeriodPhC';
                case 0x0a15: return 'RMSExtremeUnderVoltagePeriodPhC';
                case 0x0a16: return 'RMSVoltageSagPeriodPhC';
                case 0x0a17: return 'RMSVoltageSwellPeriodPhC';
            }
            break;
        case 0x0300: // ColorControl
            switch (a) {
                // Color Information
                case 0x0000: return 'CurrentHue';
                case 0x0001: return 'CurrentSaturation';
                case 0x0002: return 'RemainingTime';
                case 0x0003: return 'CurrentX';
                case 0x0004: return 'CurrentY';
                case 0x0005: return 'DriftCompensation';
                case 0x0006: return 'CompensationText';
                case 0x0007: return 'ColorTemperatureMireds';
                case 0x0008: return 'ColorMode';
                case 0x000f: return 'Options';
                case 0x4000: return 'EnhancedCurrentHue';
                case 0x4001: return 'EnhancedColorMode';
                case 0x4002: return 'ColorLoopActive';
                case 0x4003: return 'ColorLoopDirection';
                case 0x4004: return 'ColorLoopTime';
                case 0x4005: return 'ColorLoopStartEnhancedHue';
                case 0x4006: return 'ColorLoopStoredEnhancedHue';
                case 0x400a: return 'ColorCapabilities';
                case 0x400b: return 'ColorTempPhysicalMinMireds';
                case 0x400c: return 'ColorTempPhysicalMaxMireds';
                case 0x400d: return 'CoupleColorTempToLevelMinMireds';
                case 0x4010: return 'StartUpColorTemperatureMireds';
                // Defined Primaries Information
                case 0x0010: return 'NumberOfPrimaries';
                case 0x0011: return 'Primary1X';
                case 0x0012: return 'Primary1Y';
                case 0x0013: return 'Primary1Intensity';
                case 0x0014: return 'Reserved';
                case 0x0015: return 'Primary2X';
                case 0x0016: return 'Primary2Y';
                case 0x0017: return 'Primary2Intensity';
                case 0x0018: return 'Reserved';
                case 0x0019: return 'Primary3X';
                case 0x001a: return 'Primary3Y';
                case 0x001b: return 'Primary3Intensity';
                // Additional Defined Primaries Information
                case 0x0020: return 'Primary4X';
                case 0x0021: return 'Primary4Y';
                case 0x0022: return 'Primary4Intensity';
                case 0x0023: return 'Reserved';
                case 0x0024: return 'Primary5X';
                case 0x0025: return 'Primary5Y';
                case 0x0026: return 'Primary5Intensity';
                case 0x0027: return 'Reserved';
                case 0x0028: return 'Primary6X';
                case 0x0029: return 'Primary6Y';
                case 0x002a: return 'Primary6Intensity';
                // Defined Color Point Settings
                case 0x0030: return 'WhitePointX';
                case 0x0031: return 'WhitePointY';
                case 0x0032: return 'ColorPointRX';
                case 0x0033: return 'ColorPointRY';
                case 0x0034: return 'ColorPointRIntensity';
                case 0x0035: return 'Reserved';
                case 0x0036: return 'ColorPointGX';
                case 0x0037: return 'ColorPointGY';
                case 0x0038: return 'ColorPointGIntensity';
                case 0x0039: return 'Reserved';
                case 0x003a: return 'ColorPointBX';
                case 0x003b: return 'ColorPointBY';
                case 0x003c: return 'ColorPointBIntensity';
            }
            break;
        case 0x0202: // FanControl
            switch (a) {
                case 0x0000: return 'FanMode';
                case 0x0001: return 'FanModeSequence';
            }
            break;
        case 0x0402: // TemperatureMeasurement
            switch (a) {
                case 0x0000: return 'MeasuredValue';
                case 0x0001: return 'MinMeasuredValue';
                case 0x0002: return 'MaxMeasuredValue';
                case 0x0003: return 'Tolerance';
            }
            break;
        case 0x0403: // PressureMeasurement
            switch (a) {
                case 0x0000: return 'MeasuredValue';
                case 0x0001: return 'MinMeasuredValue';
                case 0x0002: return 'MaxMeasuredValue';
                case 0x0003: return 'Tolerance';
                case 0x0010: return 'ScaledValue';
                case 0x0011: return 'MinScaledValue';
                case 0x0012: return 'MaxScaledValue';
                case 0x0013: return 'ScaledTolerance';
                case 0x0014: return 'Scale';
            }
            break;
        case 0x0405: // RelativeHumidity
            switch (a) {
                case 0x0000: return 'MeasuredValue';
                case 0x0001: return 'MinMeasuredValue';
                case 0x0002: return 'MaxMeasuredValue';
                case 0x0003: return 'Tolerance';
            }
            break;
        case 0x0500: // IAS:Zone
            switch (a) {
                case 0x0000: return 'ZoneState';
                case 0x0001: return 'ZoneType';
                case 0x0002: return 'ZoneStatus';
                case 0x0010: return 'IAS_CIE_Address';
                case 0x0011: return 'ZoneID';
                case 0x0012: return 'NumberOfZoneSensitivityLevelsSupported';
                case 0x0013: return 'CurrentZoneSensitivityLevel';
            }
            break;
        case 0x0502: // IAS:WD
            switch (a) {
                case 0x0000: return 'MaxDuration';
            }
            break;
        case 0x0b05: // Diagnostics
            switch (a) {
                case 0x0000: return 'NumberOfResets';
                case 0x0001: return 'PersistentMemoryWrites';
                case 0x0100: return 'MacRxBcast';
                case 0x0101: return 'MacTxBcast';
                case 0x0102: return 'MacRxUcast';
                case 0x0103: return 'MacTxUcast';
                case 0x0104: return 'MacTxUcastRetry';
                case 0x0105: return 'MacTxUcastFail';
                case 0x0106: return 'APSRxBcast';
                case 0x0107: return 'APSTxBcast';
                case 0x0108: return 'APSRxUcast';
                case 0x0109: return 'APSTxUcastSuccess';
                case 0x010a: return 'APSTxUcastRetry';
                case 0x010b: return 'APSTxUcastFail';
                case 0x010c: return 'RouteDiscInitiated';
                case 0x010d: return 'NeighborAdded';
                case 0x010e: return 'NeighborRemoved';
                case 0x010f: return 'NeighborStale';
                case 0x0110: return 'JoinIndication';
                case 0x0111: return 'ChildMoved';
                case 0x0112: return 'NWKFCFailure';
                case 0x0113: return 'APSFCFailure';
                case 0x0114: return 'APSUnauthorizedKey';
                case 0x0115: return 'NWKDecryptFailures';
                case 0x0116: return 'APSDecryptFailures';
                case 0x0117: return 'PacketBufferAllocateFailures';
                case 0x0118: return 'RelayedUcast';
                case 0x0119: return 'PhytoMACqueuelimitreached';
                case 0x011a: return 'PacketValidatedropcount';
                case 0x011b: return 'AverageMACRetryPerAPSMessageSent';
                case 0x011c: return 'LastMessageLQI';
                case 0x011d: return 'LastMessageRSSI';
            }
            break;
    }
    return null;
};
export const attributeNameOrHex = (c, a) => {
    const s = attributeName(c, a);
    if (s == null)
        return '0x' + a.toString(16).padStart(4, '0');
    else
        return s;
};
export const attributeNameOrHexNoPrefix = (c, a) => {
    const s = attributeName(c, a);
    if (s == null)
        return a.toString(16).padStart(4, '0');
    else
        return s;
};
