import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useAppSelector, useAppDispatch } from '../hooks';
import { ConfigZigbeeNode } from '../components/ConfigZigbeeNode';
import { fetchZigbeeDeviceInfo } from '../actions/FetchZigbeeDeviceInfo';
export const ConfigZigbeeDevice = () => {
    const { id } = useParams();
    const node_id = Number(id);
    const zigbee_nodes = useAppSelector(state => state.definitions.zigbee_nodes);
    const zigbee_node = zigbee_nodes.get(node_id);
    const dispatch = useAppDispatch();
    var page;
    if (zigbee_node)
        page = (React.createElement(ConfigZigbeeNode, { node_id: zigbee_node.id }));
    else
        page = '';
    useEffect(() => {
        if (zigbee_node) {
            dispatch(fetchZigbeeDeviceInfo(zigbee_node.id));
        }
    }, [zigbee_nodes]);
    return (React.createElement("div", { className: "page-container" },
        React.createElement("h1", null, "Zigbee node"),
        page));
};
