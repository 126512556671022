import React from 'react';
import { useAppSelector, useAppDispatch } from '../hooks';
import { TopMenuItem } from '../components/TopMenuItem';
import { Dropdown } from '../components/Dropdown';
import { Link } from 'react-router-dom';
import { logout } from '../actions/Logout';
import * as LWIcon from '../components/LWIcon';
const Logout = (props) => {
    const dispatch = useAppDispatch();
    return (React.createElement(Link, { to: "/logout", onClick: e => {
            e.preventDefault();
            dispatch(logout());
        } }, props.children));
};
export function TopMenu(_props) {
    const panels = useAppSelector(state => state.definitions.panels);
    //let panel_id = 0;
    let panel_name = '-';
    for (const p of panels.entities.values()) {
        //panel_id = p.id;
        panel_name = p.title;
        break;
    }
    const panel_path = '/';
    let wrench = React.createElement(LWIcon.Wrench, null);
    return (React.createElement("nav", { role: "menubar", className: "lights" },
        React.createElement(TopMenuItem, { to: panel_path },
            React.createElement(Link, { to: panel_path }, panel_name)),
        React.createElement(TopMenuItem, { to: "/sensors" },
            React.createElement(Link, { to: "/sensors" }, "Sensorer")),
        React.createElement(TopMenuItem, { to: "/config", className: "end" },
            React.createElement(Dropdown, { button: wrench },
                React.createElement("div", { role: "menuitem" },
                    React.createElement(Link, { to: "/config/outlet" }, "Uttag")),
                React.createElement("div", { role: "menuitem" },
                    React.createElement(Link, { to: "/config/transmitters/" }, "S\u00E4ndare")),
                React.createElement("div", { role: "menuitem" },
                    React.createElement(Link, { to: "/config/panels/" }, "Paneler")),
                React.createElement("div", { role: "menuitem" },
                    React.createElement(Link, { to: "/config/macros" }, "Scener")),
                React.createElement("div", { role: "menuitem" },
                    React.createElement(Link, { to: "/config/schedule" }, "Schemal\u00E4ggning")),
                React.createElement("div", { role: "separator" }),
                React.createElement("div", { role: "menuitem" },
                    React.createElement(Logout, null, "Logga ut"))))));
}
