import { THROTTLE_QUEUE_ADD, THROTTLE_QUEUE_UPDATE, THROTTLE_SENT } from '../actions/Throttle';
export function throttle(state = new Map(), action) {
    switch (action.type) {
        case THROTTLE_QUEUE_ADD:
        case THROTTLE_QUEUE_UPDATE: {
            let m = new Map(state);
            m.set(action.endpoint, { timestamp: Date.now(),
                post: {
                    data: action.data,
                    success: action.success,
                    failure: action.failure,
                    intervalId: action.intervalId
                }
            });
            return m;
        }
        case THROTTLE_SENT: {
            let m = new Map(state);
            m.set(action.endpoint, { timestamp: Date.now(),
                post: null
            });
            return m;
        }
        default:
            return state;
    }
}
