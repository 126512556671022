import { parseInteger, parseArray, parseArrayLogFailed, parseObject } from '../util/parse';
import { parseAttribute } from '../types/Attribute';
import { parseEndpoint } from '../types/endpoint';
import { parseNodeDescriptor } from '../zigbee/node_descriptor';
const parseClusterAttributes = parseObject('ClusterAttributes', part => {
    return {
        attributes: part.req('attributes', parseArrayLogFailed(parseAttribute)),
        cluster_id: part.req('cluster_id', parseInteger),
    };
});
const parseEndpointClusterAttributes = parseObject('EndpointClusterAttributes', part => {
    return {
        clusters: part.req('clusters', parseArray(parseClusterAttributes)),
        endpoint: part.req('endpoint', parseInteger),
    };
});
export const parseZigbeeDeviceInfo = parseObject('ZigbeeDeviceInfo', part => {
    var _a, _b, _c, _d;
    const node_descriptor = part.opt('node_descriptor', parseNodeDescriptor);
    const endpointList = part.req('endpoints', parseArray(parseEndpoint));
    const attributeList = part.req('attributes', parseArray(parseEndpointClusterAttributes));
    var endpoints = new Map();
    for (const e of endpointList) {
        endpoints.set(e.endpoint, e);
    }
    var attributes = new Map();
    for (const a of attributeList) {
        if (!attributes.has(a.endpoint)) {
            attributes.set(a.endpoint, new Map());
        }
        for (const c of a.clusters) {
            if (!((_a = attributes.get(a.endpoint)) === null || _a === void 0 ? void 0 : _a.has(c.cluster_id))) {
                (_b = attributes.get(a.endpoint)) === null || _b === void 0 ? void 0 : _b.set(c.cluster_id, new Map());
            }
            for (const a2 of c.attributes) {
                (_d = (_c = attributes.get(a.endpoint)) === null || _c === void 0 ? void 0 : _c.get(c.cluster_id)) === null || _d === void 0 ? void 0 : _d.set(a2.attribute_id, a2);
            }
        }
    }
    return {
        node_descriptor,
        endpoints,
        attributes,
    };
});
