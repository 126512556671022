import { setupEditPanelDeviceDialog } from '../actions/SetupEditPanelDeviceDialog';
import { showDialog } from '../actions/ShowDialog';
export function editPanelDevice(id) {
    return (dispatch, getState) => {
        const { definitions } = getState();
        const t = definitions.panel_devices.get(id);
        if (t == undefined)
            return;
        const pg = definitions.panel_groups.get(t.panel_group_id);
        if (pg == undefined)
            return;
        dispatch(setupEditPanelDeviceDialog({
            panel_device_id: t.id,
            device_id: t.device_id,
            panel_group_id: t.panel_group_id,
            ord: t.ord.toString()
        }));
        dispatch(showDialog('edit-panel-transmitter'));
    };
}
