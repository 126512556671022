import Ajax from '../util/ajax';
import { deviceUpdated } from '../features/devices/devicesSlice';
import { parse } from '../util/parse';
import { parseDevice } from '../types/device';
export function saveZigbee(dialog_id, title) {
    return dispatch => {
        const ajax = new Ajax(dispatch);
        const url = '/zigbee/' + dialog_id;
        const obj = {
            title
        };
        ajax.post(url, obj, data => {
            const device = parse(parseDevice, data);
            dispatch(deviceUpdated(device));
        });
    };
}
