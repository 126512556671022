import React from 'react';
import { useAppSelector, useAppDispatch } from '../hooks';
import { Dialog } from '../components/DialogOld';
import { TransmitterSelector } from '../components/TransmitterSelector';
import { saveSchedule } from '../actions/SaveSchedule';
import { createSchedule } from '../actions/CreateSchedule';
import { deleteSchedule } from '../actions/DeleteSchedule';
import { FormGroup } from '../components/FormGroup';
import { FormRow } from '../components/FormRow';
import { hideDialog } from '../actions/HideDialog';
import { setEditScheduleDialog } from '../actions/SetEditScheduleDialog';
import { RadioButtons } from '../components/RadioButtons';
import { format_time } from '../util/formatTime';
const DeleteButton = props => (React.createElement("button", { key: "delete", type: "button", className: "delete lw danger", onClick: props.onClick }, "Ta bort"));
const CancelButton = props => (React.createElement("button", { key: "cancel", type: "button", className: "lw", onClick: props.onClick }, "Avbryt"));
const SaveButton = props => (React.createElement("button", { key: "save", type: "button", className: "save lw primary", onClick: props.onClick }, "Spara \u00E4ndringar"));
const CreateButton = props => (React.createElement("button", { key: "create", type: "button", className: "save lw primary", onClick: props.onClick }, "Skapa schema"));
const TimeEditor = (props) => {
    let editor;
    if (props.kind == 'cron') {
        editor = (React.createElement(CronInput, null));
    }
    else if (props.kind == 'sun') {
        editor = (React.createElement(SunInput, null));
    }
    else if (props.kind == 'time') {
        editor = (React.createElement(TimeInput, null));
    }
    else {
        editor = React.createElement("div", null);
    }
    return (React.createElement(FormRow, null,
        React.createElement(RadioButtons, { buttons: [{ kind: 'time', title: 'Tid' },
                { kind: 'sun', title: 'Sol' },
                { kind: 'cron', title: 'Cron' }], name: 'kind', kind: props.kind, updateKind: v => props.onChangeKind(v) }),
        editor));
};
const EditWeekdays = (props) => {
    return (React.createElement("div", { style: { display: 'grid', gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr 1fr 1fr 3fr' } },
        React.createElement("label", null,
            React.createElement("input", { type: "checkbox", name: "monday", checked: props.w.monday, onChange: event => props.update(Object.assign(Object.assign({}, props.w), { monday: event.target.checked })) }),
            "\u00A0M\u00E5n"),
        React.createElement("label", null,
            React.createElement("input", { type: "checkbox", name: "tuesday", checked: props.w.tuesday, onChange: event => props.update(Object.assign(Object.assign({}, props.w), { tuesday: event.target.checked })) }),
            "\u00A0Tis"),
        React.createElement("label", null,
            React.createElement("input", { type: "checkbox", name: "wednesday", checked: props.w.wednesday, onChange: event => props.update(Object.assign(Object.assign({}, props.w), { wednesday: event.target.checked })) }),
            "\u00A0Ons"),
        React.createElement("label", null,
            React.createElement("input", { type: "checkbox", name: "thursday", checked: props.w.thursday, onChange: event => props.update(Object.assign(Object.assign({}, props.w), { thursday: event.target.checked })) }),
            "\u00A0Tor"),
        React.createElement("label", null,
            React.createElement("input", { type: "checkbox", name: "friday", checked: props.w.friday, onChange: event => props.update(Object.assign(Object.assign({}, props.w), { friday: event.target.checked })) }),
            "\u00A0Fre"),
        React.createElement("label", null,
            React.createElement("input", { type: "checkbox", name: "saturday", checked: props.w.saturday, onChange: event => props.update(Object.assign(Object.assign({}, props.w), { saturday: event.target.checked })) }),
            "\u00A0L\u00F6r"),
        React.createElement("label", null,
            React.createElement("input", { type: "checkbox", name: "sunday", checked: props.w.sunday, onChange: event => props.update(Object.assign(Object.assign({}, props.w), { sunday: event.target.checked })) }),
            "\u00A0S\u00F6n")));
};
const TimeInput = () => {
    const dispatch = useAppDispatch();
    const dialog = useAppSelector(state => state.edit_schedule_dialog);
    function updateTime(v) {
        const time_parts = v.match(/(\d+):(\d+)/);
        if (time_parts) {
            const h = time_parts[1];
            const m = time_parts[2];
            dispatch(setEditScheduleDialog(Object.assign(Object.assign({}, dialog), { time: { h: Number(h), m: Number(m), w: dialog.time.w } })));
        }
    }
    function updateWeekdays(w) {
        dispatch(setEditScheduleDialog(Object.assign(Object.assign({}, dialog), { time: Object.assign(Object.assign({}, dialog.time), { w }) })));
    }
    const timeStr = format_time(dialog.time.h, dialog.time.m);
    return (React.createElement(FormRow, null,
        React.createElement("input", { type: "time", name: "time.value", value: timeStr, onChange: event => updateTime(event.target.value) }),
        React.createElement(EditWeekdays, { w: dialog.time.w, update: updateWeekdays })));
};
const SunInput = () => {
    const dispatch = useAppDispatch();
    const dialog = useAppSelector(state => state.edit_schedule_dialog);
    function updateKind(str) {
        dispatch(setEditScheduleDialog(Object.assign(Object.assign({}, dialog), { sun: Object.assign(Object.assign({}, dialog.sun), { k: str }) })));
    }
    function updateAdjust(num) {
        dispatch(setEditScheduleDialog(Object.assign(Object.assign({}, dialog), { sun: Object.assign(Object.assign({}, dialog.sun), { a: num }) })));
    }
    function updateWeekdays(w) {
        dispatch(setEditScheduleDialog(Object.assign(Object.assign({}, dialog), { sun: Object.assign(Object.assign({}, dialog.sun), { w }) })));
    }
    function updateRandom(str) {
        dispatch(setEditScheduleDialog(Object.assign(Object.assign({}, dialog), { sun: Object.assign(Object.assign({}, dialog.sun), { r: str }) })));
    }
    return (React.createElement(FormRow, null,
        React.createElement("div", { style: { display: 'grid', gridTemplateColumns: '1fr 1fr 3fr', gridGap: '10px' } },
            React.createElement("select", { name: "sun.kind", value: dialog.sun.k, onChange: event => updateKind(event.target.value) },
                React.createElement("option", { value: "t" }, "Gryning"),
                React.createElement("option", { value: "u" }, "Dagsljus"),
                React.createElement("option", { value: "e" }, "Skymning"),
                React.createElement("option", { value: "n" }, "M\u00F6rker")),
            React.createElement("select", { name: "sun.ajust", value: dialog.sun.a, onChange: event => updateAdjust(Number(event.target.value)) },
                React.createElement("option", { value: "-3" }, "Ljusare -3"),
                React.createElement("option", { value: "-2" }, "Ljusare -2"),
                React.createElement("option", { value: "-1" }, "Ljusare -1"),
                React.createElement("option", { value: "0" }, "Neutral"),
                React.createElement("option", { value: "1" }, "M\u00F6rkare +1"),
                React.createElement("option", { value: "2" }, "M\u00F6rkare +2"),
                React.createElement("option", { value: "3" }, "M\u00F6rkare +3"))),
        React.createElement("div", { style: { marginTop: '10px' } },
            React.createElement(EditWeekdays, { w: dialog.sun.w, update: updateWeekdays })),
        React.createElement("div", { style: { display: 'grid', gridTemplateColumns: '1fr 2fr', marginTop: '10px' } },
            React.createElement("select", { name: "sun.random", value: dialog.sun.r, onChange: event => updateRandom(event.target.value) },
                React.createElement("option", { value: "r0" }, "Ingen slump"),
                React.createElement("option", { value: "r5" }, "Plus/minus 5 minuter"),
                React.createElement("option", { value: "r15" }, "Plus/minus 15 minuter")))));
};
const CronInput = () => {
    const dispatch = useAppDispatch();
    const dialog = useAppSelector(state => state.edit_schedule_dialog);
    function updateCron(str) {
        dispatch(setEditScheduleDialog(Object.assign(Object.assign({}, dialog), { cron: str })));
    }
    return (React.createElement(FormRow, null,
        React.createElement("input", { type: "text", name: "time.cron", value: dialog.cron, onChange: updateCron }),
        "\u00A0 m h dom mon dow"));
};
const NexaOnOff = props => (React.createElement("div", { style: { display: 'grid', gridTemplateColumns: '1fr 1fr 8fr', marginTop: '10px' } },
    React.createElement(RadioButtons, { buttons: [{ kind: 'on', title: 'På' }, { kind: 'off', title: 'Av' }], name: 'operation', kind: props.kind, updateKind: v => props.setOperation(v) })));
const NexaDimmer = props => (React.createElement("div", { style: { display: 'grid', gridTemplateColumns: '1fr 1fr 1fr 1fr 4fr', gridGap: '10px', marginTop: '10px' } },
    React.createElement(RadioButtons, { buttons: [{ kind: 'on', title: 'På' },
            { kind: 'off', title: 'Av' },
            { kind: 'dim', title: 'Dimmer' },
        ], name: 'operation', kind: props.kind, updateKind: v => props.setOperation(v) }),
    React.createElement("select", { name: "dim_level", value: props.dim_level, onChange: event => props.setDimLevel(Number(event.target.value)) },
        React.createElement("option", { value: "1" }, "1"),
        React.createElement("option", { value: "2" }, "2"),
        React.createElement("option", { value: "3" }, "3"),
        React.createElement("option", { value: "4" }, "4"),
        React.createElement("option", { value: "5" }, "5"),
        React.createElement("option", { value: "6" }, "6"),
        React.createElement("option", { value: "7" }, "7"),
        React.createElement("option", { value: "8" }, "8"),
        React.createElement("option", { value: "9" }, "9"),
        React.createElement("option", { value: "10" }, "10"),
        React.createElement("option", { value: "11" }, "11"),
        React.createElement("option", { value: "12" }, "12"),
        React.createElement("option", { value: "13" }, "13"),
        React.createElement("option", { value: "14" }, "14"),
        React.createElement("option", { value: "15" }, "15"),
        React.createElement("option", { value: "16" }, "16"))));
const ZigbeeOnOff = props => (React.createElement("div", { style: { display: 'grid', gridTemplateColumns: '1fr 1fr 8fr', marginTop: '10px' } },
    React.createElement(RadioButtons, { buttons: [{ kind: 'zigbee on', title: 'På' },
            { kind: 'zigbee off', title: 'Av' },
        ], name: 'operation', kind: props.kind, updateKind: v => props.setOperation(v) })));
export const DialogScheduleEdit = (props) => {
    const dispatch = useAppDispatch();
    const dialog = useAppSelector(state => state.edit_schedule_dialog);
    const definitions = useAppSelector(state => state.definitions);
    const device_kind = get_device_kind(definitions, dialog.transmitter_id);
    let footer, transmitter, transmitter_operation;
    if (dialog.id) {
        footer = [
            (React.createElement(DeleteButton, { key: "delete", onClick: () => dispatch(deleteSchedule(dialog.id)) })),
            (React.createElement(CancelButton, { key: "cancel", onClick: () => dispatch(hideDialog()) })),
            (React.createElement(SaveButton, { key: "save", onClick: () => dispatch(saveSchedule(dialog)) }))
        ];
    }
    else {
        footer = [
            (React.createElement(CancelButton, { key: "cancel", onClick: () => dispatch(hideDialog()) })),
            (React.createElement(CreateButton, { key: "create", onClick: () => dispatch(createSchedule(dialog)) }))
        ];
    }
    if (props.device_id != undefined) {
        transmitter = (React.createElement("input", { name: "transmitter_id", value: props.device_id, hidden: true, readOnly: true }));
    }
    else {
        transmitter =
            (React.createElement(TransmitterSelector, { name: "transmitter_id", value: dialog.transmitter_id, onChange: event => dispatch(setEditScheduleDialog(Object.assign(Object.assign({}, dialog), { transmitter_id: Number(event.target.value) }))) }));
    }
    switch (device_kind) {
        case 'nexa onoff':
            transmitter_operation = React.createElement(NexaOnOff, { kind: dialog.operation, setOperation: v => dispatch(setEditScheduleDialog(Object.assign(Object.assign({}, dialog), { operation: v }))) });
            break;
        case 'nexa dimmer':
            transmitter_operation = React.createElement(NexaDimmer, { kind: dialog.operation, dim_level: dialog.dim_level, setOperation: v => dispatch(setEditScheduleDialog(Object.assign(Object.assign({}, dialog), { operation: v }))), setDimLevel: v => dispatch(setEditScheduleDialog(Object.assign(Object.assign({}, dialog), { dim_level: v }))) });
            break;
        case 'zigbee onoff':
            transmitter_operation = React.createElement(ZigbeeOnOff, { kind: dialog.operation, setOperation: v => dispatch(setEditScheduleDialog(Object.assign(Object.assign({}, dialog), { operation: v }))) });
            break;
        default:
            transmitter_operation = React.createElement("div", null, "No operation for this unit");
            break;
    }
    function onChangeActive(v) {
        dispatch(setEditScheduleDialog(Object.assign(Object.assign({}, dialog), { active: v })));
    }
    function onChangeKind(v) {
        dispatch(setEditScheduleDialog(Object.assign(Object.assign({}, dialog), { kind: v })));
    }
    return (React.createElement(Dialog, { id: "schedule-edit", title: "Redigera schema", footer: footer },
        React.createElement(TimeEditor, { dialog: dialog, kind: dialog.kind, time: dialog.time, onChangeKind: onChangeKind }),
        React.createElement(FormRow, null,
            React.createElement("label", null,
                React.createElement("input", { type: "checkbox", name: "active", checked: dialog.active, onChange: event => onChangeActive(event.target.checked) }),
                "\u00A0Aktiv")),
        React.createElement("hr", null),
        React.createElement(FormRow, null,
            React.createElement(FormGroup, { label: "S\u00E4ndare" },
                transmitter,
                transmitter_operation))));
};
function get_device_kind(definitions, transmitter_id) {
    if (!isNaN(transmitter_id)) {
        const d = definitions.devices.get(transmitter_id);
        if (d) {
            if (d.protocol.name == 'nexa transmitter') {
                if (d.protocol.kind == 'onoff') {
                    return 'nexa onoff';
                }
                else if (d.protocol.kind == 'dimmer') {
                    return 'nexa dimmer';
                }
            }
            else if (d.protocol.name == 'zigbee') {
                return 'zigbee onoff';
            }
        }
        else {
            console.log('Invalid device id ' + transmitter_id);
        }
    }
    return 'no device kind';
}
