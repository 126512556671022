import React from 'react';
import { useParams } from 'react-router-dom';
import { useAppSelector } from '../hooks';
import { createAppSelector } from '../util/store';
import { PanelDevice } from '../components/PanelDevice';
import { PanelGroup } from '../components/PanelGroup';
const selectPanel = createAppSelector([
    state => state.definitions,
    (_, id) => id,
], (def, input_id) => {
    let panel_id = null;
    if (input_id) {
        panel_id = Number(input_id);
    }
    else {
        for (const p of def.panels.entities.values()) {
            panel_id = p.id;
        }
    }
    var data = [];
    if (panel_id) {
        const panel_group_ids = def.panel_group_order.get(panel_id);
        if (panel_group_ids != undefined) {
            for (const panel_group_id of panel_group_ids) {
                const panel_group = def.panel_groups.get(panel_group_id);
                if (panel_group == undefined)
                    continue;
                let g = {
                    name: panel_group.title,
                    panel_devices: []
                };
                if (!(def.panel_device_order.has(panel_group_id))) {
                    continue;
                }
                const panel_device_ids = def.panel_device_order.get(panel_group_id);
                if (panel_device_ids != undefined) {
                    for (const panel_device_id of panel_device_ids) {
                        const t = def.panel_devices.get(panel_device_id);
                        if (t === undefined) {
                            console.log('Missing panel device with id ' + panel_device_id);
                            continue;
                        }
                        g.panel_devices.push({
                            id: t.id,
                            device_id: t.device_id
                            // dim: outlet.kind == 'dim'
                        });
                    }
                }
                data.push(g);
            }
        }
    }
    return data;
});
export const Panel = () => {
    const { id } = useParams();
    const data = useAppSelector(state => selectPanel(state, id));
    var rows = [];
    var group_num = 0;
    for (const group of data) {
        rows.push(React.createElement(PanelGroup, { key: group_num++, name: group.name }));
        var outlet_num = 0;
        for (const pt of group.panel_devices) {
            const key = group_num.toString() + '_' + (outlet_num++).toString();
            rows.push(React.createElement(PanelDevice, Object.assign({ key: key }, pt)));
        }
    }
    return (React.createElement("div", { className: "page-container panel" }, rows));
};
