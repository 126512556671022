import { parseInteger, parseBoolean, parseString, parseHex16Bits, parseObject } from '../util/parse';
export const parseNodeDescriptor = parseObject('NodeDescriptor', part => {
    return {
        id: part.req('id', parseInteger),
        allocate_addr: part.req('allocate_addr', parseBoolean),
        backup_binding_table_cache: part.req('backup_binding_table_cache', parseBoolean),
        backup_discovery_cache: part.req('backup_discovery_cache', parseBoolean),
        backup_trust_center: part.req('backup_trust_center', parseBoolean),
        can_be_coordinator: part.req('can_be_coordinator', parseBoolean),
        complex_descriptor: part.req('complex_descriptor', parseBoolean),
        created: part.req('created', parseString),
        device_type_full: part.req('device_type_full', parseBoolean),
        extended_active_endpoints_available: part.req('extended_active_endpoints_available', parseBoolean),
        extended_simple_descriptor_available: part.req('extended_simple_descriptor_available', parseBoolean),
        frequency_band_2400: part.req('frequency_band_2400', parseBoolean),
        frequency_band_868: part.req('frequency_band_868', parseBoolean),
        frequency_band_902: part.req('frequency_band_902', parseBoolean),
        logical_type: part.req('logical_type', parseString),
        manufacturer: part.req('manufacturer', parseHex16Bits),
        manufacturer_name: part.opt('manufacturer_name', parseString),
        maximum_buffer_size: part.req('maximum_buffer_size', parseInteger),
        maximum_incoming_transfer_size: part.req('maximum_incoming_transfer_size', parseInteger),
        maximum_outgoing_transfer_size: part.req('maximum_outgoing_transfer_size', parseInteger),
        network_manager: part.req('network_manager', parseBoolean),
        power_source_mains: part.req('power_source_mains', parseBoolean),
        primary_binding_table_cache: part.req('primary_binding_table_cache', parseBoolean),
        primary_discovery_cache: part.req('primary_discovery_cache', parseBoolean),
        primary_trust_center: part.req('primary_trust_center', parseBoolean),
        reciever_on_when_idle: part.req('reciever_on_when_idle', parseBoolean),
        security_capable: part.req('security_capable', parseBoolean),
        stack_compliance_revision: part.req('stack_compliance_revision', parseInteger),
        user_descriptor: part.req('user_descriptor', parseBoolean),
    };
});
