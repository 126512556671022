import React from 'react';
import { useParams } from 'react-router-dom';
import { useAppSelector } from '../hooks';
import { Device } from '../components/Device';
export const PageDevice = () => {
    const { id } = useParams();
    const num_devices = useAppSelector(state => state.definitions.devices.size);
    const device_id = Number(id);
    const hasDefinitions = num_devices > 0;
    if (isNaN(device_id)) {
        return null;
    }
    const content = hasDefinitions && !isNaN(device_id) ? React.createElement(Device, { id: device_id }) : '... loading data ...';
    return (React.createElement("div", { className: "page-container" },
        React.createElement("div", { className: "device" }, content)));
};
