import Ajax from '../util/ajax';
//import { fetchDefinitions } from '../actions/FetchDefinitions';
export const FETCH_ZIGBEE_NODE = 'FETCH_ZIGBEE_NODE';
export function fetchZigbeeNode(nwk) {
    return dispatch => {
        const a = new Ajax(dispatch);
        a.post('/zigbee/nodeinfo', {
            nwk
        }, data => {
            dispatch({ type: FETCH_ZIGBEE_NODE, data });
            //dispatch(fetchDefinitions());
        });
    };
}
