import Ajax from '../util/ajax';
import { panelGroupAdded } from '../features/panel_groups/panelGroupsSlice';
import { hideDialog } from '../actions/HideDialog';
import { parse } from '../util/parse';
import { parsePanelGroup } from '../types/PanelGroup';
export function createPanelGroup() {
    return (dispatch, getState) => {
        const { edit_panel_group_dialog } = getState();
        const ajax = new Ajax(dispatch);
        const url = '/panel/' + edit_panel_group_dialog.panel_id + '/group/create';
        ajax.post(url, {
            title: edit_panel_group_dialog.title,
            ord: edit_panel_group_dialog.ord
        }, data => {
            const panelgroup = parse(parsePanelGroup, data);
            dispatch(panelGroupAdded({
                id: panelgroup.id,
                panel_id: edit_panel_group_dialog.panel_id,
                title: edit_panel_group_dialog.title,
                ord: edit_panel_group_dialog.ord
            }));
            dispatch(hideDialog());
        });
    };
}
