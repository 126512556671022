import Ajax from '../util/ajax';
import { outletDeleted } from '../features/outlets/outletsSlice';
import { hideDialog } from '../actions/HideDialog';
export function deleteOutlet(id) {
    return dispatch => {
        const ajax = new Ajax(dispatch);
        const url = '/outlet/' + id;
        ajax.del(url, undefined, () => {
            dispatch(outletDeleted(id));
            dispatch(hideDialog());
        });
    };
}
