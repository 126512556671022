import { setupEditMacroDialog } from '../actions/SetupEditMacroDialog';
import { showDialog } from '../actions/ShowDialog';
export function newMacro() {
    return dispatch => {
        dispatch(setupEditMacroDialog({
            title: '',
            active: false,
            items: []
        }));
        dispatch(showDialog('macro-edit'));
    };
}
