import Ajax from '../util/ajax';
import { updateNodeNeighbors } from '../actions/UpdateNodeNeighbors';
import { parse } from '../util/parse';
import { parseNeighbors } from '../types/Zigbee/Neighbors';
export function fetchZigbeeNodeNeighbors(zigbee_node_id) {
    return dispatch => {
        const ajax = new Ajax(dispatch);
        const url = '/zigbee/' + zigbee_node_id + '/lqi';
        ajax.post(url, {}, data => {
            const neighbors = parse(parseNeighbors, data);
            if (neighbors != undefined) {
                dispatch(updateNodeNeighbors(zigbee_node_id, neighbors));
            }
        });
    };
}
