import { setupEditPanelGroupDialog } from '../actions/SetupEditPanelGroupDialog';
import { showDialog } from '../actions/ShowDialog';
export function newPanelGroup(panel_id) {
    return dispatch => {
        dispatch(setupEditPanelGroupDialog({
            id: null,
            panel_id,
            title: '',
            ord: 0
        }));
        dispatch(showDialog('group-edit'));
    };
}
