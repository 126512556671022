export function createEntityAdapter({ selectId, sortComparer }) {
    return {
        getInitialState: () => {
            return {
                entities: new Map(),
                order: []
            };
        },
        removeAll: state => {
            state.entities.clear();
            state.order = [];
        },
        addOne: (state, action) => {
            const id = selectId(action.payload);
            const isUpdate = state.entities.has(id);
            state.entities.set(id, action.payload);
            if (!isUpdate) {
                state.order.push(id);
            }
            state.order.sort((ka, kb) => {
                const a = state.entities.get(ka);
                const b = state.entities.get(kb);
                return a && b ? sortComparer(a, b) : 0;
            });
        },
        removeOne: (state, action) => {
            state.entities.delete(action.payload);
            state.order.splice(state.order.indexOf(action.payload), 1);
        }
    };
}
