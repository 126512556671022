import React from 'react';
import { useAppSelector, useAppDispatch } from '../hooks';
import { newMacro } from '../actions/NewMacro';
import { DialogMacroEdit } from '../components/DialogMacroEdit';
import { editMacro } from '../actions/EditMacro';
export const ConfigMacros = () => {
    const defMacros = useAppSelector(state => state.definitions.macros.entities);
    const macros_order = useAppSelector(state => state.definitions.macros.order);
    const dispatch = useAppDispatch();
    var macros = [];
    for (let macros_id of macros_order) {
        const macro = defMacros.get(macros_id);
        if (macro) {
            macros.push(macro);
        }
    }
    let rows = [];
    for (const m of macros) {
        const label_classes = 'inactive label ' + (m.active ? ' hidden' : '');
        rows.push(React.createElement("div", { key: m.id },
            React.createElement("div", null,
                m.title,
                React.createElement("span", { className: label_classes }, "Inaktiv")),
            React.createElement("div", null,
                React.createElement("button", { className: "lw small", onClick: () => dispatch(editMacro(m.id)) }, "Redigera"))));
    }
    return (React.createElement("div", { className: "page-container" },
        React.createElement(DialogMacroEdit, null),
        React.createElement("div", { className: "operations" },
            React.createElement("button", { className: "lw success", onClick: () => dispatch(newMacro()) }, "L\u00E4gg till scen...")),
        React.createElement("div", { className: "config-rows config-macros" }, rows)));
};
