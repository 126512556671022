export function get_any_transmitter_id(definitions) {
    for (let o of definitions.devices.values()) {
        if (o.transmitter_kind) {
            return o.id;
        }
    }
    return null;
}
export function get_transmitters(definitions) {
    let ts = new Map();
    for (let o of definitions.devices.values()) {
        if (o.transmitter_kind) {
            ts.set(o.id, o);
        }
    }
    return ts;
}
