import { parseBoolean, parseInteger, parseString, parseObject, parseArray } from '../util/parse';
import { parseAttributeType } from '../types/AttributeType';
export const parseAttribute = parseObject('Attribute', part => {
    const attribute_id = part.req('attribute_id', parseInteger);
    const changed = part.opt('changed', parseString);
    const type = part.req('type', parseAttributeType);
    part.ctx = part.ctx.addClass(type.toString());
    switch (type) {
        case 'NoData':
            return { attribute_id, changed, type, value: undefined };
        case 'Data8':
            return { attribute_id, changed, type, value: part.opt('value', parseInteger) };
        case 'Data16':
            return { attribute_id, changed, type, value: part.opt('value', parseInteger) };
        case 'Data24':
            return { attribute_id, changed, type, value: part.opt('value', parseInteger) };
        case 'Data32':
            return { attribute_id, changed, type, value: part.opt('value', parseInteger) };
        case 'Data40':
            return { attribute_id, changed, type, value: part.opt('value', parseInteger) };
        case 'Data48':
            return { attribute_id, changed, type, value: part.opt('value', parseInteger) };
        case 'Data56':
            return { attribute_id, changed, type, value: part.opt('value', parseInteger) };
        case 'Data64':
            return { attribute_id, changed, type, value: part.opt('value', parseInteger) };
        case 'Bool':
            return { attribute_id, changed, type, value: part.opt('value', parseBoolean) };
        case 'Bitmap8':
            return { attribute_id, changed, type, value: part.opt('value', parseInteger) };
        case 'Bitmap16':
            return { attribute_id, changed, type, value: part.opt('value', parseInteger) };
        case 'Bitmap24':
            return { attribute_id, changed, type, value: part.opt('value', parseInteger) };
        case 'Bitmap32':
            return { attribute_id, changed, type, value: part.opt('value', parseInteger) };
        case 'Bitmap40':
            return { attribute_id, changed, type, value: part.opt('value', parseInteger) };
        case 'Bitmap48':
            return { attribute_id, changed, type, value: part.opt('value', parseInteger) };
        case 'Bitmap56':
            return { attribute_id, changed, type, value: part.opt('value', parseInteger) };
        case 'Bitmap64':
            return { attribute_id, changed, type, value: part.opt('value', parseInteger) };
        case 'UInt8':
            return { attribute_id, changed, type, value: part.opt('value', parseInteger) };
        case 'UInt16':
            return { attribute_id, changed, type, value: part.opt('value', parseInteger) };
        case 'UInt24':
            return { attribute_id, changed, type, value: part.opt('value', parseInteger) };
        case 'UInt32':
            return { attribute_id, changed, type, value: part.opt('value', parseInteger) };
        case 'UInt40':
            return { attribute_id, changed, type, value: part.opt('value', parseInteger) };
        case 'UInt48':
            return { attribute_id, changed, type, value: part.opt('value', parseInteger) };
        case 'UInt56':
            return { attribute_id, changed, type, value: part.opt('value', parseInteger) };
        case 'UInt64':
            return { attribute_id, changed, type, value: part.opt('value', parseInteger) };
        case 'Int8':
            return { attribute_id, changed, type, value: part.opt('value', parseInteger) };
        case 'Int16':
            return { attribute_id, changed, type, value: part.opt('value', parseInteger) };
        case 'Int24':
            return { attribute_id, changed, type, value: part.opt('value', parseInteger) };
        case 'Int32':
            return { attribute_id, changed, type, value: part.opt('value', parseInteger) };
        case 'Int40':
            return { attribute_id, changed, type, value: part.opt('value', parseInteger) };
        case 'Int48':
            return { attribute_id, changed, type, value: part.opt('value', parseInteger) };
        case 'Int56':
            return { attribute_id, changed, type, value: part.opt('value', parseInteger) };
        case 'Int64':
            return { attribute_id, changed, type, value: part.opt('value', parseInteger) };
        case 'Enum8':
            return { attribute_id, changed, type, value: part.opt('value', parseInteger) };
        case 'Enum16':
            return { attribute_id, changed, type, value: part.opt('value', parseInteger) };
        case 'FloatSemi':
            return { attribute_id, changed, type, value: part.opt('value', parseString) };
        case 'FloatSingle':
            return { attribute_id, changed, type, value: part.opt('value', parseString) };
        case 'FloatDouble':
            return { attribute_id, changed, type, value: part.opt('value', parseString) };
        case 'OctetStr':
            return { attribute_id, changed, type, value: part.opt('value', parseArray(parseInteger)) };
        case 'CharStr':
            return { attribute_id, changed, type, value: part.opt('value', parseString) };
        case 'LongOctetStr':
            return { attribute_id, changed, type, value: part.opt('value', parseArray(parseInteger)) };
        case 'LongCharStr':
            return { attribute_id, changed, type, value: part.opt('value', parseString) };
        case 'Array':
            return { attribute_id, changed, type, value: undefined };
        case 'Struct':
            return { attribute_id, changed, type, value: undefined };
        case 'Set':
            return { attribute_id, changed, type, value: undefined };
        case 'Bag':
            return { attribute_id, changed, type, value: undefined };
        case 'TimeOfDay':
            return { attribute_id, changed, type, value: part.opt('value', parseString) };
        case 'Date':
            return { attribute_id, changed, type, value: part.opt('value', parseString) };
        case 'UTCTime':
            return { attribute_id, changed, type, value: part.opt('value', parseInteger) };
        case 'ClusterID':
            return { attribute_id, changed, type, value: part.opt('value', parseInteger) };
        case 'AttributeID':
            return { attribute_id, changed, type, value: part.opt('value', parseInteger) };
        case 'BACnetOID':
            return { attribute_id, changed, type, value: part.opt('value', parseString) };
        case 'IEEEAddr':
            return { attribute_id, changed, type, value: part.opt('value', parseString) };
        case 'Security128':
            return { attribute_id, changed, type, value: part.opt('value', parseArray(parseInteger)) };
        case 'Unknown':
            return { attribute_id, changed, type, value: undefined };
    }
});
