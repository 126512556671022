import React from 'react';
import { useAppSelector, useAppDispatch } from '../hooks';
import { appOpenDropdown } from '../actions/App';
export function Dropdown(props) {
    const dispatch = useAppDispatch();
    const removeDropdownListener = useAppSelector(state => state.app.removeDropdownListener);
    const open = removeDropdownListener != null;
    const menuRef = React.useRef(null);
    function click(e) {
        if (!open) {
            e.stopPropagation();
            dispatch(appOpenDropdown(menuRef.current));
        }
    }
    return (React.createElement(React.Fragment, null,
        React.createElement("a", { onClick: click, style: { whiteSpace: 'nowrap' } },
            props.button,
            " ",
            React.createElement("span", { className: "caret" })),
        React.createElement("div", { ref: menuRef, role: "menu", style: { display: open ? 'block' : 'none' } }, props.children)));
}
