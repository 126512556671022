import Ajax from '../util/ajax';
import { transmitterAdded } from '../features/transmitters/transmittersSlice';
import { deviceAdded } from '../features/devices/devicesSlice';
import { hideDialog } from '../actions/HideDialog';
import { parse } from '../util/parse';
import { parseTransmitter } from '../types/transmitter';
import { toNexaTransmitterKind } from '../types/device';
export function createTransmitter() {
    return (dispatch, getState) => {
        const { edit_transmitter_dialog } = getState();
        const ajax = new Ajax(dispatch);
        const url = '/transmitter/create';
        let obj = Object.assign({}, edit_transmitter_dialog);
        ajax.post(url, obj, data => {
            const transmitter = parse(parseTransmitter, data);
            dispatch(transmitterAdded(transmitter));
            switch (transmitter.kind) {
                case 'onoff':
                case 'dim':
                    {
                        const device = {
                            id: transmitter.id,
                            title: transmitter.title,
                            comment: transmitter.comment,
                            protocol: {
                                name: 'nexa transmitter',
                                kind: toNexaTransmitterKind(transmitter.kind),
                                code: transmitter.code,
                                unit: transmitter.unit,
                            }
                        };
                        dispatch(deviceAdded(device));
                    }
                    break;
                case 'rollershade':
                    {
                        const device = {
                            id: transmitter.id,
                            title: transmitter.title,
                            comment: transmitter.comment,
                            protocol: {
                                name: 'hasta transmitter',
                                code: transmitter.code,
                                unit: transmitter.unit,
                            }
                        };
                        dispatch(deviceAdded(device));
                    }
                    break;
            }
            dispatch(hideDialog());
        });
    };
}
