import { createSlice } from '@reduxjs/toolkit';
export const panelGroupOrderSlice = createSlice({
    name: 'panel_group_order',
    initialState: new Map(),
    reducers: {
        panelGroupOrder: (state, action) => {
            state.set(action.payload.id, action.payload.order);
        }
    },
});
// Thunk that recompute the order of the groups in a panel. Typically dispatched after one has updated an ord value in a PanelGroup
export function panelGroupOrderChanged(panelId) {
    return (dispatch, getState) => {
        const { definitions } = getState();
        const { panel_groups } = definitions;
        let pgs = [];
        for (var pg of panel_groups.values()) {
            if (pg.panel_id == panelId) {
                pgs.push(pg);
            }
        }
        pgs.sort((a, b) => {
            const diff = a.ord - b.ord;
            if (diff == 0) {
                return a.title.localeCompare(b.title);
            }
            else {
                return diff;
            }
        });
        let order = [];
        for (const pg of pgs) {
            order.push(pg.id);
        }
        // FIXME, we should only dispatch if the order really has changed...
        dispatch(panelGroupOrderSlice.actions.panelGroupOrder({ id: panelId, order }));
    };
}
export const { panelGroupOrder } = panelGroupOrderSlice.actions;
export default panelGroupOrderSlice.reducer;
