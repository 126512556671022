import React from 'react';
import { useAppSelector, useAppDispatch } from '../hooks';
import { runMacro } from '../actions/RunMacro';
export const MacroButton = (props) => {
    const dispatch = useAppDispatch();
    const macros = useAppSelector(state => state.definitions.macros.entities);
    const macro = macros.get(props.id);
    if (macro == undefined) {
        return null;
    }
    return (React.createElement("button", { key: props.id, className: "lw-button macro", onClick: () => dispatch(runMacro(macro.id)) }, macro.title));
};
