import { APP_AJAX_SPINNER, APP_DROPDOWN_OPEN, APP_DROPDOWN_CLOSE } from '../actions/App';
import { JUMP_TO } from '../actions/JumpTo';
import { SHOW_DIALOG } from '../actions/ShowDialog';
import { HIDE_DIALOG } from '../actions/HideDialog';
const initialState = {
    jumpTo: null,
    ajax_spinner: false,
    visible_dialog: null,
    dropdown: false,
    removeDropdownListener: null
};
export function app(state = initialState, action) {
    switch (action.type) {
        case APP_AJAX_SPINNER:
            return Object.assign(Object.assign({}, state), { ajax_spinner: action.active });
        case SHOW_DIALOG:
            return Object.assign(Object.assign({}, state), { visible_dialog: action.idstring });
        case HIDE_DIALOG:
            return Object.assign(Object.assign({}, state), { visible_dialog: null });
        case APP_DROPDOWN_OPEN:
            return Object.assign(Object.assign({}, state), { removeDropdownListener: action.removeDropdownListener });
        case APP_DROPDOWN_CLOSE:
            return Object.assign(Object.assign({}, state), { removeDropdownListener: null });
        case JUMP_TO:
            return Object.assign(Object.assign({}, state), { jumpTo: action.loc });
        default:
            return state;
    }
}
