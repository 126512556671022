import React from 'react';
import { useAppSelector, useAppDispatch } from '../hooks';
import { newSchedule } from '../actions/NewSchedule';
import { ConfigScheduleRow } from '../components/ConfigScheduleRow';
import { DialogScheduleEdit } from '../components/DialogScheduleEdit';
export const ConfigSchedule = () => {
    const defSchedules = useAppSelector(state => state.definitions.schedules.entities);
    const schedule_order = useAppSelector(state => state.definitions.schedules.order);
    const dispatch = useAppDispatch();
    var schedules = [];
    for (let schedule_id of schedule_order) {
        const schedule = defSchedules.get(schedule_id);
        if (schedule) {
            schedules.push({
                id: schedule.id
            });
        }
    }
    let rows = [];
    for (const s of schedules) {
        rows.push(React.createElement(ConfigScheduleRow, { key: s.id, id: s.id }));
    }
    return (React.createElement("div", { className: "page-container" },
        React.createElement(DialogScheduleEdit, null),
        React.createElement("div", { className: "operations" },
            React.createElement("button", { className: "addschedule lw success", onClick: () => dispatch(newSchedule()) }, "L\u00E4gg till ny schemal\u00E4ggning")),
        React.createElement("div", { className: "config-rows config-schedules" },
            React.createElement("div", { className: "config-schedule-row header" },
                React.createElement("div", { style: { whiteSpace: 'nowrap' } }, "Tidpunkt"),
                React.createElement("div", null, "N\u00E4sta"),
                React.createElement("div", { style: { whiteSpace: 'nowrap' } }, "Operation"),
                React.createElement("div", null, "Kontakt"),
                React.createElement("div", null)),
            rows)));
};
