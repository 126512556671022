import { createSlice } from '@reduxjs/toolkit';
import { createEntityAdapter } from '../../util/OrderedEntities';
const adapter = createEntityAdapter({
    selectId: (x) => x.id,
    sortComparer: (a, b) => a.title.localeCompare(b.title),
});
export const macrosSlice = createSlice({
    name: 'macros',
    initialState: adapter.getInitialState(),
    reducers: {
        macrosCleared: adapter.removeAll,
        macroUpdated: adapter.addOne,
        macroDeleted: adapter.removeOne,
    }
});
export const { macrosCleared, macroUpdated, macroDeleted } = macrosSlice.actions;
export const macroAdded = macroUpdated;
export default macrosSlice.reducer;
