import { parseObject, parseString, parseInteger, ParseError } from '../../util/parse';
export const parseNeighborDeviceType = (ctx, v) => {
    const s = parseString(ctx, v);
    switch (s) {
        case 'coordinator':
        case 'router':
        case 'end device':
        case 'reserved':
            return s;
        default: throw new ParseError(ctx, 'not a valid NeighborDeviceType: ' + s);
    }
};
export const parseNeighborPermitJoining = (ctx, v) => {
    const s = parseString(ctx, v);
    switch (s) {
        case 'yes':
        case 'no':
        case 'unknown1':
        case 'unknown2':
            return s;
        default: throw new ParseError(ctx, 'not a valid NeighborPermitJoining: ' + s);
    }
};
export const parseNeighborRxWhenIdle = (ctx, v) => {
    const s = parseString(ctx, v);
    switch (s) {
        case 'on':
        case 'off':
        case 'unknown1':
        case 'unknown2':
            return s;
        default: throw new ParseError(ctx, 'not a valid NeighborRxWhenIdle: ' + s);
    }
};
export const parseNeighborRelationship = (ctx, v) => {
    const s = parseString(ctx, v);
    switch (s) {
        case 'parent':
        case 'child':
        case 'sibling':
        case 'none':
        case 'previous child':
        case 'unknown1':
        case 'unknown2':
        case 'unknown3':
            return s;
        default: throw new ParseError(ctx, 'not a valid NeighborRelationship: ' + s);
    }
};
export const parseNeighbor = parseObject('Neighbor', part => {
    const depth = part.req('depth', parseInteger);
    const deviceType = part.req('device_type', parseNeighborDeviceType);
    const permitJoining = part.req('permit_joining', parseNeighborPermitJoining);
    const rxWhenIdle = part.req('rx_when_idle', parseNeighborRxWhenIdle);
    const relationship = part.req('relationship', parseNeighborRelationship);
    const ieee = part.req('ieee', parseString);
    const nwk = part.req('nwk', parseInteger);
    const extendedPAN = part.req('epan', parseString);
    const lqi = part.req('lqi', parseInteger);
    return { depth, deviceType, permitJoining, rxWhenIdle, relationship, ieee, nwk, extendedPAN, lqi };
});
