import Ajax from '../util/ajax';
import { scheduleAdded } from '../features/schedules/schedulesSlice';
import { hideDialog } from '../actions/HideDialog';
import { parse } from '../util/parse';
import { parseSchedule } from '../types/Schedule';
import { prettyWeekdays } from '../types/Weekdays';
export function createSchedule(obj) {
    return dispatch => {
        const ajax = new Ajax(dispatch);
        const url = '/schedule/create';
        let res = {
            active: obj.active,
            kind: obj.kind,
            transmitter_id: obj.transmitter_id,
            operation: obj.operation,
            dim_level: obj.dim_level,
        };
        if (obj.kind == 'cron') {
            res.cron = obj.cron;
        }
        else if (obj.kind == 'time') {
            const h = obj.time.h;
            const m = obj.time.m;
            const w = prettyWeekdays(obj.time.w);
            res.time = { h, m, w };
        }
        else if (obj.kind == 'sun') {
            const k = obj.sun.k;
            const a = obj.sun.a.toString();
            const w = prettyWeekdays(obj.sun.w);
            const r = obj.sun.r;
            res.sun = { k, a, w, r };
        }
        ajax.post(url, res, data => {
            const schedule = parse(parseSchedule, data);
            dispatch(scheduleAdded(schedule));
            dispatch(hideDialog());
        });
    };
}
