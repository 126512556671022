import { parseInteger, parseString, parseLiteral, parseArray, parseObject } from '../util/parse';
export const parseOutletKind = parseLiteral((s) => {
    switch (s) {
        case 'onoff': return 'onoff';
        case 'dim': return 'dim';
        case 'rollershade': return 'rollershade';
        default: return undefined;
    }
});
export const parseOutletCode = parseObject('OutletCode', part => {
    return {
        code: part.req('code', parseInteger),
        unit: part.req('unit', parseInteger),
    };
});
export const parseOutlet = parseObject('Outlet', part => {
    return {
        id: part.req('id', parseInteger),
        title: part.req('title', parseString),
        kind: part.req('kind', parseOutletKind),
        codes: part.req('codes', parseArray(parseOutletCode)),
        comment: part.req('comment', parseString),
    };
});
