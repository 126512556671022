const format_time_part = n => {
    if (n >= 10) {
        return n.toString();
    }
    else {
        return '0' + n.toString();
    }
};
export const format_time = (h, m) => {
    return format_time_part(h) + ':' + format_time_part(m);
};
