import React, { useState } from 'react';
import { useAppSelector } from '../hooks';
import { DialogPanelEdit } from '../components/DialogPanelEdit';
import { Link } from 'react-router-dom';
export const ConfigPanels = () => {
    const panels = useAppSelector(state => state.definitions.panels.entities);
    // The dialog data is stored here so we can set it depending on
    // what edit button we press
    const [open, setOpen] = useState(false);
    const [panelId, setPanelId] = useState(null);
    const [panelData, setPanelData] = useState({ title: '' });
    function create() {
        setPanelId(null);
        setPanelData({ title: '' });
        setOpen(true);
    }
    function edit(id) {
        var _a, _b;
        const title = (_b = (_a = panels.get(id)) === null || _a === void 0 ? void 0 : _a.title) !== null && _b !== void 0 ? _b : '';
        setPanelId(id);
        setPanelData({ title });
        setOpen(true);
    }
    let rows = [];
    for (const [, m] of panels) {
        const link = '/config/panel/' + m.id;
        rows.push(React.createElement("div", { key: m.id },
            React.createElement("div", null,
                React.createElement(Link, { to: link }, m.title)),
            React.createElement("div", null,
                React.createElement("button", { className: "lw small", onClick: () => edit(m.id) }, "Redigera"))));
    }
    return (React.createElement("div", { className: "page-container" },
        React.createElement(DialogPanelEdit, { panelId: panelId, isOpen: open, onClose: () => setOpen(false), panelData: panelData, setPanelData: setPanelData }),
        React.createElement("div", { className: "operations" },
            React.createElement("button", { className: "lw success", onClick: create }, "L\u00E4gg till panel...")),
        React.createElement("div", { className: "config-rows config-panels" }, rows)));
};
