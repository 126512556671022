import { parseObject, parseHex16Bits, parseInteger, parseString } from '../util/parse';
export const parseAddress = parseObject('Address', part => {
    if (part.has('group')) {
        return { group: part.req('group', parseHex16Bits) };
    }
    else if (part.has('ieee')) {
        return { ieee: part.req('ieee', parseString),
            endpoint: part.opt('endpoint', parseInteger),
        };
    }
    else {
        return part.error('Invalid adress');
    }
});
export function isAddressGroup(obj) {
    return obj.group !== undefined;
}
export function isAddressIEEE(obj) {
    return obj.ieee !== undefined && obj.nwk === undefined;
}
export function isAddressNWK(obj) {
    return obj.ieee === undefined && obj.nwk !== undefined;
}
export function isAddressNWKIEEE(obj) {
    return obj.ieee !== undefined && obj.nwk !== undefined;
}
