import Ajax from '../util/ajax';
import { updateTransmitterStates } from '../actions/UpdateTransmitterStates';
import { updateDeviceStates } from '../actions/UpdateDeviceStates';
export const TRANSMITTER = 'TRANSMITTER';
export function transmitter(id, command) {
    console.log('Transmitter ' + id + ' ' + command);
    return dispatch => {
        const success = data => {
            if (data.states) {
                dispatch(updateTransmitterStates(data.states));
            }
            if (data.device_states) {
                dispatch(updateDeviceStates(data.device_states));
            }
        };
        const a = new Ajax(dispatch);
        a.post('/transmitter/' + id + '/' + command, undefined, success);
        dispatch({
            type: TRANSMITTER,
            id,
            command
        });
    };
}
