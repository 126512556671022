import Ajax from '../util/ajax';
import { scheduleUpdated } from '../features/schedules/schedulesSlice';
import { parseSchedule } from '../types/Schedule';
import { hideDialog } from '../actions/HideDialog';
import { prettyWeekdays } from '../types/Weekdays';
import { parse } from '../util/parse';
export function saveSchedule(obj) {
    return dispatch => {
        if (obj.id) {
            const ajax = new Ajax(dispatch);
            const url = '/schedule/' + obj.id;
            let res;
            if (obj.kind == 'cron') {
                res = {
                    schedule_id: obj.id,
                    active: obj.active,
                    transmitter_id: obj.transmitter_id,
                    operation: obj.operation,
                    dim_level: obj.dim_level,
                    kind: 'cron',
                    cron: obj.cron
                };
            }
            else if (obj.kind == 'time') {
                const h = obj.time.h.toString();
                const m = obj.time.m.toString();
                const w = prettyWeekdays(obj.time.w);
                res = {
                    schedule_id: obj.id,
                    active: obj.active,
                    transmitter_id: obj.transmitter_id,
                    operation: obj.operation,
                    dim_level: obj.dim_level,
                    kind: 'time',
                    time: { h, m, w }
                };
            }
            else if (obj.kind == 'sun') {
                const k = obj.sun.k;
                const a = obj.sun.a.toString();
                const w = prettyWeekdays(obj.sun.w);
                const r = obj.sun.r;
                res = {
                    schedule_id: obj.id,
                    active: obj.active,
                    transmitter_id: obj.transmitter_id,
                    operation: obj.operation,
                    dim_level: obj.dim_level,
                    kind: 'sun',
                    sun: { k, a, w, r }
                };
            }
            else {
                console.log('Error, unknown schedule kind');
                return;
            }
            ajax.post(url, res, data => {
                const schedule = parse(parseSchedule, data);
                if (schedule) {
                    dispatch(scheduleUpdated(schedule));
                    dispatch(hideDialog());
                }
            });
        }
    };
}
