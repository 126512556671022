import React from 'react';
import Modal from 'react-modal';
Modal.setAppElement('#root');
export const Dialog = props => {
    let footer = null;
    if (props.footer) {
        footer = (React.createElement("div", { className: "modaldialogfooter modal-footer" }, props.footer));
    }
    else if (props.footerleft || props.footerright) {
        footer = (React.createElement("div", { className: "modaldialogfooter", style: { display: 'flex' } },
            React.createElement("div", null, props.footerleft),
            React.createElement("div", { style: { marginLeft: 'auto' } }, props.footerright)));
    }
    return (React.createElement(Modal, { isOpen: props.isOpen, onRequestClose: props.close, className: "modaldialog", contentLabel: props.title, overlayClassName: "modaldialogoverlay" },
        React.createElement("div", { className: "modaldialogheader" },
            React.createElement("div", { className: "title" }, props.title),
            React.createElement("button", { type: "button", className: "close", onClick: props.close },
                React.createElement("span", { "aria-label": "Close" }, "\u00D7"))),
        React.createElement("div", { className: "modaldialogbody" }, props.children),
        footer));
};
