import Ajax from '../util/ajax';
import { deviceAdded } from '../features/devices/devicesSlice';
import { parseDevice } from '../types/device';
import { parse } from '../util/parse';
export function createZigbee(node_id, endpoint_id, title) {
    return dispatch => {
        const ajax = new Ajax(dispatch);
        const url = '/zigbee/create';
        let obj = {
            node_id,
            endpoint_id,
            title
        };
        ajax.post(url, obj, data => {
            const device = parse(parseDevice, data);
            dispatch(deviceAdded(device));
        });
    };
}
