import React from 'react';
import { useAppSelector, useAppDispatch } from '../hooks';
import { DialogTransmitterEdit } from '../components/DialogTransmitterEdit';
import { ConfigTransmitterRow } from '../components/ConfigTransmitterRow';
import { newTransmitter } from '../actions/NewTransmitter';
export const ConfigTransmitters = () => {
    const def_transmitters = useAppSelector(state => state.definitions.transmitters);
    const dispatch = useAppDispatch();
    var transmitters = Array.from(def_transmitters.values());
    transmitters.sort((a, b) => a.title.localeCompare(b.title));
    var rows = [];
    if (transmitters) {
        for (const t of transmitters) {
            rows.push(React.createElement(ConfigTransmitterRow, { id: t.id, key: t.id }));
        }
    }
    return (React.createElement("div", { className: "page-container" },
        React.createElement(DialogTransmitterEdit, null),
        React.createElement("div", { className: "operations" },
            React.createElement("button", { className: "lw success", onClick: () => dispatch(newTransmitter()) }, "\u00A0L\u00E4gg till s\u00E4ndare...\u00A0")),
        React.createElement("div", { className: "config-rows config-transmitters" }, rows)));
};
