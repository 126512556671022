import React from 'react';
import { AjaxSpinner } from '../components/AjaxSpinner';
import { useAppSelector, useAppDispatch } from '../hooks';
import { useNavigate, useLocation } from 'react-router-dom';
import { jumpTo } from '../actions/JumpTo';
export const App = props => {
    const dispatch = useAppDispatch();
    const appJumpTo = useAppSelector(state => state.app.jumpTo);
    const navigate = useNavigate();
    const location = useLocation();
    React.useEffect(() => {
        if (appJumpTo) {
            if (location.pathname != appJumpTo) {
                navigate(appJumpTo);
            }
            dispatch(jumpTo(null));
        }
    }, [appJumpTo]);
    return (React.createElement(React.Fragment, null,
        React.createElement(AjaxSpinner, null),
        props.children));
};
