import { parseInteger, parseString, parseObject, parseError } from '../util/parse';
import { parseWeekdays } from './Weekdays';
const parseSunType = (ctx, v) => {
    const s = parseString(ctx, v);
    switch (s) {
        case 't': return 't';
        case 'u': return 'u';
        case 'e': return 'e';
        case 'n': return 'n';
        default:
            return parseError(ctx, 'invalid SunType value ' + s);
    }
};
const parseSunAjust = (ctx, v) => {
    const i = parseInteger(ctx, v);
    if (i >= -3 && i <= 3) {
        return i;
    }
    else {
        return parseError(ctx, 'invalid SunAdjust value ' + i);
    }
};
const parseScheduleRandom = (ctx, v) => {
    const s = parseString(ctx, v);
    switch (s) {
        case 'r0': return 'r0';
        case 'r5': return 'r5';
        case 'r15': return 'r15';
        default:
            return parseError(ctx, 'invalid ScheduleRandom value ' + s);
    }
};
export const parseSun = parseObject('Sun', part => {
    return {
        k: part.req('k', parseSunType),
        a: part.req('a', parseSunAjust),
        w: part.req('w', parseWeekdays),
        r: part.req('r', parseScheduleRandom),
    };
});
