import Ajax from '../util/ajax';
import { jumpTo } from '../actions/JumpTo';
export const LOGIN = 'LOGIN';
export function login(username, password) {
    return dispatch => {
        const a = new Ajax(dispatch);
        a.post('/login', {
            username,
            password
        }, data => {
            if (data) {
                dispatch(jumpTo('/'));
            }
            else {
                alert('Login error');
            }
        });
    };
}
