import Ajax from '../util/ajax';
import { macroUpdated } from '../features/macros/macrosSlice';
import { hideDialog } from '../actions/HideDialog';
import { parse } from '../util/parse';
import { parseMacro } from '../types/Macro';
export function saveMacro() {
    return (dispatch, getState) => {
        const { edit_macro_dialog } = getState();
        const ajax = new Ajax(dispatch);
        const url = '/macro/' + edit_macro_dialog.macro.id;
        let obj = {
            id: edit_macro_dialog.macro.id,
            title: edit_macro_dialog.macro.title,
            active: edit_macro_dialog.macro.active,
            // for some reason the macro items are called commands when we post to the backend, we should fix the backend
            commands: JSON.stringify(edit_macro_dialog.macro.items),
        };
        ajax.post(url, obj, data => {
            const macro = parse(parseMacro, data);
            dispatch(macroUpdated(macro));
            dispatch(hideDialog());
        });
    };
}
