import { createSlice } from '@reduxjs/toolkit';
import { createEntityAdapter } from '../../util/OrderedEntities';
const schdulesAdapter = createEntityAdapter({
    selectId: (s) => s.id,
    sortComparer: (a, b) => {
        if (a.next_run < b.next_run) {
            return -1;
        }
        else if (a.next_run > b.next_run) {
            return 1;
        }
        else {
            return a.id - b.id;
        }
    },
});
export const schedulesSlice = createSlice({
    name: 'schedules',
    initialState: schdulesAdapter.getInitialState(), // new Map(),
    reducers: {
        schedulesCleared: schdulesAdapter.removeAll,
        scheduleUpdated: schdulesAdapter.addOne,
        scheduleDeleted: schdulesAdapter.removeOne,
    }
});
export const { schedulesCleared, scheduleUpdated, scheduleDeleted } = schedulesSlice.actions;
export const scheduleAdded = scheduleUpdated;
export default schedulesSlice.reducer;
