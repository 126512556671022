import { createSlice } from '@reduxjs/toolkit';
export const devicesSlice = createSlice({
    name: 'devices',
    initialState: new Map(),
    reducers: {
        devicesCleared: (state) => { state.clear(); },
        deviceUpdated: (state, action) => { state.set(action.payload.id, action.payload); },
        deviceDeleted: (state, action) => { state.delete(action.payload); },
    }
});
export const { devicesCleared, deviceUpdated, deviceDeleted } = devicesSlice.actions;
export const deviceAdded = deviceUpdated;
export default devicesSlice.reducer;
