import React from 'react';
import './init';
import { createRoot } from 'react-dom/client';
import { App } from './components/App';
import { AppView } from './components/AppView';
import { ConfigOutlets } from './components/ConfigOutlets';
import { ConfigTransmitters } from './components/ConfigTransmitters';
import { ConfigZigbee } from './components/ConfigZigbee';
import { ConfigZigbeeDevice } from './components/ConfigZigbeeDevice';
import { ConfigZigbeeDeviceJson } from './components/ConfigZigbeeDeviceJson';
import { ConfigPanels } from './components/ConfigPanels';
import { ConfigPanel } from './components/ConfigPanel';
import { ConfigTransmitterSetup } from './components/ConfigTransmitterSetup';
import { ConfigSchedule } from './components/ConfigSchedule';
import { ConfigMacros } from './components/ConfigMacros';
import { PageDevice } from './components/PageDevice';
import { Panel } from './components/Panel';
import { Sensors } from './components/Sensors';
import { Macros } from './components/Macros';
import { Login } from './components/Login';
import { NoMatch } from './components/NoMatch';
import { Provider } from 'react-redux';
import { Routes, Route, Outlet, BrowserRouter } from 'react-router-dom';
import { store } from './util/store';
const Main = () => (React.createElement(Provider, { store: store },
    React.createElement(BrowserRouter, null,
        React.createElement(App, null,
            React.createElement(Routes, null,
                React.createElement(Route, { path: "login", element: React.createElement(Login, null) }),
                React.createElement(Route, { element: React.createElement(AppView, null,
                        React.createElement(Outlet, null)) },
                    React.createElement(Route, { path: "/", element: React.createElement(Panel, null) }),
                    React.createElement(Route, { path: "device/:id", element: React.createElement(PageDevice, null) }),
                    React.createElement(Route, { path: "panel/:id", element: React.createElement(Panel, null) }),
                    React.createElement(Route, { path: "sensors", element: React.createElement(Sensors, null) }),
                    React.createElement(Route, { path: "/macros", element: React.createElement(Macros, null) }),
                    React.createElement(Route, { path: "/config" },
                        React.createElement(Route, { path: "transmitter/:id/setup", element: React.createElement(ConfigTransmitterSetup, null) }),
                        React.createElement(Route, { path: "transmitters", element: React.createElement(ConfigTransmitters, null) }),
                        React.createElement(Route, { path: "zigbee/:id/json", element: React.createElement(ConfigZigbeeDeviceJson, null) }),
                        React.createElement(Route, { path: "zigbee/:id", element: React.createElement(ConfigZigbeeDevice, null) }),
                        React.createElement(Route, { path: "zigbee", element: React.createElement(ConfigZigbee, null) }),
                        React.createElement(Route, { path: "panels", element: React.createElement(ConfigPanels, null) }),
                        React.createElement(Route, { path: "panel/:id", element: React.createElement(ConfigPanel, null) }),
                        React.createElement(Route, { path: "outlet", element: React.createElement(ConfigOutlets, null) }),
                        React.createElement(Route, { path: "macros", element: React.createElement(ConfigMacros, null) }),
                        React.createElement(Route, { path: "schedule", element: React.createElement(ConfigSchedule, null) })),
                    React.createElement(Route, { path: "*", element: React.createElement(NoMatch, null) })))))));
const Root = () => React.createElement(React.StrictMode, null,
    React.createElement(Main, null));
const root = createRoot(document.getElementById('root'));
root.render(React.createElement(Root, null));
