import { scheduleDeleted } from '../features/schedules/schedulesSlice';
import Ajax from '../util/ajax';
import { hideDialog } from '../actions/HideDialog';
export function deleteSchedule(id) {
    return dispatch => {
        const ajax = new Ajax(dispatch);
        const url = '/schedule/' + id;
        ajax.del(url, undefined, () => {
            dispatch(scheduleDeleted(id));
            dispatch(hideDialog());
        });
    };
}
