import { ADD_SENSOR } from '../actions/AddSensor';
import { UPDATE_DEVICE_STATES } from '../actions/UpdateDeviceStates';
export function sensors(state = new Map(), action) {
    switch (action.type) {
        case ADD_SENSOR:
            var o = new Map(state);
            o.set(parseInt(action.id), {
                // must be in same order as below
                id: Number(action.id),
                temp: Number(action.temp),
                humid: Number(action.humid),
                time: action.time
            });
            return o;
        case UPDATE_DEVICE_STATES: {
            var o2 = new Map();
            for (const [id, state2] of Object.entries(action.states)) {
                const state = state2;
                if (Object.prototype.hasOwnProperty.call(state, 'temp')) {
                    o2.set(parseInt(id), {
                        // must be in same order as above
                        id: parseInt(id),
                        temp: state.temp,
                        humid: state.humid,
                        time: state.ts
                    });
                }
            }
            // has it changed compared to the old?
            let has_changed = false;
            if (state.size != o2.size) {
                has_changed = true;
            }
            else {
                for (const id of o2.keys()) {
                    const oldsensor = state.get(id);
                    const newsensor = o2.get(id);
                    if (oldsensor == undefined) {
                        has_changed = true;
                    }
                    else if (JSON.stringify(oldsensor) !== JSON.stringify(newsensor)) {
                        // the json trick only work if the properties of the object are in the same order
                        has_changed = true;
                    }
                    if (has_changed)
                        break;
                }
            }
            return has_changed ? o2 : state;
        }
        default:
            return state;
    }
}
