import Ajax from '../util/ajax';
import { panelGroupUpdated } from '../features/panel_groups/panelGroupsSlice';
import { hideDialog } from '../actions/HideDialog';
export function savePanelGroup() {
    return (dispatch, getState) => {
        const { edit_panel_group_dialog } = getState();
        const panel_group_id = edit_panel_group_dialog.id;
        if (panel_group_id) {
            const ajax = new Ajax(dispatch);
            const url = '/panel/' + edit_panel_group_dialog.panel_id + '/group/' + panel_group_id;
            ajax.post(url, {
                title: edit_panel_group_dialog.title,
                ord: edit_panel_group_dialog.ord
            }, () => {
                dispatch(panelGroupUpdated({
                    id: panel_group_id,
                    panel_id: edit_panel_group_dialog.panel_id,
                    title: edit_panel_group_dialog.title,
                    ord: edit_panel_group_dialog.ord
                }));
                dispatch(hideDialog());
            });
        }
    };
}
