import React from 'react';
import { connect } from 'react-redux';
import { editOutlet } from '../actions/EditOutlet';
const ConfigOutletElem = props => {
    return (React.createElement("div", { className: "config-outlet-elem" },
        React.createElement("div", null, props.name),
        React.createElement("div", null, props.kind),
        React.createElement("div", { className: "pull-right" },
            React.createElement("button", { className: "editx lw xprimary small", onClick: () => props.onEditOutlet() }, "\u00A0Redigera\u00A0"))));
};
const mapStateToProps = (state, ownProps) => {
    const outlet = state.definitions.outlets.get(ownProps.id);
    if (!outlet) {
        return {
            id: ownProps.id,
            kind: '',
            name: '',
            has_setup: false
        };
    }
    let kind;
    switch (outlet.kind) {
        case 'onoff':
            kind = 'På/Av';
            break;
        case 'dim':
            kind = 'Dimmer';
            break;
        case 'rollershade':
            kind = 'Rullgardin';
            break;
    }
    return {
        id: ownProps.id,
        name: outlet.title,
        kind,
        has_setup: outlet.kind == 'rollershade'
    };
};
const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        onEditOutlet: () => {
            dispatch(editOutlet(ownProps.id));
        }
    };
};
export const ConfigOutlet = connect(mapStateToProps, mapDispatchToProps)(ConfigOutletElem);
