import { jumpTo } from '../actions/JumpTo';
import Ajax from '../util/ajax';
export const LOGIN = 'LOGOUT';
export function logout() {
    return dispatch => {
        const a = new Ajax(dispatch);
        a.post('/logout', {}, data => {
            if (data) {
                dispatch(jumpTo('/login'));
            }
            else {
                alert('Logout error');
            }
        });
    };
}
