import { createAction, createSlice } from '@reduxjs/toolkit';
import { panelGroupOrderChanged } from './panelGroupOrderSlice';
const panelGroupUpdatedInternal = createAction('panel_groups/panelGroupUpdated');
export function panelGroupUpdated(pg) {
    return dispatch => {
        dispatch(panelGroupUpdatedInternal(pg));
        dispatch(panelGroupOrderChanged(pg.panel_id)); // tell the order part of the state to recompute since an ord value might have changed
    };
}
export const panelGroupsSlice = createSlice({
    name: 'panel_groups',
    initialState: new Map(),
    reducers: {
        panelGroupsCleared: s => { s.clear(); },
        panelGroupDeleted: (s, action) => { s.delete(action.payload); },
    },
    extraReducers: builder => {
        builder
            .addCase(panelGroupUpdatedInternal, (s, action) => { s.set(action.payload.id, action.payload); });
    },
});
export const { panelGroupsCleared, panelGroupDeleted } = panelGroupsSlice.actions;
export const panelGroupAdded = panelGroupUpdated;
export default panelGroupsSlice.reducer;
