import Ajax from '../util/ajax';
import { updateTransmitterStates } from '../actions/UpdateTransmitterStates';
import { updateDeviceStates } from '../actions/UpdateDeviceStates';
export function zigbeeOff(id) {
    return dispatch => {
        const success = data => {
            if (data.states) {
                dispatch(updateTransmitterStates(data.states));
            }
            if (data.device_states) {
                dispatch(updateDeviceStates(data.device_states));
            }
        };
        const ajax = new Ajax(dispatch);
        ajax.throttle_post('/zigbee/' + id + '/off', undefined, success);
    };
}
