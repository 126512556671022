import Ajax from '../util/ajax';
import { outletUpdated } from '../features/outlets/outletsSlice';
import { hideDialog } from '../actions/HideDialog';
import { parse } from '../util/parse';
import { parseOutlet } from '../types/Outlet';
export function saveOutlet(obj) {
    return dispatch => {
        const ajax = new Ajax(dispatch);
        const url = '/outlet/' + obj.id;
        // remove the key parameter from codes
        const codes = obj.codes.map(v => { var _a, _b; return ({ code: (_a = v.code) !== null && _a !== void 0 ? _a : 0, unit: (_b = v.unit) !== null && _b !== void 0 ? _b : 0 }); });
        const new_obj = {
            id: obj.id,
            title: obj.title,
            comment: obj.comment,
            kind: obj.kind,
            active: false, // the backend demand an active flag even if it is not used
            codes: JSON.stringify(codes),
        };
        ajax.post(url, new_obj, data => {
            const outlet = parse(parseOutlet, data);
            dispatch(outletUpdated(outlet));
            dispatch(hideDialog());
        });
    };
}
