import React from 'react';
import { Sensor } from '../components/Sensor';
import { useAppSelector } from '../hooks';
export const Sensors = () => {
    const sensors = useAppSelector(state => state.sensors);
    const devices = useAppSelector(state => state.definitions.devices);
    var sensorElems = [];
    for (const sensor of sensors.values()) {
        const device = devices.get(sensor.id);
        if (device) {
            const humid = sensor.humid.toFixed(0);
            const temp = sensor.temp.toFixed(1);
            sensorElems.push(React.createElement(Sensor, { key: sensor.id, title: device.title, temp: temp, humid: humid, time: sensor.time }));
        }
    }
    return (React.createElement("div", { className: "page-container" },
        React.createElement("div", { className: "sensors" }, sensorElems)));
};
