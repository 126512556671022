import Ajax from '../util/ajax';
import { macroAdded } from '../features/macros/macrosSlice';
import { hideDialog } from '../actions/HideDialog';
import { parse } from '../util/parse';
import { parseMacro } from '../types/Macro';
export function createMacro() {
    return (dispatch, getState) => {
        const { edit_macro_dialog } = getState();
        const ajax = new Ajax(dispatch);
        const url = '/macro/create';
        let obj = {
            title: edit_macro_dialog.macro.title,
            active: edit_macro_dialog.macro.active,
            // for some reason the macro items are called commands when we post to the backend, we should fix the backend
            commands: JSON.stringify(edit_macro_dialog.macro.items),
        };
        ajax.post(url, obj, data => {
            const macro = parse(parseMacro, data);
            dispatch(macroAdded(macro));
            dispatch(hideDialog());
        });
    };
}
