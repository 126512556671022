import React, { useState } from 'react';
import { Group } from '../components/Group';
import { isAddressGroup, isAddressIEEE } from '../zigbee/address';
const ZigbeeNodeBindingTableTable = props => {
    var bindingtable = [];
    var index = 0;
    for (const bt of props.items) {
        if (isAddressGroup(bt.destination)) {
            bindingtable.push(React.createElement("tr", { key: index++ },
                React.createElement("td", null, bt.source.ieee),
                React.createElement("td", null, bt.source.endpoint),
                React.createElement("td", null, bt.source.cluster),
                React.createElement("td", null, bt.destination.group),
                React.createElement("td", null),
                React.createElement("td", null),
                React.createElement("td", null,
                    React.createElement("button", { onClick: () => props.onUnbind(bt) }, "Delete"))));
        }
        else if (isAddressIEEE(bt.destination)) {
            bindingtable.push(React.createElement("tr", { key: index++ },
                React.createElement("td", null, bt.source.ieee),
                React.createElement("td", null, bt.source.endpoint),
                React.createElement("td", null, bt.source.cluster),
                React.createElement("td", null),
                React.createElement("td", null, bt.destination.ieee),
                React.createElement("td", null, bt.destination.endpoint),
                React.createElement("td", null,
                    React.createElement("button", { onClick: () => props.onUnbind(bt) }, "Delete"))));
        }
        else {
            console.error('Unknown binding table destination adress type');
        }
    }
    return (React.createElement("table", null,
        React.createElement("thead", null,
            React.createElement("tr", null,
                React.createElement("th", { style: { textAlign: 'left' } }, "Source addr"),
                React.createElement("th", { style: { textAlign: 'left' } }, "Source endpoint"),
                React.createElement("th", { style: { textAlign: 'left' } }, "Source cluster"),
                React.createElement("th", { style: { textAlign: 'left' } }, "Dest group"),
                React.createElement("th", { style: { textAlign: 'left' } }, "Dest addr"),
                React.createElement("th", { style: { textAlign: 'left' } }, "Dest endpoint"),
                React.createElement("th", { style: { textAlign: 'left' } }, "Operation"))),
        React.createElement("tbody", null, bindingtable)));
};
export const ZigbeeNodeBindingTable = (props) => {
    var _a, _b, _c;
    const ts = (_a = props.bindingtable) === null || _a === void 0 ? void 0 : _a.fetched_ts;
    const last_fetched = ts == undefined ? '?' : (ts.toLocaleString());
    const title = 'Binding table (' + last_fetched + ')';
    const [srcIEEE, setSrcIEEE] = useState('');
    const [srcEP, setSrcEP] = useState('');
    const [srcCluster, setSrcCluster] = useState('');
    const [dstGroup, setDstGroup] = useState('');
    const [dstIEEE, setDstIEEE] = useState('');
    const [dstEP, setDstEP] = useState('');
    function submit() {
        let ieee = srcIEEE;
        if (ieee == '' && props.ieee != null) {
            ieee = props.ieee;
        }
        if (ieee == '') {
            console.log('Missing source IEEE value');
            return;
        }
        if (srcEP == '') {
            console.log('Missing source EP value');
            return;
        }
        if (srcCluster == '') {
            console.log('Missing source cluster value');
            return;
        }
        let destination;
        if (dstGroup != '') {
            destination = {
                group: parseInt(dstGroup)
            };
        }
        else if (dstIEEE != '' && dstEP != '') {
            destination = {
                ieee: dstIEEE,
                endpoint: parseInt(dstEP)
            };
        }
        else {
            console.log('Missing destination');
            return;
        }
        const btItem = {
            source: {
                ieee,
                endpoint: parseInt(srcEP),
                cluster: parseInt(srcCluster)
            },
            destination
        };
        props.onBind(btItem);
        setSrcIEEE('');
        setSrcEP('');
        setSrcCluster('');
        setDstGroup('');
        setDstIEEE('');
        setDstEP('');
    }
    return (React.createElement(Group, { title: title },
        React.createElement(ZigbeeNodeBindingTableTable, { items: (_c = (_b = props.bindingtable) === null || _b === void 0 ? void 0 : _b.items) !== null && _c !== void 0 ? _c : [], onUnbind: props.onUnbind }),
        React.createElement("form", { onSubmit: props.onFetchBindingTable, style: { padding: '10px 10px' } },
            React.createElement("button", { type: "submit" }, "Fetch")),
        React.createElement(React.Fragment, null,
            React.createElement("input", { type: "text", size: 16, placeholder: "Src IEEE", value: srcIEEE, onChange: e => setSrcIEEE(e.target.value) }),
            React.createElement("input", { type: "text", size: 6, placeholder: "Src Endpoint", value: srcEP, onChange: e => setSrcEP(e.target.value) }),
            React.createElement("input", { type: "text", size: 6, placeholder: "Src Cluster", value: srcCluster, onChange: e => setSrcCluster(e.target.value) }),
            React.createElement("input", { type: "text", size: 6, placeholder: "Dst Group", value: dstGroup, onChange: e => setDstGroup(e.target.value) }),
            React.createElement("input", { type: "text", size: 16, placeholder: "Dst IEEE", value: dstIEEE, onChange: e => setDstIEEE(e.target.value) }),
            React.createElement("input", { type: "text", size: 6, placeholder: "Dst Endpoint", value: dstEP, onChange: e => setDstEP(e.target.value) }),
            React.createElement("button", { onClick: submit }, "Bind"))));
};
