import { parseString, ParseError } from '../util/parse';
export const initialWeekdays = {
    monday: true, tuesday: true, wednesday: true, thursday: true, friday: true, saturday: true, sunday: true
};
// a string with seven 0 or 1, one for each weekday
export const parseWeekdays = (ctx, v) => {
    const s = parseString(ctx, v);
    if (s.length != 7) {
        throw new ParseError(ctx, 'expected weekdays, got ' + s);
    }
    function p(c) {
        switch (c) {
            case '0': return false;
            case '1': return true;
            default: throw new ParseError(ctx, 'Invalid character "' + c + '" in weekdays');
        }
    }
    const w = {
        monday: p(s[0]),
        tuesday: p(s[1]),
        wednesday: p(s[2]),
        thursday: p(s[3]),
        friday: p(s[4]),
        saturday: p(s[5]),
        sunday: p(s[6]),
    };
    return w;
};
export function prettyWeekdays(w) {
    return [w.monday ? '1' : '0',
        w.tuesday ? '1' : '0',
        w.wednesday ? '1' : '0',
        w.thursday ? '1' : '0',
        w.friday ? '1' : '0',
        w.saturday ? '1' : '0',
        w.sunday ? '1' : '0'].join('');
}
