export function getManufacturerName(node_descriptor) {
    if (node_descriptor) {
        if (node_descriptor.manufacturer_name) {
            return node_descriptor.manufacturer_name;
        }
        else if (node_descriptor.manufacturer) {
            return node_descriptor.manufacturer.toString(16);
        }
    }
    return null;
}
