import React from 'react';
export const Sensor = props => {
    let d = new Date(props.time);
    let age = (Date.now() - d.getTime()) / 1000.0;
    let time;
    if (age > 5 * 60) {
        let year = d.getFullYear();
        let month = ('0' + (d.getMonth() + 1)).slice(-2);
        let day = ('0' + d.getDate()).slice(-2);
        let hour = ('0' + d.getHours()).slice(-2);
        let minute = ('0' + d.getMinutes()).slice(-2);
        let second = ('0' + d.getSeconds()).slice(-2);
        let str = `${year}-${month}-${day} ${hour}:${minute}:${second}`;
        time = (React.createElement("span", { style: { 'fontSize': '80%', 'paddingLeft': '10px' } },
            "(",
            str,
            ")"));
    }
    else {
        time = '';
    }
    return (React.createElement("div", { className: "sensor" },
        React.createElement("div", null,
            React.createElement("div", { style: { 'float': 'right', 'fontSize': '200%' } },
                props.temp,
                "\u00B0"),
            React.createElement("div", null,
                props.title,
                time),
            React.createElement("div", null,
                props.humid,
                "%"))));
};
