import Ajax from '../util/ajax';
import { updateTransmitterStates } from '../actions/UpdateTransmitterStates';
export const DIM = 'DIM';
export function dim(id, value) {
    console.log('Dim ' + id + ' level ' + value);
    return dispatch => {
        const success = data => {
            if (data.states) {
                dispatch(updateTransmitterStates(data.states));
            }
        };
        const a = new Ajax(dispatch);
        a.post('/transmitter/' + id + '/dim', { level: value }, success);
        dispatch({
            type: DIM,
            id,
            value
        });
    };
}
