import React from 'react';
import { useMatch } from 'react-router-dom';
export function TopMenuItem({ to, className, children }) {
    let match = useMatch(to + '/*');
    let classlist = [];
    if (className != undefined) {
        classlist.push(className);
    }
    if (match != null) {
        classlist.push('active');
    }
    const classes = classlist.length > 0 ? classlist.join(' ') : undefined;
    return (React.createElement("div", { role: "menuitem", className: classes }, children));
}
