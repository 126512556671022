import Ajax from '../util/ajax';
import { transmitterUpdated } from '../features/transmitters/transmittersSlice';
import { deviceUpdated } from '../features/devices/devicesSlice';
import { hideDialog } from '../actions/HideDialog';
import { parse } from '../util/parse';
import { parseTransmitter } from '../types/transmitter';
export function saveTransmitter() {
    return (dispatch, getState) => {
        const { edit_transmitter_dialog } = getState();
        const ajax = new Ajax(dispatch);
        const url = '/transmitter/' + edit_transmitter_dialog.id;
        let obj = Object.assign({}, edit_transmitter_dialog);
        ajax.post(url, obj, data => {
            const transmitter = parse(parseTransmitter, data);
            dispatch(transmitterUpdated(transmitter));
            let device_protocol;
            switch (transmitter.kind) {
                case 'onoff':
                    device_protocol = {
                        name: 'nexa transmitter',
                        kind: 'onoff',
                        code: transmitter.code,
                        unit: transmitter.unit,
                    };
                    break;
                case 'dim':
                    device_protocol = {
                        name: 'nexa transmitter',
                        kind: 'dimmer',
                        code: transmitter.code,
                        unit: transmitter.unit,
                    };
                    break;
                case 'rollershade':
                    device_protocol = {
                        name: 'hasta transmitter',
                        code: transmitter.code,
                        unit: transmitter.unit,
                    };
                    break;
            }
            if (device_protocol != undefined) {
                const device = {
                    id: transmitter.id,
                    title: transmitter.title,
                    comment: transmitter.comment,
                    protocol: device_protocol,
                    transmitter_kind: transmitter.kind,
                };
                dispatch(deviceUpdated(device));
                dispatch(hideDialog());
            }
        });
    };
}
