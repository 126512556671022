import React from 'react';
import { useAppSelector, useAppDispatch } from '../hooks';
import { editPanelGroup } from '../actions/EditPanelGroup';
export const ConfigPanelGroup = (props) => {
    const panel_groups = useAppSelector(state => state.definitions.panel_groups);
    const dispatch = useAppDispatch();
    const group = panel_groups.get(props.id);
    if (!group) {
        return null;
    }
    return (React.createElement("div", { className: "header" },
        React.createElement("div", { style: { gridColumnEnd: 'span 3' } }, group.title),
        React.createElement("div", null,
            React.createElement("button", { className: "edit lw small", onClick: () => dispatch(editPanelGroup(props.id)) }, "\u00A0Redigera\u00A0"))));
};
