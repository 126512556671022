class ParseContext {
    constructor(s = '') {
        this.str = s;
    }
    addClass(classname) {
        return new ParseContext(this.str + '{' + classname + '}');
    }
    addClassField(field) {
        return new ParseContext(this.str + '.' + field);
    }
    addArrayIndex(idx) {
        return new ParseContext(this.str + '[' + idx + ']');
    }
}
export class ParseError extends Error {
    constructor(ctx, message) {
        super(message); // 'Error' breaks prototype chain here
        Object.setPrototypeOf(this, new.target.prototype); // restore prototype chain
        this.ctx = ctx;
    }
}
export function parseError(ctx, msg) {
    throw new ParseError(ctx, msg);
}
export const parseUnknown = (_, v) => {
    return v;
};
export const parseNull = (ctx, v) => {
    if (v !== null) {
        throw new ParseError(ctx, 'expected null, got ' + typeof (v) + ' with value ' + v);
    }
    return null;
};
export const parseBoolean = (ctx, v) => {
    if (typeof v !== 'boolean') {
        throw new ParseError(ctx, 'expected boolean, got ' + typeof (v) + ' with value ' + v);
    }
    return v;
};
export const parseNumber = (ctx, v) => {
    if (typeof v !== 'number') {
        throw new ParseError(ctx, 'expected number, got ' + typeof (v) + ' with value ' + v);
    }
    return v;
};
export const parseString = (ctx, v) => {
    if (typeof v !== 'string') {
        throw new ParseError(ctx, 'expected string, got ' + typeof (v) + ' with value ' + v);
    }
    return v;
};
export const parseDate = (ctx, v) => {
    const s = parseString(ctx, v);
    const d = new Date(s);
    if (d == null) {
        throw new ParseError(ctx, 'expected date, got ' + s);
    }
    return d;
};
export const parseInteger = (ctx, v) => {
    const n = parseNumber(ctx, v);
    if (!Number.isInteger(n)) {
        throw new ParseError(ctx, 'expected integer, got ' + n.toString());
    }
    return n;
};
export const parseHex16Bits = (ctx, v) => {
    const s = parseString(ctx, v);
    if (!/^([a-z0-9]{4})$/.test(s)) {
        throw new ParseError(ctx, 'expected string with hex digits, got ' + s);
    }
    return parseInt(s, 16);
};
export function parseLiteral(parseToken) {
    return (ctx, v) => {
        const s = parseString(ctx, v);
        const t = parseToken(s);
        if (t == undefined) {
            throw new ParseError(ctx, 'invalid literal value "' + s + '"');
        }
        else {
            return t;
        }
    };
}
export class ParseObjectPart {
    constructor(ctx, obj) {
        this.ctx = ctx;
        this.obj = obj;
    }
    req(field, parse) {
        if (field in this.obj) {
            return parse(this.ctx.addClassField(field), this.obj[field]);
        }
        else {
            throw new ParseError(this.ctx, 'missing field "' + field + '"');
        }
    }
    opt(field, parse) {
        if (field in this.obj) {
            if (this.obj[field] == null) {
                return undefined;
            }
            else {
                return parse(this.ctx.addClassField(field), this.obj[field]);
            }
        }
        else {
            return undefined;
        }
    }
    has(field) {
        return field in this.obj;
    }
    error(msg) {
        throw new ParseError(this.ctx, msg);
    }
}
export function parseObject(objName, parse) {
    return (ctx, v) => {
        if (typeof v === 'object' && v !== null) {
            return parse(new ParseObjectPart(ctx.addClass(objName), v));
        }
        else if (v == null) {
            throw new ParseError(ctx, 'expected object, got null');
        }
        else {
            throw new ParseError(ctx, 'expected object, got ' + typeof (v));
        }
    };
}
export function parseObjectOrNull(objName, parse) {
    return (ctx, v) => {
        if (typeof v === 'object' && v !== null) {
            return parse(new ParseObjectPart(ctx.addClass(objName), v));
        }
        else if (v == null) {
            return undefined;
        }
        else {
            throw new ParseError(ctx, 'expected object, got ' + typeof (v));
        }
    };
}
export function parseArray(parse) {
    return (ctx, v) => {
        {
            if (Array.isArray(v)) {
                let res = new Array();
                v.forEach((x, i) => {
                    res.push(parse(ctx.addArrayIndex(i), x));
                });
                return res;
            }
            else {
                throw new ParseError(ctx, 'expected array, got ' + typeof (v));
            }
        }
    };
}
export function parseArrayLogFailed(parse) {
    return (ctx, v) => {
        {
            if (Array.isArray(v)) {
                let res = new Array();
                v.forEach((x, i) => {
                    try {
                        res.push(parse(ctx.addArrayIndex(i), x));
                    }
                    catch (e) {
                        if (e instanceof ParseError) {
                            console.log(e.ctx.str + ' ' + e.message);
                        }
                        else {
                            throw e;
                        }
                    }
                });
                return res;
            }
            else {
                throw new ParseError(ctx, 'expected array, got ' + typeof (v));
            }
        }
    };
}
export function parseOr(p1, p2) {
    return (ctx, v) => {
        try {
            return p1(ctx, v);
        }
        catch (e) {
            if (e instanceof ParseError) {
                try {
                    return p2(ctx, v);
                }
                catch (e2) {
                    if (e2 instanceof ParseError) {
                        throw new Error(ctx.str + ': ' + e.message + ' or ' + e2.message);
                    }
                    else {
                        throw e2;
                    }
                }
            }
            else {
                throw e;
            }
        }
    };
}
export function parse(p, value) {
    try {
        return p(new ParseContext(''), value);
    }
    catch (e) {
        if (e instanceof ParseError) {
            throw new Error(e.ctx.str + ': ' + e.message);
        }
        else {
            throw e;
        }
    }
}
export function parseOpt(p, value) {
    try {
        return p(new ParseContext(''), value);
    }
    catch (e) {
        if (e instanceof ParseError) {
            return undefined;
        }
        else {
            throw e;
        }
    }
}
