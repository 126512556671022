import { createSlice } from '@reduxjs/toolkit';
export const panelDeviceOrderSlice = createSlice({
    name: 'panel_device_order',
    initialState: new Map(),
    reducers: {
        panelDeviceOrder: (state, action) => {
            state.set(action.payload.id, action.payload.order);
        }
    },
});
// Thunk that recompute the order of the groups in a panel. Typically dispatched after one has updated an ord value in a PanelGroup
export function panelDeviceOrderChanged(panelGroupId) {
    return (dispatch, getState) => {
        const { definitions } = getState();
        const { panel_devices } = definitions;
        let pds = [];
        for (var pd of panel_devices.values()) {
            if (pd.panel_group_id == panelGroupId) {
                pds.push(pd);
            }
        }
        pds.sort((a, b) => {
            const diff = a.ord - b.ord;
            if (diff == 0) {
                return a.device_id - b.device_id;
            }
            else {
                return diff;
            }
        });
        let order = [];
        for (const pd of pds) {
            order.push(pd.id);
        }
        // FIXME, we should only dispatch if the order really has changed...
        dispatch(panelDeviceOrderSlice.actions.panelDeviceOrder({ id: panelGroupId, order }));
    };
}
export const { panelDeviceOrder } = panelDeviceOrderSlice.actions;
export default panelDeviceOrderSlice.reducer;
