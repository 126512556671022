import Ajax from '../util/ajax';
import { panelGroupDeleted } from '../features/panel_groups/panelGroupsSlice';
import { hideDialog } from '../actions/HideDialog';
export function deletePanelGroup() {
    return (dispatch, getState) => {
        const { edit_panel_group_dialog } = getState();
        const id = edit_panel_group_dialog.id;
        if (id) {
            const ajax = new Ajax(dispatch);
            const url = '/panel/' + edit_panel_group_dialog.panel_id + '/group/' + id;
            ajax.del(url, undefined, () => {
                dispatch(panelGroupDeleted(id));
                dispatch(hideDialog());
            });
        }
    };
}
