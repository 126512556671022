import Ajax from '../util/ajax';
import { updateZigbeeNodeEndpointGroups } from '../actions/UpdateZigbeeNodeEndpointGroups';
import { parse } from '../util/parse';
import { parseGroups } from '../types/Zigbee/Groups';
export function fetchZigbeeNodeEndpointGroups(zigbee_node_id, eid) {
    return dispatch => {
        const ajax = new Ajax(dispatch);
        const url = '/zigbee/' + zigbee_node_id + '/ep/' + eid + '/groups';
        ajax.post(url, {}, data => {
            const groups = parse(parseGroups, data);
            if (groups != undefined) {
                dispatch(updateZigbeeNodeEndpointGroups(zigbee_node_id, eid, groups));
            }
        });
    };
}
