import { parseInteger, parseString, parseBoolean, parseObject, parseArray } from '../util/parse';
export const parseMacroItem = parseObject('MacroItem', part => {
    return {
        device_id: part.req('device_id', parseInteger),
        operation: part.req('operation', parseString),
        transmitter_id: part.req('transmitter_id', parseInteger),
    };
});
export const parseMacro = parseObject('Macro', part => {
    return {
        id: part.req('id', parseInteger),
        title: part.req('title', parseString),
        active: part.req('active', parseBoolean),
        items: part.req('items', parseArray(parseMacroItem)),
    };
});
