import { parseBoolean, parseInteger, parseString, parseObject } from '../util/parse';
import { parseScheduleTime } from '../types/ScheduleTime';
export const parseSchedule = parseObject('Schedule', part => {
    return {
        id: part.req('id', parseInteger),
        active: part.req('active', parseBoolean),
        device_id: part.req('device_id', parseInteger),
        dim_level: part.req('dim_level', parseInteger),
        next_run: part.req('next_run', parseString),
        operation: part.req('operation', parseString),
        transmitter_id: part.req('transmitter_id', parseInteger),
        time: part.req('time', parseScheduleTime),
    };
});
