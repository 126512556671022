import { showDialog } from '../actions/ShowDialog';
import { setEditScheduleDialog } from '../actions/SetEditScheduleDialog';
import { initialState } from '../reducers/edit_schedule_dialog';
export function editSchedule(id) {
    return (dispatch, getState) => {
        const { definitions } = getState();
        const schedule = definitions.schedules.entities.get(id);
        if (schedule == undefined) {
            return;
        }
        let dialog = Object.assign({}, initialState);
        dialog.id = schedule.id;
        dialog.kind = schedule.time.kind;
        dialog.transmitter_id = schedule.transmitter_id;
        dialog.operation = schedule.operation;
        dialog.dim_level = schedule.dim_level;
        dialog.active = schedule.active;
        switch (schedule.time.kind) {
            case 'cron':
                dialog.cron = schedule.time.cron;
                break;
            case 'time':
                dialog.time = {
                    h: schedule.time.time.h,
                    m: schedule.time.time.m,
                    w: schedule.time.time.w,
                };
                break;
            case 'sun':
                dialog.sun = {
                    k: schedule.time.sun.k,
                    a: schedule.time.sun.a,
                    w: schedule.time.sun.w,
                    r: schedule.time.sun.r,
                };
                break;
        }
        dispatch(setEditScheduleDialog(dialog));
        dispatch(showDialog('schedule-edit'));
    };
}
