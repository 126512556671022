import { jumpTo } from '../actions/JumpTo';
import { throttle } from '../actions/Throttle';
import { appAjaxSpinner } from '../actions/App';
function objectToURLEncoded(element, key, parts = []) {
    if (typeof (element) == 'object') {
        for (var idx in element)
            objectToURLEncoded(element[idx], key ? key + '[' + idx + ']' : idx, parts);
    }
    else {
        parts.push(key + '=' + encodeURIComponent(element));
    }
    return parts.join('&');
}
class Ajax {
    constructor(dispatch, json = false) {
        this.dispatch = dispatch;
        this.json = json;
        dispatch(appAjaxSpinner(true));
    }
    send(method, endpoint, data, success, failure) {
        let api_url;
        let dispatch = this.dispatch;
        this.dispatch(appAjaxSpinner(true));
        if (process.env.NODE_ENV !== 'production') {
            api_url = 'https://light.dev.zigo.org/api';
            //api_url = 'http://localhost:8081/api';
            //api_url = 'http://cosmo.int.zigo.org:8081/api';
        }
        else {
            api_url = '/api';
        }
        var xhr = new XMLHttpRequest();
        xhr.onreadystatechange = function () {
            if (xhr.readyState == XMLHttpRequest.DONE) { // XMLHttpRequest.DONE == 4
                if (xhr.status == 200) {
                    try {
                        var obj = JSON.parse(xhr.responseText);
                        if (success) {
                            success(obj);
                        }
                    }
                    catch (e) {
                        if (e instanceof SyntaxError) {
                            console.log('JSON syntax error: ' + e.message);
                            if (failure) {
                                failure(xhr);
                            }
                        }
                        else {
                            dispatch(appAjaxSpinner(false));
                            throw e;
                        }
                    }
                }
                else if (xhr.status == 401) {
                    dispatch(jumpTo('/login'));
                }
                else {
                    if (failure) {
                        failure(xhr);
                    }
                }
                dispatch(appAjaxSpinner(false));
            }
        };
        xhr.open(method, api_url + endpoint, true);
        xhr.withCredentials = true;
        if (data) {
            if (this.json) {
                xhr.setRequestHeader('Content-Type', 'application/json');
                xhr.send(JSON.stringify(data));
            }
            else {
                xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
                xhr.send(objectToURLEncoded(data));
            }
        }
        else {
            xhr.send();
        }
    }
    get(endpoint, data, success, failure) {
        this.send('GET', endpoint, data, success, failure);
    }
    post(endpoint, data, success, failure) {
        this.send('POST', endpoint, data, success, failure);
    }
    del(endpoint, data, success, failure) {
        this.send('DELETE', endpoint, data, success, failure);
    }
    throttle_post(endpoint, data, success, failure) {
        const dispatch = this.dispatch;
        dispatch(throttle(endpoint, data, success, failure));
    }
}
export default Ajax;
