import React from 'react';
export const ScheduleOperation = props => {
    const schedule = props.schedule;
    let operation;
    let operation_class;
    if (!schedule.active) {
        operation = 'Inaktiv';
        operation_class = 'operation_inactive';
    }
    else if (schedule.operation == 'on') {
        operation = 'På';
        operation_class = 'operation_on';
    }
    else if (schedule.operation == 'off') {
        operation = 'Av';
        operation_class = 'operation_off';
    }
    else if (schedule.operation == 'zigbee on') {
        operation = 'På';
        operation_class = 'operation_on';
    }
    else if (schedule.operation == 'zigbee off') {
        operation = 'Av';
        operation_class = 'operation_off';
    }
    else if (schedule.operation == 'dim') {
        operation = 'Dimmer: ' + schedule.dim_level;
        operation_class = 'operation_dim';
    }
    return (React.createElement("div", { className: operation_class }, operation));
};
