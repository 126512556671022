import Ajax from '../util/ajax';
import { panelAdded } from '../features/panels/panelsSlice';
import { parse } from '../util/parse';
import { parsePanel } from '../types/Panel';
export function createPanel(panel, callback) {
    return dispatch => {
        const ajax = new Ajax(dispatch);
        const url = '/panel/create';
        ajax.post(url, panel, data => {
            const panel = parse(parsePanel, data);
            dispatch(panelAdded(panel));
            callback();
        });
    };
}
