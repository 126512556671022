import { setEditOutletDialog } from '../actions/SetEditOutletDialog';
import { showDialog } from '../actions/ShowDialog';
import { unique_value } from '../util/unique';
export function editOutlet(id) {
    return (dispatch, getState) => {
        const { definitions } = getState();
        const outlet = definitions.outlets.get(id);
        if (outlet != undefined) {
            const editOutlet = {
                id: outlet.id,
                title: outlet.title,
                kind: outlet.kind,
                codes: outlet.codes.map(v => ({ code: v.code, unit: v.unit, key: unique_value() })),
                comment: outlet.comment
            };
            dispatch(setEditOutletDialog(editOutlet));
            dispatch(showDialog('outlet-edit'));
        }
    };
}
