export const APP_AJAX_SPINNER = 'APP_AJAX_SPINNER';
export function appAjaxSpinner(active) {
    return {
        type: APP_AJAX_SPINNER,
        active
    };
}
export const APP_DROPDOWN_OPEN = 'APP_DROPDOWN_OPEN';
export function appOpenDropdown(menu) {
    return (dispatch, getState) => {
        const { app } = getState();
        if (app.removeDropdownListener) {
            app.removeDropdownListener();
        }
        const clickListener = () => {
            if (isVisible(menu)) {
                dispatch(appCloseDropdown());
                removeClickListener();
            }
        };
        const removeClickListener = () => {
            document.removeEventListener('click', clickListener);
        };
        document.addEventListener('click', clickListener);
        dispatch({
            type: APP_DROPDOWN_OPEN,
            removeDropdownListener: removeClickListener
        });
    };
}
export const APP_DROPDOWN_CLOSE = 'APP_DROPDOWN_CLOSE';
export function appCloseDropdown() {
    return (dispatch, getState) => {
        const { app } = getState();
        if (app.removeDropdownListener) {
            app.removeDropdownListener();
        }
        dispatch({
            type: APP_DROPDOWN_CLOSE
        });
    };
}
const isVisible = elem => !!elem && !!(elem.offsetWidth || elem.offsetHeight || elem.getClientRects().length);
