import Ajax from '../util/ajax';
export const PERMIT_JOINING = 'PERMIT_JOINING';
export function permitJoining() {
    return dispatch => {
        const a = new Ajax(dispatch);
        a.post('/zigbee/permitjoining', {}, data => {
            dispatch({ type: PERMIT_JOINING, data });
        });
    };
}
