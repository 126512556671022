import React from 'react';
import { Group } from '../components/Group';
const Item = (props) => {
    if (props.children) {
        return React.createElement("div", { style: { border: '2px solid #aaaaaa',
                borderRadius: '6px',
                padding: '5px 7px 2px 7px',
                marginLeft: '8px',
                marginBottom: '4px',
                backgroundColor: '#ffffff'
            } }, props.children);
    }
    else
        return null;
};
export const ZigbeeNodeDescriptor = (props) => {
    const node_descriptor = props.node_descriptor;
    if (node_descriptor == null) {
        return (React.createElement(Group, { title: "Node Descriptor" },
            React.createElement("form", { onSubmit: props.onFetchNodeDescriptor, style: { padding: '10px 10px' } },
                React.createElement("button", { type: "submit" }, "Fetch"))));
    }
    let logical_type;
    switch (node_descriptor.logical_type) {
        case 'coordinator':
            logical_type = 'Coordinator';
            break;
        case 'router':
            logical_type = 'Router';
            break;
        case 'end device':
            logical_type = 'End Device';
            break;
        default:
            logical_type = '???';
            break;
    }
    const freq868 = node_descriptor.frequency_band_868 ? '868MHz' : null;
    const freq902 = node_descriptor.frequency_band_902 ? '902MHz' : null;
    const freq2400 = node_descriptor.frequency_band_2400 ? '2.4GHz' : null;
    const power = node_descriptor.power_source_mains ? 'Mains power' : 'Other power';
    const dtype = node_descriptor.device_type_full ? 'Full function device' : 'Reduced function device';
    const idle = node_descriptor.reciever_on_when_idle ? 'On when idle' : 'Off when idle';
    const ndesc = null; // <pre>{JSON.stringify(node_descriptor, null, 4)}</pre>
    return (React.createElement(Group, { title: "Node Descriptor" },
        React.createElement("div", { style: { display: 'flex', flexWrap: 'wrap', alignContent: 'space-around' } },
            React.createElement(Item, null, logical_type),
            React.createElement(Item, null, freq868),
            React.createElement(Item, null, freq902),
            React.createElement(Item, null, freq2400),
            React.createElement(Item, null, power),
            React.createElement(Item, null, dtype),
            React.createElement(Item, null, idle),
            React.createElement(Item, null, node_descriptor.manufacturer_name)),
        ndesc,
        React.createElement("form", { onSubmit: props.onFetchNodeDescriptor, style: { padding: '10px 10px' } },
            React.createElement("button", { type: "submit" }, "Fetch"))));
};
